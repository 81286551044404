import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const isLoggedIn = useSelector((state) => state.Auth?.isLoggedIn);
  const isAuthCompleted = useSelector((state) => state.Auth?.isAuthCompleted);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthCompleted && !isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      navigate('/signin');
    }
  }, [isLoggedIn, isAuthCompleted]);
  return (
    <Outlet />
  );
};

export default ProtectedRoute;
