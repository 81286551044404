import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Badge, Loader } from 'rsuite';

// ICONS
import { MdDarkMode, MdOutlineLightMode } from 'react-icons/md';
import { IoIosLogOut } from 'react-icons/io';
import { CgProfile } from 'react-icons/cg';

// Redux
import { logOutUser } from '../Redux/Auth/auth';
import { connectUserWallet } from '../Redux/Web3Auth/web3Auth';

// Utils
import { errorToast, splitString, successToast } from '../utils';

// ASSETS
import {
  profileAvatar, brandMark, BSCLogo, ETHLogo, POLYGONLogo,
  UChainLogo,
} from '../assets/images';

const Navbar = () => {
  const isLoggedIn = useSelector((state) => state.Auth?.isLoggedIn);
  const address = useSelector((state) => state.Web3Auth?.address);
  const isConnected = useSelector((state) => state.Web3Auth?.isConnected);
  const chainID = useSelector((state) => state.Web3Auth?.chainID);
  const web3DomainName = useSelector((state) => state.Web3Auth?.web3DomainName);
  const connectLoading = useSelector((state) => state.Web3Auth?.connectLoading);
  const [dappPath, setDappPath] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [chainImg, setChainImg] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeMoreMenu = () => {
    setIsMoreOpen(false);
  };

  const logout = () => {
    setIsMoreOpen(false);
    dispatch(logOutUser()).then((res) => {
      if (res.error) {
        errorToast(res.payload);
      } else {
        successToast('Logged out successfully');
      }
    });
  };

  const connectWallet = () => {
    dispatch(connectUserWallet()).then((res) => {
      if (res.error) {
        errorToast(res.payload);
      } else {
        successToast('Wallet connected');
      }
    });
  };

  const checkPathName = (path) => {
    if (path === '/dapp-demo') {
      return true;
    }
    const pattern = /^\/dapp\/\w+$/;

    return pattern.test(path);
  };

  useEffect(() => {
    setDappPath(checkPathName(location.pathname));
  }, [location.pathname]);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.querySelector('html').setAttribute('data-theme', theme);

    const rootElement = document.documentElement;

    // Check the current theme and set the meta tag accordingly
    const themeColor = rootElement.dataset.theme === 'dark' ? '#1A1A1A' : '#2474ad';

    // Set the theme-color meta tag
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', themeColor);
    }
  }, [theme]);

  useEffect(() => {
    const chainImage = {
      56: BSCLogo,
      1: ETHLogo,
      137: POLYGONLogo,
    };

    if (chainID in chainImage) {
      setChainImg(chainImage[chainID]);
    } else {
      setChainImg(UChainLogo);
    }
  }, [chainID]);

  useEffect(() => {
    const handleChainChanged = async (chainId) => {
      if (isConnected && chainID !== chainId) {
        dispatch(connectUserWallet());
      }
    };
    if (window.ethereum) {
      window.ethereum.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, [isConnected]);

  return (
    <header className={`${dappPath && 'hidden'} ${
      scrolled
        ? 'font-kanit bg-subtleBg text-content z-30 fixed top-0 left-0 w-full transform translate-y-0 transition-transform duration-300 ease-in-out shadow-md shadow-accent-1'
        : 'font-kanit bg-subtleBg text-content z-30 '
    }`}
    >
      <nav className="flex justify-between mx-2 md:mx-8 py-2 relative">
        {/* PREMIUM MEMEBER BADGE */}
        {/* <span
          className="absolute right-2 bottom-[-12px] font-bold italic
          text-xs bg-[#E5E4E2] text-black px-2 rounded-xl z-30 "
        >
          Platinum Member
        </span> */}
        {/* <span className="absolute right-2 bottom-[-12px] font-bold italic
        text-xs bg-[#FFD700] text-black px-2 rounded-xl z-30 ">
          Gold Member
        </span> */}
        <h2 className="flex items-baseline my-1">
          <a href="/" className="flex items-baseline gap-1 font-extrabold leading-10 text-2xl md:text-4xl hover:no-underline">
            <img src={brandMark} alt="main-logo" className=" h-[2rem] w-[2rem] md:h-[2.4rem] md:w-[2.4rem]" />
            <Badge color="blue" content="Finance">
              <span className=" text-2xl md:text-4xl">JETPAD</span>
            </Badge>
          </a>
        </h2>
        <ul className=" flex gap-2 md:gap-3 w-[70%] md:w-[40%]  items-center justify-end text-lg font-semibold">
          <li>
            {theme === 'light' ? (
              <MdDarkMode className=" cursor-pointer  " onClick={() => setTheme('dark')} />) : (
                <MdOutlineLightMode className=" cursor-pointer  " onClick={() => setTheme('light')} />
            )}
          </li>
          <li>
            <button
              type="button"
              className=" bg-mainHeading text-white py-1 px-2 rounded-md hover:bg-mainHeading/60 text-xs md:text-sm disabled:bg-mainHeading/30"
              onClick={() => connectWallet()}
              disabled={connectLoading}
            >
              {connectLoading ? (
                <>
                  <Loader size="xs" content="connecting..." speed="fast" className=" text-xs m-0 p-0" />
                </>
              ) : (
                <>
                  {address ? (
                    <span className="flex gap-1 items-center">
                      <img src={chainImg} alt="chain logo" className="  w-auto h-[14px]" />
                      {web3DomainName || (splitString(address, 4, 38))}
                    </span>
                  ) : (
                    'Connect Wallet'
                  )}
                </>
              )}
            </button>
          </li>
          <li className="flex justify-center items-center gap-2">
            {isLoggedIn ? (
              <>

                <div className="relative p-0 flex justify-center items-center">
                  <button type="button" className="" onClick={() => setIsMoreOpen(!isMoreOpen)} onMouseEnter={() => setIsMoreOpen(true)}>
                    <img src={profileAvatar} alt="avata" className=" rounded-full h-[1.9rem] w-[1.9rem] md:h-[2rem] md:w-[2rem] hover:border-2 bg-darkerHeading" />
                  </button>
                  {isMoreOpen && (
                    <div
                      className="absolute rounded-b-md rounded-t-sm py-4 pl-3 pr-4 bg-content top-8 w-max flex flex-col items-start justify-center gap-2 z-30 right-0 md:right-[-3px]"
                      onMouseLeave={closeMoreMenu}
                    >
                      <button
                        type="button"
                        className="text-xs md:text-sm flex gap-1 text-subText"
                        onClick={() => {
                          navigate('/profile');
                          setIsMoreOpen(false);
                        }}
                      >
                        <CgProfile className="h-4 w-4 text-mainHeading" />
                        Profile
                      </button>
                      <button
                        type="button"
                        className="text-xs md:text-sm flex gap-1 text-subText"
                        onClick={() => logout()}
                      >
                        <IoIosLogOut className=" h-4 w-4 text-red-400" />
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <button
                type="button"
                className="border border-mainHeading text-mainHeading py-1 px-2 rounded-xl hover:bg-mainHeading/80 hover:text-content text-xs md:text-sm"
                onClick={() => navigate('/signin')}
              >
                Login
              </button>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};
export default Navbar;
