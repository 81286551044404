import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

const AirdropSkeleton = ({ count }) => {
  const numberArray = Array.from({ length: count }, (_, index) => index + 1);

  return (
    numberArray.map((item) => (

      <div key={item} className="grid grid-cols-5 gap-2 items-center bg-[#0056CC] bg-opacity-[20%] rounded-lg p-2 my-1 w-full">
        <div className="h-[38px] w-[38px] rounded-full">
          <Skeleton
            circle
            height="100%"
          />
        </div>
        <div className=" col-span-2">
          <Skeleton width={50} />
          <Skeleton count={3} />
        </div>
        <span className="">
          <Skeleton
            width={30}
            baseColor="#0ecb81"
            highlightColor="#004b81"
          />
        </span>
        <Skeleton
          width={30}
          baseColor="#0056CC"
          highlightColor="#114fa5"
        />
      </div>
    ))
  );
};
AirdropSkeleton.propTypes = {
  count: PropTypes.number.isRequired,
};

export default AirdropSkeleton;
