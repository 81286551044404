import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

// COMPONENTS
import { ConfirmPopup, InfoPopup } from '../components';

// REDUX
import { getTokenData, payAirdropFee } from '../Redux/Web3Airdrop/web3Airdrop';
import { createSocialAirdrop } from '../Redux/FirebaseAirdrop/firebaseAirdrop';
import { addChain, connectUserWallet, switchNetwork } from '../Redux/Web3Auth/web3Auth';
import { getTokenPrices } from '../Redux/TokenData/tokenData';

// Assets
import { kittenOne } from '../assets/images';

// UTILITIES
import { errorToast, successToast, formatNumber } from '../utils';
import { updateProjectStat } from '../Redux/Auth/auth';
import { chainParams } from '../constants';

const SocialAirdrop = () => {
  const [active, setActive] = useState(false);
  const [infoActive, setInfoActive] = useState(false);
  const [creatingMSG, setCreatingMSG] = useState('');
  const [airdropLink, setAirdropLink] = useState('');
  const [step, setStep] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const { chainID, address } = useSelector((state) => state.Web3Auth);
  const { userID } = useSelector((state) => state.Auth);
  const tokenPrices = useSelector((state) => state.tokenData?.tokenPrices);
  const [airdropData, setAirdropData] = useState({
    contractAddress: '',
    tokenName: '',
    ticker: '',
    totalSupply: '',
    decimal: '',
    airdropRound: '',
    totalToken: '',
    airdropAmount: '',
    referralAmount: '',
    estLaunchPrice: '',
    startDate: '',
    endDate: '',
    telegramGroup: '',
    telegramChannel: '',
    twitterPageUserName: '',
    twitterPinnedPost: '',
    airdropDetails: '',
    projectDetails: '',
    projectName: '',
    projectWebsite: '',
    twitterPageLink: '',
    whitepaper: '',
    logoLink: '',
    networkValue: 'BSCAddress',
    networkID: '56',
    networkToken: 'BNB',
    networkName: 'BINANCE SMART-CHAIN',
    airdropFee: 0,
    featuredFee: 0,
    featured: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!tokenPrices?.ethereum) {
      const ids = 'bitcoin,ethereum,binancecoin,matic-network';
      dispatch(getTokenPrices({ ids }));
    }

    setAirdropData({
      ...airdropData,
      airdropFee: (75 / tokenPrices?.binancecoin?.usd || 0).toFixed(2),
      featuredFee: (25 / tokenPrices?.binancecoin?.usd || 0).toFixed(2),
    });
  }, [tokenPrices]);

  const checkData = () => {
    const keys = Object.keys(airdropData);
    let emptyInput = null;

    keys.forEach((key) => {
      if (airdropData[key] === '') {
        emptyInput = key;
      }
    });

    return emptyInput;
  };

  const validateDates = () => {
    const currentTimestamp = Date.now();
    const startTimestamp = Date.parse(airdropData.startDate);
    const endTimestamp = Date.parse(airdropData.endDate);

    if (startTimestamp < currentTimestamp) {
      return 'Start date should be in the future.';
    }

    if (startTimestamp >= endTimestamp) {
      return 'End date should be greater than start date.';
    }

    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emptyInput = checkData();

    if (emptyInput) {
      return errorToast(`Please fill in the ${emptyInput} field.`);
    }

    const validationError = validateDates();
    if (validationError) {
      return errorToast(validationError);
    }

    if (!chainID || !address) {
      return errorToast('Please Make sure your wallet is connected');
    }

    if (airdropData.featured) {
      setTotalFee(airdropData.airdropFee + airdropData.featuredFee);
    } else {
      setTotalFee(airdropData.airdropFee);
    }

    if (airdropData.airdropFee === 0) {
      return errorToast('Airdrop Fee cannot be 0');
    }
    setActive(true);
    return {};
  };

  const handleChange = (e) => {
    e.preventDefault();
    setAirdropData({ ...airdropData, [e.target.name]: e.target.value });
  };

  const handleOptionChange = (e) => {
    const networkID = e.target.options[e.target.selectedIndex].id;
    const networkValue = e.target.value;
    const chainIDHex = e.target?.options[e.target.selectedIndex].title;
    let networkToken = '';
    let airdropFee = 0;
    let networkName = '';
    let featuredFee = 0;
    dispatch(switchNetwork({ chainIDHex })).then((res) => {
      if (res.error) {
        const regex = /wallet_addEthereumChain/i;
        if (regex.test(res.payload)) {
          dispatch(addChain(chainParams[chainIDHex])).then((res) => {
            if (res.error) {
              errorToast(res?.payload);
            } else {
              successToast(res?.payload);
              dispatch(connectUserWallet());
            }
          });
        } else {
          errorToast(res.payload);
        }
      } else {
        dispatch(connectUserWallet());
        successToast(res.payload);
        if (networkValue === 'BSCAddress') {
          networkToken = 'BNB';
          airdropFee = (75 / tokenPrices?.binancecoin?.usd || 0).toFixed(2);
          // airdropFee = 0.01;
          featuredFee = (25 / tokenPrices?.binancecoin?.usd || 0).toFixed(2);
          networkName = 'BINANCE SMART-CHAIN';
        } else if (networkValue === 'ethereumAddress') {
          networkToken = 'ETH';
          airdropFee = (75 / tokenPrices?.ethereum?.usd || 0).toFixed(2);
          featuredFee = (25 / tokenPrices?.ethereum?.usd || 0).toFixed(2);
          networkName = 'ETHEREUM';
        } else {
          networkToken = 'MATIC';
          airdropFee = (75 / tokenPrices['matic-network']?.usd || 0).toFixed(0);
          featuredFee = (25 / tokenPrices['matic-network']?.usd || 0).toFixed(0);
          networkName = 'POLYGON MATIC';
        }
        setAirdropData({
          ...airdropData,
          networkValue,
          networkID,
          networkToken,
          airdropFee,
          networkName,
          featuredFee,
        });
      }
      return {};
    });
  };

  // const checkFeatured

  const updateStateWithTokenData = (apiResponse) => {
    const {
      tokenName, tokenSymbol, totalSupply, tokenDecimals, name, symbol, decimals,
    } = apiResponse;

    setAirdropData({
      ...airdropData,
      tokenName: tokenName || name || '',
      ticker: tokenSymbol || symbol || '',
      decimal: tokenDecimals || decimals || '',
      totalSupply: totalSupply || '',
    });
  };

  useEffect(() => {
    if (airdropData.contractAddress >= 42) {
      const tokenData = {
        contractAddress: airdropData.contractAddress,
        networkId: airdropData.networkID,
      };
      dispatch(getTokenData(tokenData)).then((res) => {
        if (res.error) {
          errorToast('Make sure you are connected to the right network');
        } else {
          updateStateWithTokenData(res.payload);
          successToast(res.payload.message);
        }
      });
    }
  }, [airdropData.networkID, airdropData.contractAddress]);

  const createAirdrop = (e) => {
    e.preventDefault();
    setCreatingMSG('Starting Transaction...');
    dispatch(payAirdropFee(totalFee)).then((res) => {
      if (res.error) {
        errorToast(res.payload);
        setActive(false);
        setCreatingMSG('');
      } else {
        // { userID: '', address: '', amount: '', refferalCount: '' }
        successToast('Transaction successfull, Creating Airdrop');
        setCreatingMSG('Creating Airdrop...');

        dispatch(createSocialAirdrop({
          ...airdropData, userID, chainID, address,
        })).then((res) => {
          if (res.error) {
            errorToast(res.payload);
            setActive(false);
            setCreatingMSG('');
          } else {
            successToast('Airdrop created successfully');
            dispatch(updateProjectStat('totalAirdrop'));
            setActive(false);
            setCreatingMSG('');
            setAirdropLink(`/airdrop/${res.payload?.airID}`);
            setInfoActive(true);
          }
        });
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>JetPad - Social-Airdrop</title>
        <meta
          name="description"
          content="Create Social Airdrop"
        />
        <meta property="og:title" content="JetPad - Social-Airdrop" />
        <meta
          property="og:description"
          content="Create Social Airdrop"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <section className="flex flex-col justify-center bg-mainBg text-content items-center">
        <div className=" my-8 mx-8 w-full flex flex-col  justify-center items-center">
          <div className="bg-subtleBg relative border border-accent-1 rounded-lg w-[98%] md:w-[70%] p-6 flex flex-col items-center shadow-md shadow-accent-1">
            <img
              src={kittenOne}
              alt="kitten"
              className=" absolute w-[99px] aspect-auto top-[-48px] right-0"
            />
            <h4 className="mb-6 font-black ">
              Unlock the Power of Token Ownership with Verified
              Participation - Join, Engage, and Earn!
            </h4>
            <p className=" font-mono mb-4 text-subHeading">
              Our innovative platform empowers new token owners to participate
              in a seamless airdrop experience.
              Enabling their users to Engage in social activities like joining your
              Telegram group and channel,
              following your Twitter page, and retweeting your pinned post.
            </p>
            <span className="font-light text-subText">
              Our robust system automatically verifies users actions, ensuring that only valid
              users gain access to the airdrop and receive your exclusive token.
            </span>
          </div>
        </div>
        <div className=" my-8 mx-6 md:mx-8 w-full flex flex-col  justify-center items-center">
          <div className="bg-subtleBg border border-accent-1 rounded-lg w-[98%] md:w-[70%] py-6 px-1 flex flex-col items-center ">
            <h2 className=" font-extrabold text-2xl mb-5 mt-5">Create Airdrop</h2>
            <form
              onSubmit={handleSubmit}
              className=" flex flex-col gap-3 w-full items-center"
            >
              {step === 0 && (
              <div className=" animated fadeInDown flex flex-col gap-3 w-full items-center">
                <h2 className=" font-bold text-2xl mb-10 mt-5 text-mainHeading">Token data</h2>
                <select
                  value={airdropData.networkValue}
                  onChange={handleOptionChange}
                  className=" border-accent-1 bg-mainBg border-2
                text-center rounded-xl font-mono text-sm md:text-lg hover:border-accent-1/90"
                >
                  <option value="BSCAddress" id="56" title="0x38">BSC</option>
                  {/* <option value="BSCAddress" id="97" title="0x61">BSC TestNet</option> */}
                  <option value="ethereumAddress" id="1" title="0x1">Ethereum</option>
                  <option value="polygonAddress" id="137" title="0x89">Polygon</option>
                </select>
                <input
                  name="contractAddress"
                  className="auth-input placeholder:text-xs border border-accent-1"
                  type="text"
                  placeholder="Paste token Contract address"
                  value={airdropData.contractAddress}
                  onChange={(e) => handleChange(e)}
                />
                <div className="flex gap-4 w-[90%]">
                  <input
                    name="tokenName"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="Token name"
                    value={airdropData.tokenName}
                    onChange={(e) => handleChange(e)}
                    disabled
                  />
                  <input
                    name="ticker"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="Ticker"
                    value={airdropData.ticker}
                    onChange={(e) => handleChange(e)}
                    disabled
                  />
                </div>
                <div className="flex gap-4 w-[90%]">
                  <input
                    name="totalSupply"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="Total supply"
                    value={formatNumber(airdropData.totalSupply)}
                    onChange={(e) => handleChange(e)}
                    disabled
                  />
                  <input
                    name="decimal"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="Decimal"
                    value={airdropData.decimal}
                    onChange={(e) => handleChange(e)}
                    disabled
                  />
                </div>
                <span className=" flex justify-start w-[90%] text-xs md:text-base text-subText">
                  {`Create Airdrop fee: ${airdropData.airdropFee} ${airdropData.networkToken}`}
                </span>
                <button
                  type="button"
                  className="self-center bg-mainHeading text-mainBg px-8 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                  onClick={() => setStep(1)}
                >
                  Next
                </button>
              </div>
              )}
              {step === 1 && (
              <div className=" animated fadeInDown flex flex-col gap-3 w-full items-center">
                <h2 className=" font-bold text-2xl mb-10 mt-5 text-mainHeading">Airdrop details</h2>
                <div className="flex gap-4 w-[90%]">
                  <input
                    name="airdropRound"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="Airdrop round (Round 1)"
                    value={airdropData.airdropRound}
                    onChange={(e) => handleChange(e)}
                  />
                  {/* 25%, 50% */}
                  <input
                    name="totalToken"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="number"
                    placeholder="Total token for Airdrop"
                    value={airdropData.totalToken}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="flex gap-4 w-[90%]">
                  <input
                    name="airdropAmount"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="number"
                    placeholder="Airdrop amount (550)"
                    value={airdropData.airdropAmount}
                    onChange={(e) => handleChange(e)}
                  />
                  <input
                    name="referralAmount"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="number"
                    placeholder="Token amount per referral"
                    value={airdropData.referralAmount}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="flex gap-4 w-[100%] justify-center">
                  <input
                    name="estLaunchPrice"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="number"
                    placeholder="Estimated token Launch Price ($0.04)"
                    value={airdropData.estLaunchPrice}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="flex gap-4 w-[90%]">
                  <label htmlFor="startDate" className="w-[100%]">
                    Start Date:
                    <input
                      id="startDate"
                      name="startDate"
                      className="auth-input appearance-none date-input w-[100%] placeholder:text-xs border border-accent-1"
                      type="date"
                      placeholder="Airdrop start date"
                      value={airdropData.startDate}
                      onChange={(e) => handleChange(e)}
                    />
                  </label>

                  <label htmlFor="endDate" className="w-[100%]">
                    End Date:
                    <input
                      id="endDate"
                      name="endDate"
                      className="auth-input appearance-none date-input w-[100%]  border border-accent-1"
                      type="date"
                      placeholder="Airdrop end date"
                      value={airdropData.endDate}
                      onChange={(e) => handleChange(e)}
                    />
                  </label>

                </div>
                <div className="flex gap-4 w-[90%]">
                  <input
                    name="telegramGroup"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="telegram group link"
                    value={airdropData.telegramGroup}
                    onChange={(e) => handleChange(e)}
                  />
                  <input
                    name="telegramChannel"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="telegram channel link"
                    value={airdropData.telegramChannel}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="flex gap-4 w-[90%]">
                  <input
                    name="twitterPageUserName"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="Twitter handle (eg: @JetPad_Finance)"
                    value={airdropData.twitterPageUserName}
                    onChange={(e) => handleChange(e)}
                  />
                  <input
                    name="twitterPinnedPost"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="Twitter Pinned Post Link"
                    value={airdropData.twitterPinnedPost}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="flex gap-6">

                  <button
                    type="button"
                    className="self-center bg-mainHeading text-mainBg px-8 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                    onClick={() => setStep(0)}
                  >
                    Prev
                  </button>
                  <button
                    type="button"
                    className="self-center bg-mainHeading text-mainBg px-8 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                    onClick={() => setStep(2)}
                  >
                    Next
                  </button>
                </div>
              </div>
              )}
              {step === 2 && (
              <div className=" animated fadeInDown flex flex-col gap-3 w-full items-center">
                <h2 className=" font-bold text-2xl mb-10 mt-5 text-mainHeading">Project details</h2>
                <div className=" flex gap-4 w-[100%] justify-center">
                  <textarea
                    cols="30"
                    rows="10"
                    name="airdropDetails"
                    className="auth-input placeholder:text-xs border border-accent-1 h-[120px]"
                    type="text"
                    placeholder="Airdrop Description"
                    value={airdropData.airdropDetails}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="flex gap-4 w-[100%] justify-center">
                  <textarea
                    cols="30"
                    rows="10"
                    name="projectDetails"
                    className="auth-input placeholder:text-xs border border-accent-1 h-[120px]"
                    type="text"
                    placeholder="Project Description"
                    value={airdropData.projectDetails}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="flex gap-4 w-[90%]">
                  <input
                    name="projectName"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="Project Name"
                    value={airdropData.projectName}
                    onChange={(e) => handleChange(e)}
                  />
                  <input
                    name="projectWebsite"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="Project Website"
                    value={airdropData.projectWebsite}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="flex gap-4 w-[90%]">
                  <input
                    name="twitterPageLink"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="Twitter Page Link"
                    value={airdropData.twitterPageLink}
                    onChange={(e) => handleChange(e)}
                  />
                  <input
                    name="whitepaper"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="White Paper link"
                    value={airdropData.whitepaper}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="flex w-[90%]">
                  <input
                    name="logoLink"
                    className="auth-input placeholder:text-xs border border-accent-1 w-full"
                    type="text"
                    placeholder="Logo link"
                    value={airdropData.logoLink}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="flex flex-col lg:flex-row gap-1 w-[90%]">
                  <label htmlFor="featured" className="lg:w-[40%]">
                    <input
                      type="checkbox"
                      name="featured"
                      id="featured"
                      className="mr-1"
                      checked={airdropData?.featured}
                      onChange={() => setAirdropData(
                        { ...airdropData, featured: !airdropData.featured },
                      )}
                    />
                    Featured Airdrop
                  </label>
                  <p id="featured-info" className="text-xs text-subText">
                    Selecting this option will boost your airdrop and make it more visible to users.
                    Also we pin your airdrop on our twitter page for 24hours.
                    However, it comes with an additional cost of
                    {` ${airdropData?.featuredFee} ${airdropData?.networkToken}`}
                  </p>

                </div>
                <div className="flex gap-6">

                  <button
                    type="button"
                    className="self-center bg-mainHeading text-mainBg px-8 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                    onClick={() => setStep(1)}
                  >
                    Prev
                  </button>
                  <button
                    type="submit"
                    className="self-center bg-[#11cc00] text-mainBg px-8 py-2 rounded-xl hover:bg-[#11cc00cc] text-lg font-bold mb-2 flex justify-center items-center"
                  >
                    Create
                  </button>
                </div>
              </div>
              )}
            </form>
          </div>
        </div>
        {active
      && (
      <ConfirmPopup
        setActive={setActive}
        handleClick={createAirdrop}
        title="Create Airdrop"
        creatingMSG={creatingMSG}
        message={`
        This is going to trigger one transaction.
        1: A transaction of ${totalFee} ${airdropData.networkToken} and Your Airdrop will be created.
        Please make sure you have enough ${airdropData.networkToken} to cover the transaction.
        `}
      />
      )}
        {infoActive && (
        <InfoPopup
          airdropLink={airdropLink}
          setInfoActive={setInfoActive}
        />
        )}
      </section>
    </>
  );
};

export default SocialAirdrop;
