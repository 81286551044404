import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Chart } from 'react-google-charts';
import { Helmet } from 'react-helmet';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { ethers } from '../web3/ethers';

// COMPONENTS
import { ConfirmPopup, DisSuccessPopup } from '../components';

// REDUX
import { getAirdropDetails, updateAirdropProps } from '../Redux/FirebaseAirdrop/firebaseAirdrop';
import { distributeAirdrop, transferAirdropToken } from '../Redux/Web3Airdrop/web3Airdrop';

// UTILITIES
import {
  checkActive, errorToast, getCountryCounts, openInNewTab, splitString, successToast,
} from '../utils';

// ASSETS
import { brandMark } from '../assets/images';

const DistributeAirdrop = () => {
  const [airdropDetails, setAirdropDetails] = useState(null);
  const [participantsChart, setParticipantsChart] = useState([['Country', 'Users']]);
  const [active, setActive] = useState(false);
  const [successActive, setSuccessActive] = useState(false);
  const [explorerLink, setExplorerLink] = useState('');
  const [creatingMSG, setCreatingMSG] = useState('');
  const isConnected = useSelector((state) => state.Web3Auth?.isConnected);
  const chain = useSelector((state) => state.Web3Auth?.chainID);
  const [percentage, setPercentage] = useState(0);
  const [emailData, setEmailData] = useState({ subject: '', content: '' });

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const perClaimed = ((
      Number(airdropDetails?.totalClaimed || 0) / Number(airdropDetails?.totalToken || 0)
    ) * 100)?.toFixed(1) || 0;
    setPercentage(perClaimed);
  }, [airdropDetails]);

  const radialStyle = {
    background: `conic-gradient(
      #0091ff ${percentage}%, 
      transparent ${percentage}% 100%
    )`,
  };

  const handleChange = (e) => {
    setEmailData({ ...emailData, [e.target.name]: e.target.value });
  };

  const handleClose = (value) => {
    dispatch(getAirdropDetails(id));
    setSuccessActive(value);
  };

  useEffect(() => {
    dispatch(getAirdropDetails(id)).then((res) => {
      if (res.error) {
        errorToast(res?.payload);
        navigate('/my-airdrops');
      } else {
        setAirdropDetails(res.payload);
        setParticipantsChart(getCountryCounts(res.payload?.participants));
      }
    });
  }, [id]);

  const recipients = airdropDetails?.participants.map((part) => part.address);
  const formattedAmounts = airdropDetails?.participants.map(
    (part) => {
      const total = Number(part.airdropAmount) + Number(part.referralReward);
      return ethers.utils.parseUnits(total.toString(), airdropDetails?.decimal);
    },
  );

  const handleDistribute = () => {
    if (!isConnected) {
      return errorToast('Please connect your wallet to Proceed');
    }

    if (chain !== airdropDetails?.chainID) {
      return errorToast(`Please connect to ${airdropDetails?.networkName} network`);
    }

    if (recipients?.length <= 0) {
      return errorToast('This airdrop had no Participants');
    }
    if (airdropDetails?.distributed) {
      return errorToast('This airdrop has been distributed');
    }
    const jetpadCA = {
      97: '0xF9D68e81c055c871886E1363994b40829C2b990E',
      56: '0x58529CeE4ecbfc18C5852e17ba2c28633F104Fd4',
      1: '0x8aFFCF1AfB6b32c387c667308BC85F7CaAb83ETH',
      137: '0x01Ff99bbd61117a4Aabbf4e2fddaCB9B074b75F7',
    };
    const explorer = {
      97: 'https://testnet.bscscan.com/',
      56: 'https://bscscan.com/',
      1: 'https://etherscan.io/',
      137: 'https://polygonscan.com/',
    };

    const airdropCA = jetpadCA[airdropDetails?.chainID];
    const curExplorer = explorer[airdropDetails?.chainID];

    const data = {
      contractAddress: airdropDetails?.contractAddress,
      decimal: airdropDetails?.decimal,
      tokenAmount: airdropDetails?.totalClaimed,
      airdropCA,
    };
    setCreatingMSG('Transfering...');

    dispatch(transferAirdropToken(data)).then((res) => {
      if (res.error) {
        errorToast(res?.payload);
        setCreatingMSG('');
        setActive(false);
      } else {
        successToast('Token transfered Successfully, Initiating Distribution...');

        // Delay dispatching distributeAirdrop by 10 seconds for the first txn to confirm
        setTimeout(() => {
          const data = {
            recipients,
            formattedAmounts,
            airdropCA,
            tokenAddress: airdropDetails?.contractAddress,
          };

          setCreatingMSG('Distributing...');
          dispatch(distributeAirdrop(data)).then((res) => {
            if (res.error) {
              errorToast(res?.payload);
              setCreatingMSG('');
              setActive(false);
            } else {
              successToast('Airdrop Distribution successfull');
              setExplorerLink(`${curExplorer}tx/${res.payload}`);
              const data = { id, distributed: true, explorerLink: `${curExplorer}tx/${res.payload}` };
              dispatch(updateAirdropProps(data)).then(() => {
                getAirdropDetails(id);
              });
              setCreatingMSG('');
              setActive(false);
              setSuccessActive(true);
            }
          });
        }, 10000);
      }
    });
    return {};
  };

  const options = {
    title: 'USER DISTRIBUTION',
    backgroundColor: '',
    titleTextStyle: {
      color: '#5398a3',
    },
    legend: {
      textStyle: {
        color: '#5398a3',
      },
    },
    is3D: true,
  };

  return (
    <>
      <Helmet>
        <title>JetPad - Distribute-Airdrop</title>
        <meta
          name="description"
          content="Distribute your airdrop"
        />
        <meta property="og:title" content="JetPad - Distribute-Airdrop" />
        <meta
          property="og:description"
          content="Distribute your airdrop"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <section className="min-h-screen  flex justify-center bg-mainBg text-content items-center">
        <div className=" my-8 mx-4 md:mx-8 w-full flex flex-col gap-10 justify-center items-center">
          <div className="flex flex-col w-full gap-5 items-center">
            <div className="flex w-full flex-row justify-between gap-2 bg-subtleBg px-5 rounded-lg border border-accent-1 shadow-md shadow-accent-1">
              <button
                type="button"
                className="self-center flex items-center justify-center bg-mainHeading rounded-md px-1 py-[0.15rem] text-white mr-[5%] hover:bg-mainHeading/60"
                onClick={() => navigate('/my-airdrops')}
              >
                <MdOutlineKeyboardBackspace />
                back
              </button>
              <div className=" flex gap-3">
                <span className="flex gap-1 md:gap-2 flex-col md:flex-row items-center border-r-2 border-accent-1 pr-2">
                  <span className="text-sm text-subHeading md:after:content-[':']">NETWORK</span>
                  <span className="text-xs font-light text-subText text-center">
                    {airdropDetails?.networkName}
                  </span>
                </span>
                <span className="flex gap-1 md:gap-2 flex-col md:flex-row items-center">
                  <span className="text-sm text-subHeading md:after:content-[':']">CA</span>
                  <span className="text-xs font-light text-subText text-center">
                    {splitString(airdropDetails?.contractAddress || '0x', 4, 38)}
                  </span>
                </span>
              </div>
              <div className="self-center flex h-full justify-end">
                <h5 className=" text-subHeading font-semibold md:font-bold text-sm md:text-base self-center text-end">
                  {airdropDetails?.tokenName}
                </h5>
                <img src={airdropDetails?.logoLink || brandMark} alt="token Logo" className="h-12 w-12 border border-accent-1 rounded-full translate-y-[-50%]" />
              </div>
            </div>

            {/* STATUS CARDS SECTION */}
            <div className=" grid grid-cols-2 md:grid-cols-4 gap-4 w-full">
              <div className="bg-subtleBg rounded-md p-2  border border-b-4 border-accent-1 flex flex-col gap-3 justify-center w-full ">
                <span className="text-subHeading font-semibold">
                  Token Allocation
                </span>
                <span className=" text-subText font-bold text-lg md:text-xl break-all">
                  {Number(airdropDetails?.totalToken || 0).toLocaleString()}
                </span>
              </div>

              <div className="bg-subtleBg rounded-md p-2 border border-b-4 border-accent-1 flex flex-row gap-3 justify-between w-full">
                <div className="flex flex-col gap-3">
                  <span className="text-subHeading font-semibold">
                    Token claimed
                  </span>
                  <span className=" text-subText font-bold text-lg md:text-xl break-all">
                    {Number(airdropDetails?.totalClaimed || 0).toLocaleString()}
                  </span>
                </div>
                <div className="radial-chart border border-accent-1" style={radialStyle}>
                  <span className="text-subText">
                    {percentage}
                    %
                  </span>
                </div>
              </div>

              <div className="bg-subtleBg rounded-md p-2 border border-b-4 border-accent-1 flex flex-col gap-3 justify-center w-full">
                <span className="text-subHeading font-semibold">
                  Participants
                </span>
                <span className=" text-subText font-bold text-lg md:text-xl break-all">
                  {(airdropDetails?.noOfPart)?.toLocaleString() || 0}
                </span>
              </div>
              <div className="bg-subtleBg rounded-md p-2 border border-b-4 border-accent-1 flex flex-col gap-3 justify-center w-full">
                <span className="text-subHeading font-semibold">
                  End Date
                </span>
                <span className=" text-subText font-bold text-lg md:text-xl break-all">
                  {airdropDetails?.endDate}
                </span>
              </div>
            </div>

            <div className="grid gap-6 grid-cols-1 md:grid-cols-9 w-full">
              {/* FIRST GRID SECTION */}
              <div className="md:col-span-6 w-full bg-subtleBg rounded-md p-2">
                <div className="flex justify-between p-2">
                  <span className="text-subHeading font-semibold">All Airdrop Participants</span>
                </div>

                <div className="border border-accent-1 rounded-lg mt-5 h-[250px] bg-mainBg overflow-y-auto">
                  <div className=" mx-3">
                    <div className="grid grid-cols-5  gap-2 rounded-lg p-1 place-items-start border-b border-accent-1">
                      <span className="text-sm text-subHeading">Dis. Name</span>
                      <span className="text-sm text-subHeading col-span-2">Address</span>
                      <span className=" text-sm text-subHeading">No. Ref</span>
                      <span className=" text-sm text-subHeading">Amount</span>
                    </div>
                    {airdropDetails ? (
                      airdropDetails?.participants.map((item, index) => (
                        <div key={item.userID} className={`grid grid-cols-5 gap-2 text-sm place-items-start ${index % 2 === 0 && 'even:bg-accent-1/30'}`}>
                          <span className="flex gap-1 items-center col-span-1 overflow-clip w-full">
                            <span className=" text-subText ">
                              {`${index + 1}.`}
                            </span>
                            <span className="w-fit truncate overflow-hidden whitespace-nowrap">
                              {item?.displayName || 'JetSetter'}
                            </span>
                          </span>
                          <span className="md:hidden lg:hidden col-span-2">
                            {splitString(item.address || '0x', 8, 36)}
                          </span>
                          <span className="hidden md:block col-span-2 ">
                            {splitString(item.address || '0x', 12, 36)}
                          </span>
                          <span className=" col-span-1">
                            {`${item.noOfReferral} Ref`}
                          </span>
                          <span className=" col-span-1 overflow-clip w-full whitespace-nowrap">
                            {`${Number(item.airdropAmount) + Number(item.referralReward)} ${airdropDetails?.ticker}`}
                          </span>
                        </div>
                      ))
                    ) : (
                      <span className=" text-sm text-center w-full">No Participants Yet!!!</span>
                    )}
                  </div>
                </div>
              </div>
              {/* SECOND GRID SECTION */}
              <div className="md:col-span-3 w-full flex flex-col gap-2">
                <div className=" bg-subtleBg rounded-md w-full p-2">
                  <div className="flex justify-between p-2">
                    <span className="text-subHeading font-semibold">Participants By Country</span>
                  </div>
                  <div>
                    <Chart
                      chartType="PieChart"
                      data={participantsChart}
                      options={options}
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid gap-6 grid-cols-1 md:grid-cols-9 w-full">
              {/* FIRST GRID SECTION */}
              <div className="md:col-span-6 w-full bg-subtleBg rounded-md p-2">
                <div className="flex justify-start flex-col gap-1 p-2">
                  <div className="flex justify-between">
                    <span className="text-subHeading font-semibold">Distribute Airdrop</span>
                    <span className=" font-bold text-subText self-end">
                      {airdropDetails?.tokenName}
                    </span>
                  </div>
                  <span className=" text-subText text-xs font-light">
                    Send Your Token To All the Eligible Airdrop Participants
                  </span>
                </div>

                <div className="flex flex-col w-full">
                  <div className=" my-4">
                    <div className=" mt-2 flex justify-center items-center border border-notice bg-notice text-subText rounded-lg p-2">
                      <span className="text-xs  font-semibold w-full text-center">
                        Clicking the distribute button below is going to trigger 2 transactions
                      </span>
                    </div>
                    <span className="block my-1 font-semibold mt-3">
                      1. A transfer of
                      {' '}
                      <span className=" font-bold text-mainHeading">
                        {`${Number(airdropDetails?.totalClaimed || 0).toLocaleString()}${airdropDetails?.ticker}`}
                      </span>
                      {' '}
                      to the JETPAD SMART CONTRACT
                    </span>
                    <span className="block my-1 font-semibold">
                      2. A distribution of
                      {' '}
                      <span className=" font-bold text-mainHeading">
                        {`${Number(airdropDetails?.totalClaimed || 0).toLocaleString()} to ${airdropDetails?.noOfPart}`}
                      </span>
                      {' '}
                      Airdrop Participants from JETPAD SMART CONTRACT
                    </span>
                  </div>
                  <span className="font-light text-subText my-2 self-center">
                    This is totally Free, you only pay for gas fee!!!
                  </span>
                  <button
                    type="button"
                    className="bg-mainHeading text-mainBg hover:bg-mainHeading/50 w-[40%] self-center py-1 rounded-lg font-bold disabled:bg-gray-500"
                    onClick={() => setActive(true)}
                    disabled={
                      !checkActive(airdropDetails?.startDate, airdropDetails?.endDate)
                      || airdropDetails?.distributed
                    }
                  >
                    DISTRIBUTE
                  </button>
                  <div className="flex flex-col w-full justify-center items-center mt-3">
                    <span className=" font-light text-red-500">
                      {!checkActive(airdropDetails?.startDate || 0, airdropDetails?.endDate || 0) && 'Airdrop Have not ended.. Please Wait...'}
                      {airdropDetails?.distributed && 'This Airdrop have been distributed...'}
                    </span>
                    {airdropDetails?.distributed && (
                      <button
                        type="button"
                        className="bg-mainHeading text-mainBg hover:bg-mainHeading/50 w-[40%] self-center py-1 rounded-lg font-bold disabled:bg-gray-500"
                        onClick={() => openInNewTab(airdropDetails?.explorerLink)}
                      >
                        View Transaction
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* SECOND GRID SECTION */}
              <div className="md:col-span-3 w-full flex flex-col gap-2">
                <div className=" bg-subtleBg rounded-md w-full p-2">
                  <div className="flex justify-between p-2">
                    <span className="text-subHeading font-semibold">Broadcast Email</span>
                    <span className=" text-subText text-xs font-light">send Email To all Participants</span>
                  </div>
                  <div className=" flex flex-col gap-2 p-4  ">
                    <label htmlFor="subject" className="w-full flex flex-col">
                      Email Subject
                      <input
                        name="subject"
                        className="auth-input w-full placeholder:text-xs border border-accent-1"
                        type="text"
                        placeholder="Enter email Subject"
                        value={emailData.subject}
                        onChange={(e) => handleChange(e)}
                      />
                    </label>
                    <label htmlFor="content" className="w-full flex flex-col">
                      Email body
                      <textarea
                        // cols="30"
                        rows="10"
                        name="content"
                        className="auth-input w-full placeholder:text-xs border border-accent-1"
                        type="text"
                        placeholder="Compose Email"
                        value={emailData.content}
                        onChange={(e) => handleChange(e)}
                      />
                    </label>
                  </div>
                  <div className="flex gap-2 w-full px-4 justify-evenly">
                    <button
                      type="button"
                      className=" bg-mainHeading text-mainBg hover:bg-mainHeading/50 text-lg px-2 py-1 rounded-lg font-bold"
                      disabled={airdropDetails?.distributed}
                    >
                      Send Email &#x2192;
                    </button>
                    <button
                      type="button"
                      className=" border border-mainHeading text-mainHeading text-lg px-2 py-1 rounded-lg  hover:bg-mainHeading hover:text-content font-semibold disabled:bg-gray-500"
                      disabled={airdropDetails?.distributed}
                    >
                      Save Draft
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {active
          && (
            <ConfirmPopup
              setActive={setActive}
              handleClick={handleDistribute}
              title="Distribute Airdrop"
              creatingMSG={creatingMSG}
              message={`
        This is going to trigger two transactions.
        1: A transfer of ${airdropDetails?.totalClaimed}${airdropDetails?.ticker} to JETPAD SMART CONTRACT.
        2: A distribution of ${airdropDetails?.totalClaimed} to ${airdropDetails?.noOfPart} Airdrop Participants from JETPAD SMART CONTRACT.
        Please make sure you have enough ${airdropDetails.networkToken} and ${airdropDetails.tokenName} to cover both transactions.
        `}
            />
          )}
        {successActive
          && (
            <DisSuccessPopup
              handleClose={handleClose}
              explorerLink={explorerLink}
              projectName={airdropDetails.tokenName}
            />
          )}
      </section>
    </>
  );
};

export default DistributeAirdrop;
