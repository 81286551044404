import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { Loader } from 'rsuite';
import { Helmet } from 'react-helmet';

// ICONS
import { AiOutlineEye } from 'react-icons/ai';

// Redux
import { logInUser } from '../../Redux/Auth/auth';

// Utils
import { successToast, errorToast } from '../../utils';

const SignIn = () => {
  const [userData, setUserDate] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const isLoggedIn = useSelector((state) => state.Auth?.isLoggedIn);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setUserDate({ ...userData, [e.target.name]: e.target.value });
  };

  const handeLogin = (e) => {
    setLoading(true);
    e.preventDefault();
    dispatch(logInUser(userData)).then((res) => {
      if (res.error) {
        errorToast(res.payload);
        setLoading(false);
      } else {
        successToast('Logged-in Successfully');
        navigate('/profile');
        setLoading(false);
      }
    });
  };

  if (isLoggedIn) {
    return <Navigate to="/profile" />;
  }

  return (

    <>
      <Helmet>
        <title>JetPad - SignIn</title>
        <meta
          name="description"
          content="Login to your Jetpad Account"
        />
        <meta property="og:title" content="JetPad - SignIn" />
        <meta
          property="og:description"
          content="Login to your Jetpad Account"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <section className=" min-h-screen overflow-hidden  flex justify-center bg-mainBg text-content items-center">
        <div className=" my-8 mx-2  md:mx-8 w-full flex flex-col  justify-center items-center">
          <div className=" bg-subtleBg border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-2 md:p-6 flex flex-col items-center shadow-md shadow-accent-1">
            <h2 className=" font-extrabold leading-3 text-3xl mb-10 mt-5">Sign-In</h2>
            <form
              onSubmit={handeLogin}
              className=" flex flex-col gap-5 w-full items-center"
            >
              <input
                name="email"
                className="auth-input placeholder:text-xs border border-accent-1"
                type="email"
                placeholder="Enter Email address"
                required
                value={userData.email}
                onChange={(e) => handleChange(e)}
              />
              <div className="auth-pass-div border border-accent-1">
                <input
                  name="password"
                  className="auth-pass placeholder:text-xs"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your password"
                  required
                  value={userData.password}
                  onChange={(e) => handleChange(e)}
                />
                <AiOutlineEye className="hover:cursor-pointer hover:text-[#006CFF]" onClick={() => setShowPassword(!showPassword)} />
              </div>
              <button
                type="submit"
                className="self-center bg-mainHeading text-mainBg px-4 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
              >
                {loading ? (
                  <>
                    <Loader size="sm" content="loggingIn..." speed="fast" />
                  </>
                ) : 'Login'}
              </button>
              <div className="flex mt-5 font-light items-center">
                Don&apos;t have an Account?&nbsp;
                <button
                  type="button"
                  className=" bg-none text-link font-semibold text-lg outline-none hover:text-link/90"
                  onClick={() => navigate('/signup')}
                >
                  Sign-Up
                </button>
              </div>
              <div className="flex my-0 font-light">
                <button
                  type="button"
                  className=" bg-none text-link font-semibold text-lg outline-none hover:text-link/90"
                  onClick={() => navigate('/reset-pass')}
                >
                  Forgot password?
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignIn;
