import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ethers } from '../../web3/ethers';
import { ERC20abi } from '../../web3/ERC20ABI';
import { JetpadAirdropABI } from '../../web3/JetpadAirdropABI';

// BASE URL
const BASEURL = process.env.REACT_APP_MORALIS_API_URL;

const initialState = {
  loading: false,
};

// Action-types
const GET_TOKEN_DATA = 'Web3Airdrop/web3Airdrop/GET_TOKEN_DATA';
const GET_TOKEN_DATA_MORALIS = 'Web3Airdrop/web3Airdrop/GET_TOKEN_DATA_MORALIS';
const PAY_AIRDROP_FEE = 'Web3Airdrop/web3Airdrop/PAY_AIRDROP_FEE';
const TRANSFER_AIRDROP_TOKEN = 'Web3Airdrop/web3Airdrop/TRANSFER_AIRDROP_TOKEN';
const CREATE_WEB3_DAPP_AIRDROP = 'Web3Airdrop/web3Airdrop/CREATE_WEB3_DAPP_AIRDROP';
const CLAIM_WEB3_DAPP_AIRDROP = 'Web3Airdrop/web3Airdrop/CLAIM_WEB3_DAPP_AIRDROP';
const WITHDRAW_DAPP_BALANCE = 'Web3Airdrop/web3Airdrop/WITHDRAW_DAPP_BALANCE';
const DISTRIBUTE_AIRDROP = 'Web3Airdrop/web3Airdrop/DISTRIBUTE_AIRDROP';

// Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_TOKEN_DATA}/pending`:
      return { ...state, loading: true };
    case `${GET_TOKEN_DATA}/fulfilled`:
      return {
        ...state, ...action.payload, loading: false,
      };
    case `${GET_TOKEN_DATA}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${PAY_AIRDROP_FEE}/pending`:
      return { ...state, loading: true };
    case `${PAY_AIRDROP_FEE}/fulfilled`:
      return {
        ...state, ...action.payload, loading: false,
      };
    case `${PAY_AIRDROP_FEE}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    default:
      return state;
  }
}

// Action Creators
// Get tokens Data moralis
export const getTokenDataMoralis = createAsyncThunk(GET_TOKEN_DATA_MORALIS,
  async (data, { rejectWithValue }) => {
    const { networkId, contractAddress } = data;
    try {
      const response = await axios.get(`${BASEURL}token-details`, {
        params: { contractAddress, networkId },
      });
      if (response.data[0].name !== '') {
        return { ...response.data[0], message: 'Token data loaded' };
      }
      return { message: 'Error: Select correct network or check address' };
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  });

// GET TOKEN DATA WEB3 ETHERJS
export const getTokenData = createAsyncThunk(GET_TOKEN_DATA, async (data, { rejectWithValue }) => {
  const { contractAddress } = data;
  try {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const tokenContract = new ethers.Contract(
        contractAddress,
        ERC20abi,
        provider,
      );

      const tokenName = await tokenContract.name();
      const tokenSymbol = await tokenContract.symbol();
      let totalSupply = await tokenContract.totalSupply();
      const tokenDecimals = await tokenContract.decimals();

      totalSupply = ethers.utils.formatUnits(totalSupply, tokenDecimals);
      const response = {
        tokenName, tokenSymbol, totalSupply, tokenDecimals, message: 'Token data loaded',
      };
      return response;
    }
    return { message: 'Use a Dapp browser' };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// PAY AIRFROP FEE
export const payAirdropFee = createAsyncThunk(PAY_AIRDROP_FEE,
  async (fee, { rejectWithValue }) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const signer = provider.getSigner();

      const signerAddress = await signer.getAddress(); // Get the address of the signer

      let balance = await provider.getBalance(signerAddress);
      balance = ethers.utils.formatEther(balance);
      if (Number(fee) >= Number(balance)) return rejectWithValue('Insufficient Balance');

      const tx = await signer.sendTransaction({
        to: '0xEeFa32059B0325B6ef79BAa1cF02A2096F81553a',
        value: ethers.utils.parseEther(fee.toString()),
      });

      return tx.hash;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

//  TRANSFER AIRDROP TOKEN
export const transferAirdropToken = createAsyncThunk(TRANSFER_AIRDROP_TOKEN,
  async (data, { rejectWithValue }) => {
    const {
      contractAddress, decimal, tokenAmount, airdropCA,
    } = data;
    try {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(
          contractAddress,
          ERC20abi,
          signer,
        );

        const amount = ethers.utils.parseUnits(tokenAmount.toString(), decimal);
        await tokenContract.transfer(airdropCA, amount);

        // const receipt = await tx.wait();

        return 'Token transfer successfull, Creating airdrop...';
      }
      return rejectWithValue('Use a Dapp browser');
    } catch (error) {
      return rejectWithValue(error?.data?.message);
    }
  });

// CREATE DAPP AIRDROP ON SMART CONTRACT
export const createWeb3DappAirdrop = createAsyncThunk(CREATE_WEB3_DAPP_AIRDROP,
  async (data, { rejectWithValue }) => {
    const {
      airdropFee, startDate, endDate, totalAmount, airdropAmount, tokenAddress, referralAmount,
      airdropContractAddress, decimal,
    } = data;

    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const signer = provider.getSigner();
        const airdropContract = new ethers.Contract(
          airdropContractAddress,
          JetpadAirdropABI,
          signer,
        );

        const etherAmount = ethers.utils.parseEther(airdropFee.toString());
        const tx = await airdropContract.createAirdrop(
          Math.floor(new Date(startDate).getTime() / 1000),
          Math.floor(new Date(endDate).getTime() / 1000),
          ethers.utils.parseUnits(totalAmount.toString(), decimal),
          ethers.utils.parseUnits(airdropAmount.toString(), decimal),
          tokenAddress,
          ethers.utils.parseUnits(referralAmount.toString(), decimal),
          { value: etherAmount },
        );

        const receipt = await tx.wait();

        const transactionHash = receipt?.transactionHash;
        const airdropIndexID = parseInt(receipt?.logs[0]?.topics[1], 16);

        return { airdropIndexID, transactionHash };
      } catch (error) {
        return rejectWithValue(error?.data?.message);
      }
    }
    return rejectWithValue('Use a Dapp browser');
  });

// CLAIM DAPP AIRDROP
export const claimWeb3DappAirdrop = createAsyncThunk(CLAIM_WEB3_DAPP_AIRDROP,
  async (data, { rejectWithValue }) => {
    const {
      airdropIndexID, airdropCA, airdropAmount, referrerAdd, referralAmount, decimal, claimFee,
    } = data;

    if (window.ethereum) {
      try {
        const fee = ethers.utils.parseEther(claimFee?.toString());

        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const signer = provider.getSigner();
        const airdropContract = new ethers.Contract(
          airdropCA,
          JetpadAirdropABI,
          signer,
        );

        await airdropContract.claimAirdrop(
          airdropIndexID,
          ethers.utils.parseUnits(airdropAmount.toString(), decimal),
          referrerAdd,
          ethers.utils.parseUnits(referralAmount.toString(), decimal),
          { value: fee },
        );

        // const receipt = await tx.wait();

        return 'Airdrop claimed successfully';
      } catch (error) {
        return rejectWithValue(error?.data?.message);
      }
    }
    return rejectWithValue('Use a Dapp browser');
  });

// WITHDRAW REMAING AIRDROP BALANCE
export const withdrawDappBalance = createAsyncThunk(WITHDRAW_DAPP_BALANCE,
  async (data, { rejectWithValue }) => {
    const {
      airdropIndexID, airdropCA,
    } = data;

    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const signer = provider.getSigner();
        const airdropContract = new ethers.Contract(
          airdropCA,
          JetpadAirdropABI,
          signer,
        );

        await airdropContract.withdrawTokens(
          airdropIndexID,
        );

        // const receipt = await tx.wait();

        return 'Withdrawal successfull';
      } catch (error) {
        return rejectWithValue(error?.data?.message);
      }
    }
    return rejectWithValue('Use a Dapp browser');
  });

// DISTRIBUTE AIRDROP TO PARTICIPANTS
export const distributeAirdrop = createAsyncThunk(DISTRIBUTE_AIRDROP,
  async (data, { rejectWithValue }) => {
    const {
      recipients, formattedAmounts, airdropCA, tokenAddress,
    } = data;

    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const signer = provider.getSigner();
        const airdropContract = new ethers.Contract(
          airdropCA,
          JetpadAirdropABI,
          signer,
        );

        const tx = await airdropContract.bulkDistributeTokens(
          tokenAddress, JSON.parse(JSON.stringify(recipients, null, 2)), formattedAmounts,
        );

        const receipt = await tx.wait();

        return receipt?.transactionHash;
      } catch (error) {
        return rejectWithValue(error?.data?.message);
      }
    }
    return rejectWithValue('Use a Dapp browser');
  });
