import React from 'react';
import PropTypes from 'prop-types';

// UTILITIES
import { handleCopy, successToast, openInNewTab } from '../utils';

// ASSETS
import { thankYou } from '../assets/images';

const DisSuccessPopup = ({ handleClose, explorerLink, projectName }) => (
  <section className="w-full animated fadeInUp h-full z-40 fixed bg-mainBg bg-opacity-80 top-0 bottom-0 left-0 flex justify-center items-center">
    <div className="bg-subtleBg relative border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col text-content items-center shadow-md shadow-accent-1">
      <h4 className=" font-extrabold text-xl md:text-3xl mb-10 mt-5 text-center">
        {projectName}
        <br />
        Airdrop Distributed Successfully
      </h4>

      <div className=" my-4 flex justify-center items-center w-[70%] bg-mainBg border border-accent-1 rounded-lg p-2 shadow-sm shadow-accent-1">
        <span className="text-xs  font-light text-mainHeading">
          🚀 Congratulations on Completing Your Airdrop on JetPad!

        </span>
      </div>
      <div className=" flex justify-center items-center flex-col gap-3 text-xs md:text-base w-full md:w-[80%] lg:w-[70%]">
        <p className=" text-center text-xl text-subHeading">
          Thank You For Choosing Jetpad As Your Airdrop Partner!!!
        </p>

        <div className=" m-2 p-1 rounded-lg self-center w-[90%] md:w-[80%] flex gap-2 items-center">
          <img
            src={thankYou}
            alt="thank you"
            className=""
          />
        </div>
      </div>
      <div className=" my-3 flex flex-col gap-1">
        <span className=" font-bold text-center">Explorer Transaction Link</span>
        <button
          type="button"
          className="text-link font-light flex flex-col items-center"
          onClick={() => {
            if (handleCopy(explorerLink)) {
              successToast('Transaction Link copied!');
            }
          }}
        >
          {explorerLink}
          <span className=" text-xs md:text-sm border border-mainHeading text-mainHeading hover:bg-mainHeading hover:text-subHeading  px-4 rounded-xl">Click to copy</span>
        </button>
      </div>
      <div className="w-full md:w-[60%] flex justify-center items-center gap-6">
        <button
          type="button"
          className="bg-mainHeading text-mainBg hover:bg-mainHeading/50 text-lg px-2 py-1 rounded-lg font-bold w-[80%] "
          onClick={() => {
            openInNewTab(explorerLink);
          }}
        >
          View Transaction
        </button>
        <button
          type="button"
          className="border border-mainHeading text-mainHeading text-lg px-2 py-1 rounded-lg  hover:bg-mainHeading hover:text-mainBg font-semibold w-[80%] "
          onClick={() => {
            handleClose(false);
          }}
        >
          Close &#x2192;
        </button>
      </div>
    </div>
  </section>
);

DisSuccessPopup.propTypes = {
  explorerLink: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectName: PropTypes.string.isRequired,
};
export default DisSuccessPopup;
