import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'react-js-loader';
import { Helmet } from 'react-helmet';

// ICONS
import { AiOutlineArrowLeft, AiOutlineCloseCircle } from 'react-icons/ai';
import { MdOutlineFileCopy } from 'react-icons/md';

// COMPONENTS
import {
  AirdropCard, AirdropSkeleton, SearchingSkeleton, ChartTable,
  TVChartContainer,
} from '../components';

// REDUX
import { getFeatured } from '../Redux/FirebaseAirdrop/firebaseAirdrop';
import {
  getTokenById, getTokenPrices, getTokenByAddress,
  searchTokenByAddress, searchTokenByName, getDexTrades,
} from '../Redux/TokenData/tokenData';

// UTILITIES
import {
  handleCopy, splitString, successToast,
  getNetworkId, errorToast, openInNewTab, convertNumberToString, extractHexPart,
  formatDateToSec, countTradeKinds,
} from '../utils';

// ASSETS
import {
  bitcoinCash, ads1, ads2, website1, brandMark,
} from '../assets/images';

// CONSTANTS
import { supportedNetworks } from '../constants';

const Tokens = () => {
  // const [ohlcvData, setOhlcvData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [contractSearchResults, setContractSearchResults] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [searching, setSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [searchNetwork, setSearchNetwork] = useState('');
  const [selectInputActive, setSelectInputActive] = useState(false);
  const [isContractSearch, setIsContractSearch] = useState(false);
  const [inputActive, setInputActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tradeKindCount, setTradeKindCount] = useState({ buyCount: 0, sellCount: 0 });

  const featuredAirdrop = useSelector((state) => state.firbaseAirdrop?.featuredAirdrop);
  const tokenDetails = useSelector((state) => state.tokenData?.tokenDetails);
  const tokenPrices = useSelector((state) => state.tokenData?.tokenPrices);
  const dexTrades = useSelector((state) => state.tokenData?.dexTrades);
  const { network, address } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // USE-EFFECT FOR FETCHING FEATURED AIRDROPS
  useEffect(() => {
    if (featuredAirdrop.length === 0) {
      dispatch(getFeatured());
    }
  }, []);

  // USE eFFECT FOR GETTING TOKEN WEB3 DATA AND CANDLE OHLC DATA
  useEffect(() => {
    dispatch(getTokenByAddress({ network, address })).then((res) => {
      if (res.payload?.relationships?.top_pools?.data?.length !== 0) {
        let poolAddress = res.payload?.relationships?.top_pools?.data[0].id;
        poolAddress = extractHexPart(poolAddress);

        dispatch(getDexTrades({ poolAddress, network })).then((res) => {
          if (!res.error) {
            setTradeKindCount(countTradeKinds(res?.payload));
          }
        });
      } else {
        errorToast('No Pool for this address');
      }
    });
  }, [network, address]);

  // USE-EFFECT FOR GETTING TOKEN AND PROJECT DATA IF USER VISITS DIRECT //  GET BASE TOEKN PRICES
  useEffect(() => {
    if (tokenDetails?.attributes?.address && tokenDetails?.contract_address !== address) {
      dispatch(getTokenById(tokenDetails?.attributes?.coingecko_coin_id));
    }

    if (!tokenPrices?.ethereum) {
      const ids = 'bitcoin,ethereum,binancecoin,matic-network';
      dispatch(getTokenPrices({ ids }));
    }
  }, [network, address, tokenDetails]);

  // NAVIGATING TO CLICKED FEATURED AIRDROP DETAILS PAGE
  const showAirdropDetails = (id) => {
    navigate(`/airdrop/${id}`);
  };

  // UTILITY FUNCTION TO CHECK IF USER'S INPUT IS A CONTRACT ADDRESS
  const isContractAddress = (input) => {
    const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

    return addressRegex.test(input);
  };

  // HANDLE SEARCHING TOKEN BY NAME FROM USERS INPUT
  const handleSearch = (query) => {
    setSearching(true);
    dispatch(searchTokenByName(query)).then((res) => {
      if (res.error) {
        setSearching(false);
        setSearched(true);
      } else {
        setSearching(false);
        setSearchResults(res?.payload);
        setSearched(true);
      }
    });
  };

  // HANDLE SEARCHING TOKEN BY CONTRACT ADDRESS FROM USERS INPUT
  const handleSearchCA = (query) => {
    setSearching(true);
    dispatch(searchTokenByAddress({ query, searchNetwork })).then((res) => {
      if (res.error) {
        setErrorMessage('Token Not Found, Verify CA or Network');
        setContractSearchResults([]);
        setSearching(false);
      } else {
        setContractSearchResults([res?.payload]);
        setSearching(false);
      }
    });
  };

  // USE-EFFECT FOR SETTING DELAY BEFORE MAKING API REST AND ALSO CHECKING INPUT TYPE
  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTypingTimeout = setTimeout(() => {
      if (searchQuery.trim() !== '') {
        if (isContractAddress(searchQuery)) {
          setIsContractSearch(true);
          if (searchNetwork !== '') {
            handleSearchCA(searchQuery);
          } else {
            setSelectInputActive(true);
            setErrorMessage('Select Token Network From The Options');
          }
        } else {
          handleSearch(searchQuery);
          setSearchResults([]);
          setIsContractSearch(false);
        }
      }
    }, 2000);

    setTypingTimeout(newTypingTimeout);
  }, [searchQuery, searchNetwork]);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleOptionChange = (e) => {
    const networkValue = e.target.value;
    setSearchNetwork(networkValue);
  };

  // FUNCTION FOR CLOSING SEARCH MODAL AND RESETING MOST DATA
  const closeSearchModal = () => {
    setInputActive(false);
    window.onscroll = function () { };
    setSearchQuery('');
    setSearchResults([]);
    setSearched(false);
    clearTimeout(typingTimeout);
    setErrorMessage('');
    isContractAddress(false);
    setSearchNetwork('');
    setSelectInputActive(false);
    setContractSearchResults([]);
  };

  // GETTING TOKENS DATA WHEN USER CLICKS ON A SEARCH RESULT
  const getTokenData = (id) => {
    // set a loading spinner
    dispatch(getTokenById(id)).then((res) => {
      if (!res.error) {
        const netID = getNetworkId(supportedNetworks, res.payload?.asset_platform_id);

        if (netID !== '') {
          const path = `/tokens/${netID}/${res.payload?.contract_address}`;
          closeSearchModal();
          navigate(path);
        } else {
          errorToast('Network not Supported');
        }
      }
    });
    // stop the loading spinner
  };

  return (
    <>
      <Helmet>
        <title>{`JetPad - ${tokenDetails?.name || 'Token'} Chart`}</title>
        <meta
          name="description"
          content={
            `${tokenDetails?.name || 'Token'} Chart} and Project details`
          }
        />
        <meta property="og:title" content={`JetPad - ${tokenDetails?.name || 'Token'} Chart`} />
        <meta
          property="og:description"
          content={
            `${tokenDetails?.name || 'Token'} Chart} and Project details`
          }
        />
        <meta property="og:image" content={tokenDetails?.image?.large || brandMark} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <section className="flex justify-center  items-center bg-mainBg text-content">
        {inputActive && (
        <button
          type="button"
          className="w-screen h-screen bg-mainBg bg-opacity-80 z-40 left-0 top-0 bottom-0 fixed cursor-default"
          onClick={closeSearchModal}
        >
          &nbsp;
          <span className="sr-only">close</span>
        </button>
        )}
        <div className="w-full text-content">
          <div className="flex w-full justify-center items-center text-xs font-mono gap-4 bg-gradient-to-r from-accent-1 via-accent-2/60 to-accent-1 py-2 border-b border-accent-1">
            <span>
              ETH:
              <span className="text-subText ml-1">{`$${tokenPrices?.ethereum?.usd || 0}`}</span>
            </span>
            <span>
              BNB:
              <span className="text-subText ml-1">{`$${tokenPrices?.binancecoin?.usd || 0}`}</span>
            </span>
            <span>
              MATIC:
              <span className="text-subText ml-1">{`$${tokenPrices['matic-network']?.usd || 0}`}</span>
            </span>
          </div>
          <div className="flex w-full justify-center items-center text-xs font-mono gap-4 bg-mainBg py-1 relative">
            <AiOutlineArrowLeft
              className=" absolute text-content cursor-pointer self-center hover:text-link font-bold text-xl left-2 md:left-10"
              onClick={() => navigate('/')}
            />
            <div className="w-[70%] md:w-[45%]  bg-mainBg rounded-t-md relative z-40">
              <div className="flex">
                <input
                  type="text"
                  placeholder="Search by coin name or paste CA"
                  className=" py-1 border-l border-r border-b-2 border-accent-1 w-full rounded-lg text-subHeading bg-transparent placeholder:text-center shadow-sm shadow-accent-1"
                  value={searchQuery}
                  onChange={handleChange}
                  onClick={() => {
                    setInputActive(true);
                    window.onscroll = function () { window.scrollTo(null, null); };
                  }}
                />
                {selectInputActive && (
                <select
                  value={searchNetwork}
                  onChange={handleOptionChange}
                  placeholder="Select Network"
                  className=" w-[20%] border-r border-t border-b text-xs bg-subtleBg text-subText"
                >
                  <option value="">Select Network</option>
                  <option value="bsc">BSC</option>
                  <option value="eth">Ethereum</option>
                  <option value="polygon_pos">Polygon</option>
                </select>
                )}
              </div>
              {inputActive && (!isContractSearch ? (
                <div
                  className="w-full min-h-[189px] max-h-[70vh] border border-accent-1 bg-subtleBg absolute z-20 rounded-b-md overflow-x-auto px-2 py-3 shadow-sm shadow-accent-1"
                >
                  {searching ? (
                    <SearchingSkeleton count={3} />
                  ) : (
                    <div className="w-full flex gap-2 bg-mainBg items-center mb-2">
                      <div className=" flex gap-1 items-center">
                        <span>Coins:</span>
                        <span className="h-[26px] w-[26px] bg-accent-1 rounded-full flex justify-center items-center">
                          <span className="text-xs">{searchResults?.length}</span>
                        </span>
                      </div>
                      <div className="w-full bg-accent-1 h-[2px] rounded-md" />
                      <AiOutlineCloseCircle className=" hover:text-red-500 h-6 w-6 cursor-pointer" onClick={closeSearchModal} />
                    </div>
                  )}
                  <div className=" flex flex-col gap-2">
                    {searchResults?.length !== 0 ? (
                      searchResults?.map((coin) => (
                        <button
                          type="button"
                          key={coin?.id}
                          onClick={() => getTokenData(coin?.id)}
                          className="flex gap-3 bg-mainBg p-2 rounded-md w-full h-full hover:bg-accent-1/30"
                        >
                          <img
                            src={coin?.large || coin?.thumb}
                            alt="coin logo"
                            className="h-[28px] w-[28px] rounded-full self-center"
                          />
                          <div className="flex flex-col items-start">
                            <h3 className=" text-xl font-semibold">{coin?.name}</h3>
                            <div className="flex gap-3">
                              <span className="flex gap-1">
                                <span className=" font-extralight text-subText">Symbol:</span>
                                <span className=" font-semibold">{coin?.symbol}</span>
                              </span>
                              <span className="flex gap-1">
                                <span className=" font-extralight text-subText">Market Rank:</span>
                                <span className=" font-semibold text-[#0ecb81]">{coin?.market_cap_rank}</span>
                              </span>
                            </div>
                          </div>
                        </button>
                      ))
                    ) : (
                      <>
                        {searched && (
                        <div className="w-full flex flex-col justify-center items-center gap-3">
                          <h5 className=" text-xl font-semibold">No Result Found</h5>
                          <span className=" text-center text-xs">
                            We can&apos;t find anything with that term at the moment,
                            try searching something else.
                          </span>
                        </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="w-full min-h-[189px] max-h-[70vh] border border-accent-1 bg-subtleBg absolute z-20 rounded-b-md overflow-x-auto px-2 py-3"
                >
                  {searching ? (
                    <SearchingSkeleton count={3} />
                  ) : (
                    <div className="w-full flex gap-2 bg-mainBg items-center mb-2">
                      <div className=" flex gap-1 items-center">
                        <span>Tokens:</span>
                        <span className="h-[26px] w-[26px] bg-accent-1 rounded-full flex justify-center items-center">
                          <span className="text-xs">{contractSearchResults?.length}</span>
                        </span>
                      </div>
                      <div className="w-full bg-accent-1 h-[2px] rounded-md" />
                      <AiOutlineCloseCircle className=" hover:text-red-500 h-6 w-6 cursor-pointer" onClick={closeSearchModal} />
                    </div>
                  )}
                  <div className=" flex flex-col gap-2">
                    {contractSearchResults?.length !== 0 ? (
                      contractSearchResults?.map((token) => (
                        <button
                          type="button"
                          key={token?.id}
                          onClick={() => getTokenData(token?.attributes?.coingecko_coin_id)}
                          className="flex gap-3 bg-mainBg p-2 rounded-md w-full h-full hover:bg-accent-1/30"
                        >
                          <div className="flex flex-col items-start">
                            <h3 className=" text-xl font-semibold">{token?.attributes?.name}</h3>
                            <div className="flex gap-3">
                              <span className="flex gap-1">
                                <span className=" font-extralight text-subHeading">Symbol:</span>
                                <span className=" font-semibold text-subText">{token?.attributes?.symbol}</span>
                              </span>
                              <span className="flex gap-1">
                                <span className=" font-extralight text-subHeading">Price:</span>
                                <span className=" font-semibold text-subText">
                                  {`$${Number(token?.attributes?.price_usd)?.toFixed(3)}`}
                                </span>
                              </span>
                              <span className="flex gap-1">
                                <span className=" font-extralight text-subHeading">Market Cap:</span>
                                <span className=" font-semibold text-[#0ecb81]">
                                  {`$${Number(token?.attributes?.market_cap_usd)?.toFixed(3)}`}
                                </span>
                              </span>
                            </div>
                          </div>
                        </button>
                      ))
                    ) : (
                      <>
                        {errorMessage !== '' && (
                        <div className="w-full flex flex-col justify-center items-center gap-3">
                          <span className=" text-center text-xs">
                            {errorMessage}
                          </span>
                        </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <section className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-7 bg-mainBg text-content gap-2 mx-3">
            <div className=" order-2 md:order-1 lg:order-1 flex flex-col gap-2 px-2 mt-12 md:mt-3">
              <div className="flex flex-col gap-1 font-mono my-1">
                <div className="grid grid-cols-2 gap-1">
                  <div className="border border-accent-1 rounded-md p-1 flex flex-col justify-center items-center">
                    <span className=" text-subText">PRICE</span>
                    <span className="text-content">
                      {`$${Number(tokenDetails?.attributes?.price_usd).toFixed(2) || <Loader type="ping-cube" color="#0ecb81" size={50} />}`}
                    </span>
                  </div>
                  <div className="border border-accent-1 rounded-md p-1 flex flex-col justify-center items-center">
                    <span className=" text-subText">MKT CAP</span>
                    <span className="text-content">
                      {`$${convertNumberToString(Number(tokenDetails?.attributes?.market_cap_usd)?.toFixed(0)) || <Loader type="ping-cube" color="#0ecb81" size={50} />}`}
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-1 gap-1">
                  <div className="border border-accent-1 rounded-md p-1 flex flex-col justify-center items-center">
                    <span className=" text-subText">T-SUPPLY</span>
                    <span className="text-content">
                      {convertNumberToString((parseFloat(Number(tokenDetails?.attributes?.total_supply)) / 10 ** 21)?.toFixed(0)) || <Loader type="ping-cube" color="#0ecb81" size={50} />}
                    </span>
                  </div>
                  <div className="border border-accent-1 rounded-md p-1 flex flex-col justify-center items-center">
                    <span className=" text-subText">24H VOL</span>
                    <span className="text-content">
                      {`$${convertNumberToString(Number(tokenDetails?.attributes?.volume_usd?.h24)?.toFixed(0)) || <Loader type="ping-cube" color="#0ecb81" size={50} />}`}
                    </span>
                  </div>
                </div>
                <div className="border border-accent-1 rounded-md p-1 flex flex-col justify-center items-center">
                  <span className=" text-subText">CA</span>
                  <span className="text-content">
                    <button
                      type="button"
                      className=""
                      onClick={() => {
                        if (handleCopy(tokenDetails?.contract_address)) {
                          successToast('Contract address copied!');
                        }
                      }}
                    >
                      {tokenDetails?.contract_address ? splitString(tokenDetails?.contract_address, 4, 38) : <Loader type="ping-cube" color="#0ecb81" size={50} />}
                    </button>
                  </span>
                </div>
              </div>
              <div className="w-full my-1">
                <h4 className=" font-bold bg-subtleBg border-b-2 border-accent-1 rounded-md w-full text-center">NETWORK(S)</h4>
                <div className=" h-[160px] overflow-x-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-200 p-2">
                  <div className="h-full w-full grid grid-cols-2 md:grid-cols-1 gap-1">
                    {tokenDetails?.platforms
                      ? Object.entries(tokenDetails?.platforms).map(([platform, address]) => (
                        <div key={platform} className="border border-accent-1 rounded-md p-0 flex flex-col justify-center items-center h-fit">
                          <span className=" text-subText overflow-clip">
                            {`${platform?.slice(0, 9)}:`}
                          </span>
                          <span className="text-content">
                            <button
                              type="button"
                              className="after:content-['_↗'] hover:bg-link/70 p-0 m-0"
                              onClick={() => {
                                if (handleCopy(address)) {
                                  successToast('Contract address copied!');
                                }
                              }}
                            >
                              {address ? splitString(address, 4, 38)?.slice(0, 11) : 'null'}
                            </button>
                          </span>
                        </div>
                      )) : (
                        <div>No platform addresses to display.</div>
                      )}
                  </div>
                </div>
              </div>
              <div className=" my-1 w-full">
                <h4 className=" font-bold bg-subtleBg border-b-2 border-accent-1 rounded-md w-full text-center">EXPLORER(S)</h4>
                <div className="h-[160px] overflow-x-auto flex flex-col gap-2">
                  {tokenDetails?.links?.blockchain_site?.filter((str) => str !== '')?.length >= 1
                    ? tokenDetails?.links?.blockchain_site?.filter((str) => str !== '')?.map((ex) => (
                      <div key={ex} className="flex items-center gap-3  flex-wrap pb-1 border-b border-accent-1 w-fit rounded-b-lg">
                        <h4 className=" h-[32px] w-[32px] bg-subtleBg flex justify-center items-center rounded-lg">
                          <img src={`https://www.google.com/s2/favicons?sz=64&domain_url=${ex || ''}`} alt="explorer" className="h-[24px] w-[24px] rounded-full" />
                        </h4>
                        <div className="flex gap-2 font-semibold flex-wrap">
                          <a
                            href={ex}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="after:content-['_↗'] lg:after:content-[''] hover:text-link/70 text-link"
                          >
                            Transactions
                          </a>
                          |
                          <a
                            href={`${ex}#balances`}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="after:content-['_↗'] lg:after:content-[''] hover:text-link/70 text-link"
                          >
                            Holders
                          </a>
                        </div>
                      </div>
                    )) : (
                      <span>
                        No Explorer Data
                      </span>
                    )}
                </div>
              </div>
              <div className="w-full my-2">
                <h4 className=" font-bold bg-subtleBg border-b-2 border-accent-1 rounded-md w-full text-center">COMMUNITE(S)</h4>
                <div className="flex flex-col gap-1 my-1">
                  {tokenDetails?.links?.chat_url?.filter((str) => str !== '')?.length >= 1
                    ? tokenDetails?.links?.chat_url?.filter((str) => str !== '')?.map((chat) => (
                      <div key={chat} className="flex items-center gap-3  flex-wrap w-full">
                        <h4 className=" h-[32px] w-[32px] bg-subtleBg flex justify-center items-center rounded-lg">
                          <img
                            src={`https://www.google.com/s2/favicons?sz=64&domain_url=${chat || ''}`}
                            alt="community"
                            className="h-[24px] w-[24px] rounded-full"
                          />
                        </h4>
                        <div className="flex gap-2 font-semibold flex-wrap">
                          <a
                            href={chat}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="after:content-['_↗'] hover:text-link/70 text-link"
                          >
                            Visit
                          </a>
                        </div>
                      </div>
                    )) : (
                      <span>
                        No Community Data
                      </span>
                    )}
                </div>
              </div>
              <div className=" border border-accent-1 rounded-md flex flex-col justify-center items-center px-2 py-1">
                <span className=" text-subText text-center">TRADES</span>
                <div className="flex flex-row justify-between items-center w-full">
                  <span className=" text-subText flex justify-center items-center">
                    <div className=" h-1 w-1 bg-[#22C55E] rounded-full" />
                    Buys
                  </span>
                  <span className="text-content">
                    {tradeKindCount?.buyCount}
                  </span>
                </div>
                <div className="flex flex-row justify-between items-center w-full">
                  <span className=" text-subText flex items-center justify-center">
                    <div className=" h-1 w-1 bg-[#EF4444] rounded-full" />
                    Sells
                  </span>
                  <span className="text-content">
                    {tradeKindCount?.sellCount}
                  </span>
                </div>
              </div>
              <div className=" border border-accent-1 rounded-md flex flex-col justify-center items-center px-2 py-1">
                <span className=" text-subText text-center">VOTES</span>
                <div className="flex flex-row justify-between items-center w-full">
                  <span className=" text-subText flex justify-center items-center">
                    <div className=" h-1 w-1 bg-[#22C55E] rounded-full" />
                    Up-Votes
                  </span>
                  <span className="text-content">
                    {`${tokenDetails?.sentiment_votes_up_percentage}%`}
                  </span>
                </div>
                <div className="flex flex-row justify-between items-center w-full">
                  <span className=" text-subText flex items-center justify-center">
                    <div className=" h-1 w-1 bg-[#EF4444] rounded-full" />
                    Down-Votes
                  </span>
                  <span className="text-content">
                    {`${tokenDetails?.sentiment_votes_down_percentage}%`}
                  </span>
                </div>
              </div>
            </div>
            <div className=" order-1 md:order-2 md:col-span-2 lg:col-span-4 mt-1 bg-subtleBg rounded-md">
              <div className="w-full bg-subtleBg p-1 flex items-center justify-between">
                <div className="flex gap-1 items-center">
                  <img src={tokenDetails?.image?.large || brandMark} alt="token lgog" className="h-[36px] w-[36px] rounded-full" />
                  <span className="flex flex-col">
                    <span className=" text-xl font-bold">{tokenDetails?.name || <Loader type="ping-cube" color="#0ecb81" size={50} />}</span>
                    <span className=" text-[#0ecb81]">
                      {`$${Number(tokenDetails?.attributes?.price_usd || 0).toFixed(5)}`}
                    </span>
                  </span>
                </div>
                <div className="flex gap-2">
                  <button
                    type="button"
                    className="px-2 bg-[#b9d8d6] rounded-lg text-black font-bold hover:bg-[#708f8d] hover:text-white"
                    onClick={() => successToast('Comming Soon!')}
                  >
                    Trade
                  </button>
                  <a
                    href={tokenDetails?.links?.blockchain_site[0]}
                    rel="noopener noreferrer"
                    target="_blank"
                    className=" h-[32px] w-[32px] bg-[#b9d8d6] hover:bg-[#708f8d] hover:text-white flex justify-center items-center rounded-lg"
                    title="Token Blockchain Explorer"
                  >
                    <img
                      src={`https://www.google.com/s2/favicons?sz=64&domain_url=${tokenDetails?.links?.blockchain_site[0]}` || bitcoinCash}
                      alt="explorer"
                      className="h-[24px] w-[24px] rounded-full"
                    />
                  </a>
                  <a
                    href={tokenDetails?.links?.homepage[0]}
                    rel="noopener noreferrer"
                    target="_blank"
                    className=" h-[32px] w-[32px] bg-[#b9d8d6] hover:bg-[#708f8d] hover:text-white flex justify-center items-center rounded-lg"
                    title="Official Website"
                  >
                    <img
                      src={website1}
                      alt="explorer"
                      className="h-[30px] w-[30px] rounded-full"
                    />
                  </a>
                </div>
              </div>
              <div id="chart" className=" bg-accent-1 text-center">
                <TVChartContainer />
              </div>
              <div className=" my-4 h-[291px] overflow-x-auto w-full">
                <table className="w-full table-auto">
                  <thead className="border bg-black sticky top-0">
                    <tr className=" p-0 bg-mainBg hover:bg-mainBg text-xs font-light">
                      <th className=" text-xs w-full">TIME</th>
                      <th>TYPE</th>
                      <th>{`PRICE ${network?.toUpperCase()}`}</th>
                      <th>PRICE USD</th>
                      <th>{`$${tokenDetails?.attributes?.symbol}`}</th>
                      <th>TOTAL USD</th>
                      <th>FROM</th>
                      <th>TX</th>
                    </tr>
                  </thead>
                  <tbody className="w-full text-xs font-light">
                    {!dexTrades || dexTrades?.length === 0 ? (
                      <tr className="">
                        <td className="text-sm text-subText w-full text-center" colSpan="8">
                          No Dex Trades For This Token
                        </td>
                      </tr>
                    ) : (
                      dexTrades?.map((trade) => (
                        <tr key={trade?.id} className={`w-full ${trade.attributes.kind === 'buy' ? 'text-[#22C55E]' : 'text-[#EF4444]'}`}>
                          <td className="w-full">{formatDateToSec(trade.attributes.block_timestamp)}</td>
                          <td>
                            {trade.attributes.kind.charAt(0).toUpperCase()
                            + trade.attributes.kind.slice(1)}
                          </td>
                          <td>{Number(trade.attributes.price_to_in_currency_token)?.toFixed(5)}</td>
                          <td>{Number(trade.attributes.price_to_in_usd)?.toFixed(3)}</td>
                          <td>{Number(trade.attributes.to_token_amount)?.toFixed(3)}</td>
                          <td>{Number(trade.attributes.volume_in_usd)?.toFixed(2)}</td>
                          <td>
                            <button
                              type="button"
                              className="text-link flex gap-1"
                              onClick={() => {
                                if (handleCopy(trade.attributes.tx_from_address)) {
                                  successToast('Address copied!');
                                }
                              }}
                            >
                              {splitString(trade.attributes.tx_from_address, 4, 38)}
                              <MdOutlineFileCopy className=" text-link" />
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="text-link flex gap-1"
                              onClick={() => {
                                if (handleCopy(trade.attributes.tx_hash)) {
                                  successToast('Tnx hash copied!');
                                }
                              }}
                            >
                              {splitString(
                                trade.attributes.tx_hash, 4,
                                (trade.attributes.tx_hash?.length - 4),
                              )}
                              <MdOutlineFileCopy className=" text-link" />
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className=" order-3 md:col-span-3 lg:col-span-2 flex flex-col gap-2 bg-subtleBg p-3 rounded-md border-b border-l lg:border-l-0 border-r border-accent-1">
              <h4 className=" font-bold bg-mainBg rounded-md border border-accent-1 w-full text-center">FEATURED AIRDROPS</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-3 place-items-center">
                {featuredAirdrop.length !== 0 ? (featuredAirdrop.slice(0, 3).map((airdrop) => (
                  <AirdropCard
                    key={airdrop?.id}
                    airdrop={airdrop}
                    showAirdropDetails={showAirdropDetails}
                  />
                ))) : (
                  <AirdropSkeleton count={3} />
                )}
              </div>
              <h4 className=" font-bold bg-mainBg rounded-md border border-accent-1 w-full text-center">ADS</h4>
              <div className=" text-center font-bold italic">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-3 place-items-center">
                  <img src={ads1} alt="ads1" className="w-full cursor-progress hidden lg:block my-1 h-[190px]" />
                  <button
                    type="button"
                    onClick={() => openInNewTab('https://bc.game/')}
                  >
                    <img
                      src={ads2}
                      alt="ads"
                      className="w-full md:h-full cursor-progress my-1"
                    />
                  </button>
                </div>
                <a
                  href="https://docs.jetpad.finance/advertise-with-us"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="after:content-['_↗'] hover:text-link/80 cursor-pointer italic text-link my-3"
                  title="Advert"
                >
                  Advertise with Us
                </a>
              </div>
            </div>
          </section>
          <section className="mx-3 mt-4 flex flex-col gap-12">
            <div className="flex flex-col justify-center items-center mt-2">
              <h3 className=" text-2xl font-bold mb-4">Project Details</h3>
              <p className=" font-semibold" dangerouslySetInnerHTML={{ __html: tokenDetails?.description?.en?.replace(/(\r\n|\n|\r)/g, '<br>') || 'No Project Details' }} />
            </div>
            <div className="flex flex-col justify-center items-center">
              <h3 className=" text-2xl font-bold mb-4">Project Notice</h3>
              <p className=" font-semibold" dangerouslySetInnerHTML={{ __html: tokenDetails?.public_notice?.replace(/(\r\n|\n|\r)/g, '<br>') || 'No Important Notice At the Momemt!!' }} />
            </div>
            <ChartTable />
            <div className="border border-[#353F3E] rounded-lg mb-3 p-2">
              <p className="text-sm font-thin text-center">
                ⚠️ Disclaimer: All content provided herein our website, hyperlinked sites,
                associated applications, forums, blogs, social media accounts and other
                platforms (Site) is for your general information only, procured from
                third party sources. We make no warranties of any kind in relation
                to our content, including but not limited to accuracy and updatedness.
                No part of the content that we provide constitutes financial advice,
                legal advice or any other form of advice meant for your specific
                reliance for any purpose. Any use or reliance on our content is
                solely at your own risk and discretion. You should conduct your own
                research, review, analyse and verify our content before relying on
                them. Trading is a highly risky activity that can lead to major losses,
                please therefore consult your financial advisor before making any decision.
                No content on our Site is meant to be a solicitation or offer.
              </p>
              <div className=" mt-3 flex justify-around items-center font-light">
                <span>
                  <a
                    href="https://www.tradingview.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="after:content-['_↗'] hover:text-link/80 cursor-pointer italic text-link my-3"
                    title="Trading View"
                  >
                    Crypto charts by TradingView
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.coingecko.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="after:content-['_↗'] hover:text-link/80 cursor-pointer italic text-link my-3"
                    title="coingecko"
                  >
                    Data from coingecko
                  </a>
                </span>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Tokens;
