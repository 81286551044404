import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// UTILITIES
import { calcDays, formatDate, formatNumber } from '../utils';

const CreatedCard = ({ airdrop, handleDistribute, handleClaim }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-mainBg text-content border border-accent-1 p-3 rounded-lg flex flex-col gap-3">
      <div className="flex justify-between items-center w-full">
        <div className="flex flex-col gap-1 overflow-hidden whitespace-nowrap">
          <div className="flex gap-1 items-center ">
            <img
              src={airdrop.logoLink}
              alt="bitcoin cash"
              className="h-[38px] w-[38px] rounded-full"
            />
            <span className="inline-block font-semi-bold text-3xl truncate overflow-hidden whitespace-nowrap">{airdrop.projectName}</span>
          </div>
          <span className="font-barlow text-sm italic text-subHeading">{airdrop.networkName}</span>
        </div>
        <div className="flex flex-col gap-2 justify-center items-cente">
          <span className=" font-thin">{airdrop.airdropRound}</span>
          <span className={calcDays('2023-10-01', airdrop.endDate)?.style}>
            {calcDays('2023-10-01', airdrop.endDate)?.text}
          </span>
        </div>
      </div>
      <div className="w-full grid grid-cols-3 place-content-center place-items-center">
        <div className="flex flex-col gap-1 place-self-start">
          <span className=" font-bold text-subHeading text-sm md:text-base">Allocation</span>
          <span className=" italic font-black text-xs md:text-sm text-subText w-full break-all">
            {`${formatNumber(airdrop.totalToken)} ${airdrop.ticker}`}
          </span>
        </div>
        <div className="flex flex-col gap-1 items-center">
          <span className=" font-bold text-subHeading text-sm md:text-base ">Created at</span>
          <span className=" italic font-black text-xs md:text-sm text-subText">{formatDate(airdrop.timeStamp)}</span>
        </div>
        <div className="flex flex-col gap-1 place-self-end">
          <span className=" font-bold text-subHeading text-sm md:text-base">Type</span>
          <span className=" italic font-black text-xs md:text-sm text-subText">{airdrop.airdropType}</span>
        </div>
      </div>
      <div className=" w-full grid grid-cols-2 gap-6">
        {airdrop.airdropType === 'Social Airdrop' ? (
          <>
            <button
              type="button"
              className=" px-1 md:px-2 py-1 md:py-2 border border-mainHeading rounded-md hover:bg-mainHeading hover:text-white after:content-['_↗']"
              onClick={() => navigate(`/airdrop/${airdrop.airdropID}`)}
            >
              View Airdrop
            </button>
            <button
              type="button"
              className=" px-1 md:px-2 py-1 md:py-2 border border-mainHeading rounded-md hover:bg-mainHeading hover:text-white after:content-['_↗']"
              onClick={() => handleDistribute(airdrop.airdropID)}
            >
              View Details
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className=" px-1 md:px-3 py-1 md:py-2 border border-[#006CFF] rounded-md hover:bg-[#006CFF] after:content-['_↗']"
              onClick={() => navigate(`/dapp/${airdrop.dappURL || `T3${airdrop.airdropID}`}`)}
            >
              View Dapp
            </button>
            <button
              type="button"
              className=" px-1 md:px-3 py-1 md:py-2 border border-[#006CFF] whitespace-nowrap rounded-md hover:bg-[#006CFF] disabled:bg-gray-700 after:content-['_↗']"
              onClick={() => handleClaim(
                airdrop.startDate, airdrop.endDate, airdrop.airdropID, airdrop.airdropIndexID,
                airdrop.airdropCA, airdrop.chainID, airdrop.networkName,
              )}
            >
              Claim Balance
            </button>
          </>
        )}
      </div>
    </div>
  );
};

CreatedCard.propTypes = {
  airdrop: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleClaim: PropTypes.func.isRequired,
  handleDistribute: PropTypes.func.isRequired,
};

export default CreatedCard;
