import React, { useState } from 'react';

// PAGES
import DappT1 from './DappT1';
import DappT2 from './DappT2';
import DappT3 from './DappT3';

const DappDemo = () => {
  const [template, setTemplate] = useState(1);

  const handleSelect = (e) => {
    setTemplate(parseInt(e.target.value, 10));
  };
  return (
    <>
      <div className="w-full bg-black flex justify-center items-center py-2 static">
        <select
          value={template}
          onChange={handleSelect}
          className=" border-[#0056CC] bg-[#fff] border-2 text-center rounded-xl font-mono text-sm md:text-lg hover:border-[#114fa5]"
        >
          <option value={1} id="Dapp1">Template 1</option>
          <option value={2} id="Dapp2">Template 2</option>
          <option value={3} id="Dapp3">Template 3</option>
        </select>
      </div>
      {template === 1 && <DappT1 />}
      {template === 2 && <DappT2 />}
      {template === 3 && <DappT3 />}
    </>
  );
};

export default DappDemo;
