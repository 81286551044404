import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// COMPONENTS
import CreatedCard from './CreatedCard';

// REDUX
import { withdrawDappBalance } from '../Redux/Web3Airdrop/web3Airdrop';

// UTILITIES
import { checkActive, errorToast, successToast } from '../utils';

const CreatedAirdrops = ({ createdAirdrops }) => {
  const isConnected = useSelector((state) => state.Web3Auth?.isConnected);
  const chain = useSelector((state) => state.Web3Auth?.chainID);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDistribute = (airdropID) => {
    navigate(`/distribute/${airdropID}`);
  };

  const handleClaim = (
    startDate, endDate, airdropID, airdropIndexID, airdropCA, chainID, networkName,
  ) => {
    if (!checkActive(startDate, endDate)) {
      return errorToast('Dapp Airdrop still Runing');
    }
    if (!isConnected) {
      return errorToast('Please connect your wallet to claim');
    }
    if (chain !== chainID) {
      return errorToast(`Please connect to ${networkName} network`);
    }

    dispatch(withdrawDappBalance({ airdropIndexID, airdropCA })).then((res) => {
      if (res.error) {
        errorToast(res?.payload);
      } else {
        successToast('Balance withdrawn successfully!!');
      }
    });
    return { };
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {createdAirdrops.map((airdrop) => (
        <CreatedCard
          key={airdrop.airdropID}
          airdrop={airdrop}
          handleClaim={handleClaim}
          handleDistribute={handleDistribute}
        />
      ))}
    </div>
  );
};

CreatedAirdrops.propTypes = {
  createdAirdrops: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default CreatedAirdrops;
