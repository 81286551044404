import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GrTooltip } from 'react-icons/gr';

// UTILITIES
import { handleCopy, successToast } from '../utils';

const InfoPopup = ({ airdropLink, setInfoActive }) => {
  const navigate = useNavigate();
  const URL = window.location.origin;

  return (
    <section className="w-full animated fadeInUp h-full z-40 fixed bg-mainBg bg-opacity-80 top-0 bottom-0 left-0 flex justify-center items-center">
      <div className="bg-subtleBg relative border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col text-content items-center shadow-md shadow-accent-1">
        <h4 className=" font-extrabold text-xl md:text-3xl mb-10 mt-5 text-center">
          Airdrop Created Successfully
        </h4>

        <div className=" my-4 flex justify-center items-center w-[70%] bg-mainBg border border-accent-1 rounded-lg p-2 shadow-sm shadow-accent-1">
          <span className="text-xs  font-light text-mainHeading">
            🚀 Congratulations on Creating Your Airdrop on JetPad!

          </span>
        </div>
        <div className=" flex justify-center items-center flex-col gap-3 text-xs md:text-base w-full md:w-[80%] lg:w-[70%]">
          <p className=" text-center text-lg text-subHeading">
            To ensure the integrity of your airdrop and verify participants,
            please follow these steps:
          </p>
          <ul className=" flex flex-col gap-2 text-subText">
            <li>
              <span className=" font-bold mr-2 text-subHeading">Step 1:</span>
              <span>
                Go to Telegram and Search for
                <span className="text-link"> @JetPad_verification_bot</span>
              </span>
            </li>
            <li>
              <span className=" font-bold mr-2 text-subHeading">Step 2:</span>
              <span>Add @JetPad_verification_bot to your Group and Channel </span>
            </li>
            <li>
              <span className=" font-bold mr-2 text-subHeading">Step 3:</span>
              <span>
                You only need to give the bot Default permissions (No need to make an admin)
              </span>
            </li>
            <li>
              <span className=" font-bold mr-2 text-subHeading">Step 4:</span>
              <span>
                You are all done and ready to go. Now The system can verify and
                confirm that a user has joined your group and channel before
                they can proceed with the airdrop
              </span>
            </li>
          </ul>
          <div className=" m-2 p-1 rounded-lg self-center w-[90%] md:w-[80%] flex gap-2 items-center border border-notice bg-notice">
            <GrTooltip
              className="h-[65px] w-[65px] text-mainHeading"
            />
            <span className="text-sm text-subText">
              Visit our
              <a
                href="https://docs.jetpad.finance/important/how-to-add-telegram-verification-bot"
                rel="noopener noreferrer"
                target="_blank"
                className="after:content-['_↗'] hover:text-link/80 cursor-pointer mx-1 text-lg  text-link my-3"
                title="documentation"
              >
                documentation
              </a>
              for a detailed guide on adding the verification bot to your group or channel.
            </span>
          </div>
        </div>
        <div className=" my-3 flex flex-col gap-1">
          <span className=" font-bold text-center">Your Airdrop Link</span>
          <button
            type="button"
            className="text-link font-light flex flex-col items-center"
            onClick={() => {
              if (handleCopy(`${URL}${airdropLink}`)) {
                successToast('Airdrop Link copied!');
              }
            }}
          >
            {`${URL}${airdropLink}`}
            <span className=" text-xs md:text-sm border border-mainHeading text-mainHeading hover:bg-mainHeading hover:text-subHeading  px-4 rounded-xl">Click to copy</span>
          </button>
        </div>
        <div className="w-full flex justify-center items-center">
          <button
            type="button"
            className="bg-mainHeading text-mainBg hover:bg-mainHeading/50 text-lg px-2 py-1 rounded-lg font-bold w-[80%] "
            onClick={() => {
              setInfoActive(false);
              navigate(airdropLink);
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </section>

  );
};

InfoPopup.propTypes = {
  airdropLink: PropTypes.string.isRequired,
  setInfoActive: PropTypes.func.isRequired,
};

export default InfoPopup;
