import React from 'react';
import PropTypes from 'prop-types';

// METERIAL UI
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const ClickToolTip = ({
  openState, setOpenState, title,
}) => (
  <>
    <Tooltip
      title={title}
      placement="top"
      TransitionComponent={Zoom}
      arrow
    >
      <span className=" self-center hidden lg:inline-block text-center mx-1 h-4 w-4 rounded-full bg-gray-400 text-white overflow-hidden">i</span>
    </Tooltip>
    <ClickAwayListener onClickAway={() => setOpenState(false)}>
      <div className="flex lg:hidden">
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setOpenState(false)}
          open={openState}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={title}
          placement="top"
          TransitionComponent={Zoom}
          arrow
        >
          <button
            type="button"
            onClick={() => setOpenState(true)}
            className=" self-center inline-block text-center mx-1 h-4 w-4 rounded-full bg-gray-400 text-white overflow-hidden"
          >
            i
          </button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  </>
);

ClickToolTip.propTypes = {
  openState: PropTypes.bool.isRequired,
  setOpenState: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ClickToolTip;
