import React from 'react';
import { Loader } from 'rsuite';

const LoadingPopUp = () => (
  <section className="flex w-full animated fadeInUp h-full z-50 fixed bg-subtleBg bg-opacity-80 top-0 bottom-0 left-0 flex-col justify-center items-center">
    <Loader size="sm" content="Updating..." speed="fast" />
    <span className=" text-3xl md:text-5xl text-mainHeading font-black">Please Wait...</span>

  </section>
);

export default LoadingPopUp;
