import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Loader } from 'rsuite';
import { IoCheckmarkDoneCircle } from 'react-icons/io5';

// ICONS
import { AiOutlineCloseCircle } from 'react-icons/ai';

// UTILITIES
import {
  endsWithBnb, endsWithEth, errorToast, isValidEthereumAddress, openInNewTab,
} from '../utils';
import { resolveWeb3Address, resolveWeb3DomainName } from '../Redux/Web3Auth/web3Auth';

const EditProfilePopup = ({
  setActive, name, id, userID, handleUpdate, title,
}) => {
  const [itemName, setItemName] = useState('');
  const [domainName, setDomainName] = useState(null);
  const [web3Address, setWeb3Address] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleWeb3Address = (address, chainId) => {
    setLoading(true);
    dispatch(resolveWeb3Address({ address, chainId: [chainId] })).then((res) => {
      if (res.error) {
        setLoading(false);
      } else {
        setDomainName(res.payload);
        setLoading(false);
      }
    });
  };

  const handleWeb3DomainName = (web3DomainName) => {
    setLoading(true);
    dispatch(resolveWeb3DomainName({ web3DomainName })).then((res) => {
      if (res.error) {
        setErrorMsg(`No Address found for ${itemName}. Check your spelling or enter ${title} Address`);
        errorToast(`No Address found for ${itemName}. Check your spelling or enter ${title} Address`);
        setLoading(false);
      } else {
        if (res.payload === null) {
          setErrorMsg(`No Address found for ${itemName?.toUpperCase()}. Check your spelling or enter ${title?.toUpperCase()} Address`);
          errorToast(`No Address found for ${itemName}. Check your spelling or enter ${title} Address`);
        } else {
          setWeb3Address(res.payload);
        }
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setDomainName(null);
    setWeb3Address(null);
    setErrorMsg(null);
    if (title === 'bnb') {
      if (isValidEthereumAddress(itemName)) {
        handleWeb3Address(itemName, 56);
      } else if (endsWithBnb(itemName)) {
        handleWeb3DomainName(itemName);
      }
    } else if (title === 'eth') {
      if (isValidEthereumAddress(itemName)) {
        handleWeb3Address(itemName, 1);
      } else if (endsWithEth(itemName)) {
        handleWeb3DomainName(itemName);
      }
    }
  }, [itemName]);

  return (
    <section className="w-full animated fadeInUp h-full z-40 fixed bg-subtleBg bg-opacity-80 left-0 top-0 bottom-0 flex justify-center items-center">
      <div className="bg-mainBg relative border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col text-content shadow-md shadow-accent-1">
        <AiOutlineCloseCircle className="absolute right-5 hover:text-red-500 text-xl" onClick={() => setActive(false)} />
        <h2 className=" font-extrabold leading-3 text-3xl mb-10 mt-5 text-center">Update Details</h2>
        <div className=" font-mono flex justify-center items-center flex-col gap-3 text-xs md:text-base">
          <h3>
            {`Change ${name}`}
          </h3>
          {id === 'telegramID' && (
            <span className="flex flex-col gap-1 text-center">
              <p className=" font-bold text-subText">This should be your Telegram ID and not user-name. It contains only numbers</p>
              <button
                type="button"
                id="telegramID"
                name="Telegram user ID"
                className="text-link px-2 text-xs rounded-lg hover:text-link/80 after:content-['_↗'] "
                onClick={() => openInNewTab(
                  'https://docs.jetpad.finance/important/how-to-get-your-telegram-user-id',
                )}
              >
                How to get your ID
              </button>
            </span>
          )}
          <input
            className="auth-input placeholder:text-xs border border-accent-1"
            type={id === 'telegramID' ? 'number' : 'text'}
            value={itemName}
            placeholder={(title === 'bnb' || title === 'eth') ? `Enter ${name} or .${title} Domain Name` : `Enter ${name}`}
            onChange={(e) => setItemName(e.target.value)}
          />
          {domainName && (
            <>
              <span className="flex gap-1 items-center text-sm flex-col">
                Found Domain Name for this address:
                <span className="text-[#F2C22A] text-xs md:text-sm flex">
                  {domainName}
                  <IoCheckmarkDoneCircle className="text-[#1EEFA4]" />
                </span>
              </span>
            </>
          )}
          {web3Address && (
            <>
              <span className="flex gap-1 items-center text-sm flex-col">
                Found address for this Domain Name:
                <span className="text-[#F2C22A] text-xs md:text-sm flex">
                  {web3Address}
                  <IoCheckmarkDoneCircle className="text-[#1EEFA4]" />
                </span>
              </span>
            </>
          )}
          {errorMsg && (
            <>
              <span className="flex gap-1 items-center text-sm text-red-500">
                {errorMsg}
              </span>
            </>
          )}
          <div className="">
            <button
              type="button"
              className="self-center bg-mainHeading text-white px-4 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center disabled:bg-mainHeading/50"
              onClick={() => handleUpdate(
                itemName, name, id, userID, title, web3Address, domainName,
              )}
              disabled={loading || errorMsg}
            >
              {loading ? <Loader size="xs" content="Please Wait..." speed="fast" className=" text-xs m-0 p-0" /> : 'UPDATE'}
            </button>
          </div>
        </div>
        {(title === 'bnb' || title === 'eth') && (
          <button
            type="button"
            className=" text-link hover:text-link/70 hover:underline self-end"
            onClick={() => navigate('/web3-name')}
          >
            {`Get .${title} name for your address. Eg. jetpad.${title}`}
          </button>
        )}
      </div>
    </section>
  );
};

EditProfilePopup.propTypes = {
  setActive: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default EditProfilePopup;
