import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IoMdCopy } from 'react-icons/io';

// Components
import { EditProfilePopup, LoadingPopUp, SetUserNamePopup } from '../components';

// Redux
import { logOutUser, updateUserProfile } from '../Redux/Auth/auth';

// Utils
import {
  errorToast, successToast, splitString, removeAtSymbol, openInNewTab, isValidEthereumAddress,
  handleCopy, endsWithBnbOrEth,
} from '../utils';

// ASSETS
import { profileAvatar } from '../assets/images';

const Profile = () => {
  const [active, setActive] = useState(false);
  const [popUpActive, setPopUpActive] = useState(false);
  const [UEditActive, setUEditActive] = useState(false);
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [title, seTitle] = useState('');

  const user = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    email, emailVerified, joinedDate, userID, userName, refCount, points,
  } = user;

  const URL = window.location.origin;
  const refLink = `${URL}/signup?ref=${userName}`;

  const handleClick = (e) => {
    setName(e.target.name);
    setId(e.target.id);
    seTitle(e.target.title);
    setActive(true);
  };

  const handleChangePassword = () => {
    dispatch(logOutUser()).then((res) => {
      if (res.error) {
        errorToast(res.payload);
      } else {
        successToast('Signed-out, Change your password');
        navigate('/reset-pass');
      }
    });
  };

  // const handleNameOrAddressResolve = () => {

  // };

  const handleUpdate = (itemName, name, id, userID, title, web3Address, domainName) => {
    let data;
    if (name === 'Polygon Address') {
      if (!isValidEthereumAddress(itemName)) {
        return errorToast(`Enter a valid ${name}`);
      }
    } else if (name === 'Ethereum Address' || name === 'BSC Address') {
      if (!endsWithBnbOrEth(itemName) && !isValidEthereumAddress(itemName)) {
        return errorToast(`Enter a valid ${name}`);
      }
      if (endsWithBnbOrEth(itemName) && !isValidEthereumAddress(web3Address)) {
        return errorToast(`Check your spelling or enter ${title} Address`);
      }
      if (isValidEthereumAddress(itemName) && domainName) {
        const web3DKey = `${title}DomainName`;
        data = {
          [id]: itemName, userID, [web3DKey]: domainName,
        };
      }
      if (endsWithBnbOrEth(itemName) && isValidEthereumAddress(web3Address)) {
        const web3DKey = `${title}DomainName`;
        data = {
          [id]: web3Address, userID, [web3DKey]: itemName,
        };
      }
    }

    if (id === 'telegramID' && itemName.length < 4) {
      return errorToast('Please enter a valid telegram ID');
    }
    setPopUpActive(true);
    if (!data) {
      data = { [id]: removeAtSymbol(itemName), userID };
    }
    dispatch(updateUserProfile(data)).then((res) => {
      if (res.error) {
        errorToast(res.payload);
      } else {
        successToast(`${name} updated successfully`);
        setActive(false);
        // setItemName('');
        setPopUpActive(false);
      }
    });

    return {};
  };

  const refText = `🚀 Join the JetPad community
  AI-Powered Airdrop Launchpad.
  💡Project owners: Leverage #JetPad and its community to launch your airdrop
  and grow your project.
  🌐Crypto enthusiasts: Join and get airdrop tokens from verified projects
  in 3 simple clicks.
  ${userName ? refLink : `${URL}/signup`}
  #JetPad #Airdrop
  `;

  return (
    <>
      <Helmet>
        <title>JetPad - Profile</title>
        <meta
          name="description"
          content="My Profile"
        />
        <meta property="og:title" content="JetPad - Profile" />
        <meta
          property="og:description"
          content="My Profile"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      <section className="flex justify-center bg-mainBg text-content items-center">
        <div className=" my-1 mx-1 md:my-8 md:mx-8 w-full flex flex-col gap-10 justify-center items-center">
          <div className="bg-gradient-to-r from-accent-1 via-accent-2/60 to-accent-1 border border-accent-1 rounded-lg w-[95%] md:w-[70%] flex flex-col ">
            <div className="grid grid-cols-2 text-xs md:text-base px-4 md:px-6 py-6">
              <div className="flex flex-col w-full justify-start items-start gap-4">
                <div className="flex gap-2">
                  <img src={profileAvatar} alt="profile" className="h-[54px] w-[54px] rounded-full" />
                  <div className="flex flex-col gap-1">
                    <span className=" font-semibold text-sm md:text-lg font-mono">Good Day,</span>
                    <span className=" font-bold text-subHeading ">
                      {userName ? `@${userName}` : (
                        <button
                          type="button"
                          className="font-mono text-xs md:text-sm border border-mainHeading text-mainHeading hover:bg-mainHeading hover:text-subHeading  px-2 py-1 rounded-xl"
                          onClick={() => setUEditActive(true)}
                        >
                          Set User-name
                        </button>
                      )}
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  className="font-mono text-link border border-transparent hover:border-link px-2 py-1 rounded-xl"
                  disabled
                >
                  Change Avatar
                </button>
              </div>
              <div className="flex flex-col gap-3 justify-center items-end w-full">
                <button
                  type="button"
                  className="bg-mainHeading text-white hover:bg-mainHeading/50 py-1 px-2 rounded-md text-xs md:text-sm w-[60%] md:w-[40%]"
                  onClick={() => navigate('/my-airdrops')}
                >
                  My Airdrops
                </button>
                <button
                  type="button"
                  className="bg-mainHeading text-white hover:bg-mainHeading/50 py-1 px-2 rounded-md text-xs md:text-sm  w-[60%] md:w-[40%]"
                  onClick={() => navigate('')}
                >
                  Premium
                </button>
              </div>
            </div>
            <div className="bg-subtleBg border-t border-accent-1 rounded-t-2xl px-4 md:px-6 py-2">
              <div className="grid grid-cols-2 gap-3 mb-3">
                <div className="grid grid-cols-2 gap-1 border-r border-[#0056CC] pr-2">
                  <div className="flex flex-col justify-start items-center gap-3">
                    <span className=" font-bold text-sm md:text-lg lg:text-xl mt-2 text-center">Ref. Count</span>
                    <h4 className=" font-semibold text-sm md:text-base text-mainHeading text-center">
                      🚀
                      {refCount || 0}
                    </h4>
                  </div>
                  <div className="flex flex-col justify-start items-center gap-3">
                    <span className="  font-bold text-sm md:text-lg lg:text-xl mt-2 text-center">Point Value</span>
                    <h4 className=" font-semibold text-sm md:text-base text-mainHeading text-center">
                      {points || 0}
                    </h4>
                  </div>
                </div>
                <div className="flex flex-col justify-start w-full">
                  <div className=" mt-2 flex justify-center items-center  bg-green-300 border border-green-700 rounded-lg p-2">
                    <span className="text-xs font-semibold text-black">
                      ✨ Accumulate 1.5 points per successful referral on JetPad
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className=" flex flex-col justify-center items-center bg-mainBg border border-accent-1 shadow-md shadow-accent-1 text-subText rounded-lg p-2 pt-0">
                  <h5 className="text-subHeading font-extrabold underline"> Project owners</h5>
                  <span className="text-xs font-semibold text-center">
                    🌐 Redeem points for a percentage discount on airdrop creation fees.
                  </span>
                  <h5 className="text-subHeading font-extrabold underline mt-2">JetSetters</h5>
                  <span className="text-xs font-semibold text-center">
                    💎 Unlock a FREE 1-month Premium membership at a specific referral milestone.
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center mt-4 gap-2">
                <span className=" font-bold text-sm md:text-lg lg:text-xl text-center underline">Referral Text</span>
                <div className=" flex justify-center items-center  bg-mainBg rounded-lg p-2 border border-accent-1">
                  <span className="text-xs text-subText font-semibold">
                    🚀 Join the JetPad community
                    <br />
                    AI-Powered Airdrop Launchpad
                    <br />
                    <br />
                    💡Project owners: Leverage #JetPad and its community to launch your airdrop
                    and grow your project.
                    <br />
                    🌐Crypto enthusiasts: Join and get airdrop tokens from verified projects
                    in 3 simple clicks.
                    <br />
                    <br />
                    {userName ? refLink : `${URL}/signup`}
                    <br />
                    <br />
                    #JetPad #Airdrop
                  </span>
                </div>
                {userName ? (
                  <button
                    type="button"
                    className="text-xs border border-mainHeading text-mainHeading px-2 py-1 rounded-lg  hover:bg-mainHeading hover:text-content  w-[40%] md:w-[20%]"
                    onClick={() => {
                      if (handleCopy(refText)) {
                        successToast('Referral Text copied!');
                      }
                    }}
                  >
                    Copy Text
                  </button>
                ) : (
                  <span className="flex flex-col items-center gap-2">
                    <div className=" mt-2 flex justify-center items-center border border-notice bg-notice text-subText rounded-lg p-2">
                      <span className="text-xs  font-semibold">
                        You need to set your username to get a valid referral link
                      </span>
                    </div>

                    <button
                      type="button"
                      className="bg-mainHeading text-mainBg hover:bg-mainHeading/50 text-lg px-2 py-1 rounded-lg"
                      onClick={() => setUEditActive(true)}
                    >
                      Set User-name
                    </button>
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* User Data section */}
          <div className="bg-subtleBg border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col text-content">
            <h2 className=" font-extrabold leading-3 text-3xl mb-10 mt-5 text-center">Account Details</h2>
            <div className=" col-span-2 font-mono flex flex-col gap-3">
              <div className="grid grid-cols-3 md:grid-cols-4 border border-accent-1 rounded-lg">
                <h4 className="bg-accent-1 border-r border-accent-1 pl-2 py-2 rounded-lg bg-opacity-[20%]">Email</h4>
                <h4 className="text-subText py-2 pl-2 rounded-lg bg-opacity-5 col-span-2">
                  {email !== undefined ? splitString(email, 5, (email?.length - 11)) : 'loading...'}
                </h4>
                <span className=" text-green-500 italic text-sm py-2">
                  {emailVerified && 'Verified'}
                </span>
              </div>
              <div className="grid grid-cols-3 md:grid-cols-4 border border-accent-1 rounded-lg">
                <h4 className="bg-accent-1 border-r border-accent-1 py-2 pl-2 rounded-lg bg-opacity-[20%]">User-ID</h4>
                <h4 className=" py-2 pl-2 rounded-lg text-subText col-span-2 md:col-span-3">
                  {userID !== undefined ? splitString(userID, 7, 19) : 'loading...'}
                </h4>
              </div>
              <div className="grid grid-cols-3 md:grid-cols-4 border border-accent-1 rounded-lg">
                <h4 className="bg-accent-1 border-r border-accent-1 py-2 pl-2 rounded-lg bg-opacity-[20%]">password</h4>
                <button
                  type="button"
                  className="text-link py-2 pl-2 rounded-lg col-span-2 md:col-span-3 hover:text-link/80"
                  onClick={handleChangePassword}
                >
                  Change Password
                </button>
              </div>
              <div className="grid grid-cols-3 md:grid-cols-4 border border-accent-1 rounded-lg">
                <h4 className="bg-accent-1 border-r border-accent-1 py-2 pl-2 rounded-lg bg-opacity-[20%]">Joined Date</h4>
                <h4 className=" py-2 pl-2 rounded-lg text-subText col-span-2 md:col-span-3">
                  {joinedDate}
                </h4>
              </div>
            </div>
          </div>
          {/* Social Media Section */}
          <div className="bg-subtleBg border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col text-content">
            <h2 className=" font-extrabold leading-3 text-3xl mb-10 mt-5 text-center">Social  Details</h2>
            <div className=" font-mono flex flex-col gap-3 text-xs md:text-base">

              <div className="grid grid-cols-3 md:grid-cols-4 border border-accent-1 rounded-lg">
                <span className="bg-accent-1 border-r border-accent-1 pl-2 py-2 rounded-lg bg-opacity-[20%]">Twitter Username</span>
                <p className="text-subText py-2 pl-2 rounded-lg col-span-2">
                  {user?.twitterUsername ? user.twitterUsername : 'No User-name'}
                </p>
                <button
                  type="button"
                  id="twitterUsername"
                  name="Twitter Username"
                  className="text-link py-2 pl-2 rounded-lg hover:text-link/80 col-span-3 md:col-span-1"
                  onClick={handleClick}
                >
                  {user?.twitterUsername ? 'Change Username' : 'Add User-name'}
                </button>
              </div>
              <div className="grid grid-cols-3 md:grid-cols-4 border border-accent-1 rounded-lg">
                <span className="bg-accent-1 border-r border-accent-1 pl-2 py-2 rounded-lg bg-opacity-[20%]">Telegram UserID</span>
                <p className="text-subText py-2 pl-2 rounded-lg col-span-2">
                  {user?.telegramID ? user.telegramID : 'No User-ID'}
                </p>
                <div className="col-span-3 md:col-span-1 flex flex-row md:flex-col items-center gap-1 my-1 justify-center">
                  <button
                    type="button"
                    id="telegramID"
                    name="Telegram user ID"
                    className="text-link px-2  rounded-lg hover:text-link/80 after:content-['||'] after:text-subText"
                    onClick={handleClick}
                  >
                    {user?.telegramID ? 'Change ID ' : 'Add ID'}
                  </button>
                  <button
                    type="button"
                    id="telegramID"
                    name="Telegram user ID"
                    className="text-[#ff0800] px-2 text-xs rounded-lg hover:text-link after:content-['_↗'] underline"
                    onClick={() => openInNewTab(
                      'https://docs.jetpad.finance/important/how-to-get-your-telegram-user-id',
                    )}
                  >
                    How to get your ID
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-3 md:grid-cols-4 border border-accent-1 rounded-lg">
                <span className="bg-accent-1 border-r border-accent-1 pl-2 py-2 rounded-lg bg-opacity-[20%]">Discord Username</span>
                <p className="text-subText py-2 pl-2 rounded-lg col-span-2">
                  {user?.discordUsername ? user.discordUsername : 'No User-name'}
                </p>
                <button
                  type="button"
                  id="discordUsername"
                  name="Discord Username"
                  className="text-link py-2 pl-2 rounded-lg hover:text-link/80 col-span-3 md:col-span-1"
                  onClick={handleClick}
                >
                  {user?.discordUsername ? 'Change Username' : 'Add User-name'}
                </button>
              </div>
            </div>
          </div>
          {/* Account Wallet Section */}
          <div className="bg-subtleBg border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col text-content">
            <h2 className=" font-extrabold leading-3 text-3xl mb-10 mt-5 text-center">Account Wallet</h2>
            <div className=" font-mono flex flex-col gap-3 text-xs md:text-base">

              <div className="grid grid-cols-3 md:grid-cols-4 border border-accent-1 rounded-lg">
                <span className="bg-accent-1 border-r border-accent-1 pl-2 py-2 rounded-lg bg-opacity-[20%]">BSC Chain</span>
                <p className="text-subText py-2 pl-2 rounded-lg col-span-2 flex gap-1 items-center">
                  {user?.BSCAddress ? (user?.bnbDomainName || splitString(user.BSCAddress, 7, 35)) : 'No Address' }
                  {user?.BSCAddress && (
                    <button
                      type="button"
                      onClick={() => {
                        handleCopy(user?.BSCAddress);
                        successToast('BNB Address copied!');
                      }}
                    >
                      <IoMdCopy />
                    </button>
                  )}
                </p>
                <button
                  type="button"
                  id="BSCAddress"
                  name="BSC Address"
                  title="bnb"
                  className="text-link py-2 pl-2 rounded-lg hover:text-link/80 col-span-2 md:col-span-1"
                  onClick={handleClick}
                >
                  {user?.BSCAddress ? 'Change Address' : 'Add Address' }
                </button>
              </div>
              <div className="grid grid-cols-3 md:grid-cols-4 border border-accent-1 rounded-lg">
                <span className="bg-accent-1 border-r border-accent-1 pl-2 py-2 rounded-lg bg-opacity-[20%]">Ethereum</span>
                <p className="text-subText py-2 pl-2 rounded-lg col-span-2 flex gap-1 items-center">
                  {user?.ethereumAddress ? (user?.ethDomainName || splitString(user.ethereumAddress, 7, 35)) : 'No Address' }
                  {user?.BSCAddress && (
                    <button
                      type="button"
                      className="hover:text-subText"
                      onClick={() => {
                        handleCopy(user?.ethereumAddress);
                        successToast('ETH Address copied!');
                      }}
                    >
                      <IoMdCopy />
                    </button>
                  )}
                </p>
                <button
                  type="button"
                  id="ethereumAddress"
                  name="Ethereum Address"
                  title="eth"
                  className="text-link py-2 pl-2 rounded-lg hover:text-link/80 col-span-2 md:col-span-1"
                  onClick={handleClick}
                >
                  {user?.ethereumAddress ? 'Change Address' : 'Add Address' }
                </button>
              </div>
              <div className="grid grid-cols-3 md:grid-cols-4 border border-accent-1 rounded-lg">
                <span className="bg-accent-1 border-r border-accent-1 pl-2 py-2 rounded-lg bg-opacity-[20%]">Polygon</span>
                <p className="text-subText py-2 pl-2 rounded-lg col-span-2 flex gap-1 items-center">
                  {user?.polygonAddress ? splitString(user.polygonAddress, 7, 35) : 'No Address' }
                  {user?.polygonAddress && (
                    <button
                      type="button"
                      className="hover:text-subText"
                      onClick={() => {
                        handleCopy(user?.polygonAddress);
                        successToast('Polygon Address copied!');
                      }}
                    >
                      <IoMdCopy />
                    </button>
                  )}
                </p>
                <button
                  type="button"
                  id="polygonAddress"
                  name="Polygon Address"
                  title="matic"
                  className="text-link py-2 pl-2 rounded-lg hover:text-link/80 col-span-2 md:col-span-1"
                  onClick={handleClick}
                >
                  {user?.polygonAddress ? 'Change Address' : 'Add Address' }
                </button>
              </div>
            </div>
          </div>
        </div>
        {active
          && (
          <EditProfilePopup
            setActive={setActive}
            name={name}
            id={id}
            title={title}
            userID={userID}
            handleUpdate={handleUpdate}
          />
          )}
        {UEditActive
          && (
          <SetUserNamePopup
            setUEditActive={setUEditActive}
            userID={userID}
            email={email}
          />
          )}
      </section>
      {popUpActive && <LoadingPopUp />}
    </>
  );
};
export default Profile;
