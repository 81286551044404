/* eslint-disable */
// REACT ICONS
import {
  FcMindMap, FcMultipleInputs,
  FcWorkflow, FcAcceptDatabase, FcConferenceCall, FcGenealogy, FcOrgUnit,
} from 'react-icons/fc';
import {
  IoHomeOutline, IoRocketOutline, IoBriefcaseOutline, IoDocumentTextOutline,
} from 'react-icons/io5';
import { BsSendPlus } from 'react-icons/bs';
import { LuCandlestickChart } from 'react-icons/lu';
import { FaXTwitter } from 'react-icons/fa6';
import { FaTelegramPlane } from 'react-icons/fa';
import { RxAvatar } from 'react-icons/rx';

// Assets
import {
  ETHLogo, BSCLogo, POLYGONLogo, AVALANCHELogo, TRONLogo,
  ARBITRUMLogo, OPBNBLogo, brandMark, spaceId,
} from './assets/images';

export const supportedNetworks = {
  ethereum: 'eth',
  'bnb chain': 'bsc',
  'binance smart chain': 'bsc',
  'polygon pos': 'polygon_pos',
  avalanche: 'avax',
  moonriver: 'movr',
  cronos: 'cro',
  harmony: 'one',
  'boba network': 'boba',
  fantom: 'ftm',
  smartbch: 'bch',
  aurora: 'aurora',
  metis: 'metis',
  arbitrum: 'arbitrum',
  'arbitrum one': 'arbitrum',
  fuse: 'fuse',
  okexchain: 'okexchain',
  'kucoin community chain': 'kcc',
  iotex: 'iotx',
  celo: 'celo',
  'gnosis xdai': 'xdai',
  'huobi eco chain': 'heco',
  moonbeam: 'glmr',
  optimism: 'optimism',
  energi: 'nrg',
  wanchain: 'wan',
  ronin: 'ronin',
  kardiachain: 'kai',
  meter: 'mtr',
  velas: 'velas',
  shiden: 'sdn',
  telos: 'tlos',
  syscoin: 'sys',
  'terra classic': 'terra',
  'oasis emerald': 'oasis',
  astar: 'astr',
  elastos: 'ela',
  'milkomeda cardano': 'milkada',
  'dfk chain': 'dfk',
  evmos: 'evmos',
  solana: 'solana',
  conflux: 'cfx',
  bitorrent: 'bttc',
  'sx network': 'sxn',
  xdc: 'xdc',
  'cube network': 'cube_network',
  klaytn: 'klaytn',
  kava: 'kava',
  bitgert: 'bitgert',
  tombchain: 'tombchain',
  dogechain: 'dogechain',
  findora: 'findora',
  thundercore: 'thundercore',
  'clover finance': 'clover-finance',
  'arbitrum nova': 'arbitrum_nova',
  canto: 'canto',
  'ethereum classic': 'ethereum_classic',
  'step network': 'step-network',
  ethereumpow: 'ethw',
  ethereumfair: 'ethereumfair',
  godwoken: 'godwoken',
  songbird: 'songbird',
  'redlight chain': 'redlight_chain',
  kekchain: 'kekchain',
  tomochain: 'tomochain',
  'function x': 'fx',
  'platon network': 'platon_network',
  exosama: 'exosama',
  oasys: 'oasys',
  'bitkub chain': 'bitkub_chain',
  'empire network': 'empire_network',
  alvey: 'alvey',
  wemix: 'wemix',
  flare: 'flare',
  onus: 'onus',
  aptos: 'aptos',
  core: 'core',
  'goerli testnet': 'goerli-testnet',
  filecoin: 'filecoin',
  'lung chain': 'lung-chain',
  zksync: 'zksync',
  poochain: 'poochain',
  'loop network': 'loop-network',
  'bitindi chain': 'bitindi-chain',
  multivac: 'multiVAC',
  'polygon zkevm': 'polygon-zkevm',
  'beaneco smartchain': 'beaneco-smartchain',
  'eos evm': 'eos-evm',
  apex: 'apex',
  callisto: 'callisto',
  ultron: 'ultron',
  'nova network': 'nova-network',
  'sui network': 'sui-network',
  pulsechain: 'pulsechain',
  'trustless computer': 'trustless-computer',
  enuls: 'enuls',
  tenet: 'tenet',
  rollux: 'rollux',
  'starknet alpha': 'starknet-alpha',
  mantle: 'mantle',
  'neon evm': 'neon-evm',
  linea: 'linea',
};

export const supportedChain = [
  {
    name: 'BNB Chain',
    logo: BSCLogo,
    text: '',
    bgColor: 'bg-[#333333]',
    textColor: 'text-[#f0b92a]',
  },
  {
    name: 'Ethereum',
    logo: ETHLogo,
    text: '',
    bgColor: 'bg-[#627ad8]',
    textColor: 'text-[#262626]',
  },
  {
    name: 'Tron',
    logo: TRONLogo,
    text: 'Coming Soon',
    bgColor: 'bg-[#262626]',
    textColor: 'text-[#f71c1f]',
  },
  {
    name: 'Polygon zkEVM',
    logo: POLYGONLogo,
    text: '',
    bgColor: 'bg-[#ad73de]',
    textColor: 'text-[#262626]',
  },
  {
    name: 'Avalanche',
    logo: AVALANCHELogo,
    text: 'Coming Soon',
    bgColor: 'bg-[#e84142]',
    textColor: 'text-[#262626]',
  },
  {
    name: 'Aribitrum One',
    logo: ARBITRUMLogo,
    text: 'Coming Soon',
    bgColor: 'bg-[#213147]',
    textColor: 'text-[#3b91b8]',
  },
  {
    name: 'opBNB Chain',
    logo: OPBNBLogo,
    text: 'Coming Soon',
    bgColor: 'bg-[#edbe2b]',
    textColor: 'text-[#fffff]',
  },
];

export const dAppEngagements = [
  {
    title: 'Effortless DApp Building - With just texts',
    text: 'No complexities and technical hurdles, launch your airdrop DApp with our ready made templates - No Coding! ',
    icon: FcMindMap,
    bgColor: 'bg-[#ce3489]',
  },
  {
    title: 'Deploy your DApp to the blockchain instantly',
    text: 'Once Your Airdrop DApp is live, Users can effortlessly claim and interact with your token, enhancing engagement and adoption.',
    icon: FcWorkflow,
    bgColor: 'bg-[#ffc248]',
  },
  {
    title: 'Fully Functional Referral feature',
    text: 'Each user get a unique referral link - users get pre-set referral reward for each successful claim of their referee',
    icon: FcMultipleInputs,
    bgColor: 'bg-[#66c3a1]',
  },
];

export const projectOwnersTestimonials = [
  {
    avatar: brandMark,
    name: 'GVS',
    role: 'Project Lead',
    text: 'JetPad transformed our airdrop strategy! The high-quality platform and the visionary founder ensured a successful campaign. Kudos to the team for delivering excellence in airdrop management',
    date: 'Jan 2, 2024',
    rating: 5,
  },
  {
    avatar: brandMark,
    name: 'QuakkaSwapp',
    role: 'Project Manager',
    text: 'JetPad made our airdrop creation seamless and efficient. The platform\'s innovative approach and the dedication of its founder ensured a smooth experience. Highly recommend it to fellow projects!',
    date: 'Dec 20, 2023',
    rating: 4.5,
  },
  {
    avatar: brandMark,
    name: 'Black Jesus',
    role: 'Blockchain Developer',
    text: 'Our airdrop journey with JetPad was phenomenal! The platform\'s constant development and exceptional quality made our project stand out. A trustworthy founder and a fantastic tool – JetPad truly delivered on its promise',
    date: 'Dec 31, 2023',
    rating: 3,
  },
  {
    avatar: brandMark,
    name: 'CryptoInnov8',
    role: 'Founder & CEO',
    text: 'JetPad has been a game-changer for our project. The platform\'s efficiency in airdrop management is unmatched. Kudos to the visionary team behind JetPad!',
    date: 'Jan 5, 2024',
    rating: 5,
  },
  {
    avatar: brandMark,
    name: 'TechTokens',
    role: 'Project Manager',
    text: 'They simplified our airdrop creation process. The user-friendly platform and prompt support made our campaign a success. Highly recommended for project owners!',
    date: 'Jan 7, 2024',
    rating: 4,
  },
  {
    avatar: brandMark,
    name: 'InnoChain',
    role: 'Lead Developer',
    text: 'Choosing JetPad for our airdrop was a smart move. The platform\'s innovation and attention to detail set it apart. A reliable solution for project owners.',
    date: 'Jan 10, 2024',
    rating: 5,
  },
  {
    avatar: brandMark,
    name: 'DigitalVibes',
    role: 'Co-Founder',
    text: 'JetPad exceeded our expectations. The platform\'s features and seamless interface made airdrop management effortless. A must-have tool for project success!',
    date: 'Jan 15, 2024',
    rating: 3.5,
  },
  {
    avatar: brandMark,
    name: 'BlockPioneers',
    role: 'Blockchain Strategist',
    text: 'JetPad\'s airdrop tools are top-notch. The platform\'s versatility and ease of use contributed significantly to the success of our campaign. Highly satisfied!',
    date: 'Jan 20, 2024',
    rating: 4.5,
  },
  {
    avatar: brandMark,
    name: 'CryptoVisionaries',
    role: 'Co-Founder',
    text: 'This is a must for any project. The platform\'s reliability, coupled with excellent support, made our airdrop journey a breeze. A highly recommended solution!',
    date: 'Jan 25, 2024',
    rating: 5,
  },
];

export const airdropersTestimonials = [
  {
    avatar: brandMark,
    name: 'Emma L.',
    role: 'Avid Airdrop Hunter',
    text: 'JetPad has simplified the airdrop process for hunters. Creating an account and participating in multiple airdrops has never been this easy. A must-try for every airdrop enthusiast!',
    date: 'Jan 20, 2024',
    rating: 5,
  },
  {
    avatar: brandMark,
    name: 'David M.',
    role: 'Crypto Enthusiast',
    text: 'JetPad is my go-to platform for airdrops! Creating an account was easy, and the airdrop selection is fantastic. Joined multiple drops effortlessly and received tokens on the distribution date. Highly recommended!',
    date: 'Jan 10, 2024',
    rating: 5,
  },
  {
    avatar: brandMark,
    name: 'Sophie R.',
    role: 'Airdrop Hunter',
    text: 'This is a game-changer for airdrop hunters like me! Creating an account and joining airdrops became a breeze. Received tokens promptly – an excellent platform for crypto enthusiasts',
    date: 'Dec 31, 2023',
    rating: 5,
  },
  {
    avatar: brandMark,
    name: 'CryptoExplorer21',
    role: 'Airdrop Enthusiast',
    text: 'JetPad is a heaven for airdrop hunters! The platform\'s simplicity and extensive airdrop list make it my go-to. Hassle-free airdrop participation and quick token rewards!',
    date: 'Jan 3, 2024',
    rating: 4.5,
  },
  {
    avatar: brandMark,
    name: 'BlockchainSeeker',
    role: 'Crypto Enthusiast',
    text: 'JetPad is a gem in the crypto space! Joining airdrops is effortless, and the user-friendly interface is a delight. Thanks for making airdrop hunting a rewarding experience!',
    date: 'Jan 8, 2024',
    rating: 3.5,
  },
  {
    avatar: brandMark,
    name: 'TokenHarvester',
    role: 'Airdrop Explorer',
    text: 'JetPad is a revolution for airdrop enthusiasts! Discovering and participating in airdrops is now hassle-free. Great platform, quick rewards, and a fantastic community!',
    date: 'Jan 12, 2024',
    rating: 4.5,
  },
  {
    avatar: brandMark,
    name: 'CryptoAdventurer',
    role: 'Airdrop Seeker',
    text: 'The platform has transformed my airdrop journey! The platform\'s intuitive design and vast airdrop opportunities are unparalleled. A must-try for every crypto enthusiast!',
    date: 'Jan 18, 2024',
    rating: 3.5,
  },
  {
    avatar: brandMark,
    name: 'DigitalNomadCrypto',
    role: 'Airdrop Explorer',
    text: 'JetPad is a crypto enthusiast\'s dream come true! The platform\'s extensive airdrop options and quick rewards make it the ultimate choice. Highly recommend!',
    date: 'Jan 02, 2024',
    rating: 4,
  },
  {
    avatar: brandMark,
    name: 'CoinChaser',
    role: 'Crypto Advocate',
    text: 'JetPad has made airdrop hunting enjoyable! Joining airdrops is straightforward, and the rewards are swift. An excellent platform for crypto enthusiasts seeking opportunities!',
    date: 'Jan 28, 2024',
    rating: 5,
  },
];

export const socialEngagements = [
  {
    title: 'Launch Airdrop - with few simple clicks',
    text: 'Enter your token and project details and create your airdrop instantly.',
    icon: FcAcceptDatabase,
    bgColor: 'bg-[#5969fb]',
  },
  {
    title: 'Manage your airdrop from our intuitive dashboard',
    text: 'Once Your Airdrop is live, visit your dashboard to see how your airdop is performing.',
    icon: FcOrgUnit,
    bgColor: 'bg-[#ce3489]',
  },
  {
    title: 'See all eligibe participants',
    text: 'Get Detailed data on remaining airdrop quantity, referrals, participants wallet addresses and location data.',
    icon: FcConferenceCall,
    bgColor: 'bg-[#7533df]',
  },
  {
    title: 'Get Airdrop Token to users wallets',
    text: 'Use Jetpad smart contract to distribute your airdrops to all eligible participants with just 2 clicks.',
    icon: FcGenealogy,
    bgColor: 'bg-[#ff5f85]',
  },
];

export const menus = [
  { title: 'Home', icon: IoHomeOutline, path: '/' },
  {
    title: 'Airdrop',
    icon: IoRocketOutline,
    child: [
      {
        title: 'Create Airdrop',
        path: '/create-airdrop',
      },
      {
        title: 'Airdrops',
        path: '/airdrops',
      },
      {
        title: 'My-Airdrops',
        path: '/my-airdrops',
      },
      {
        title: 'How To Create',
        path: 'https://docs.jetpad.finance/airdrops/create-social-airdrop',
        doc: true,
      },
    ],
    path: '',
  },
  {
    title: 'Portfolio', icon: IoBriefcaseOutline, path: '/portfolio',
  },
  { title: 'Profile ', icon: RxAvatar, path: '/profile' },
  { title: 'MultiSender', icon: BsSendPlus, path: '/multisender' },
  {
    title: 'Chart',
    icon: LuCandlestickChart,
    path: '/tokens/eth/0x14fee680690900ba0cccfc76ad70fd1b95d10e16',
  },
  {
    title: 'Web3-Name',
    icon: spaceId,
    path: '/web3-name',
  },
  {
    title: 'Twitter ',
    icon: FaXTwitter,
    gap: true,
    path: 'https://twitter.com/JetPad_Finance',
    social: true,
  },
  {
    title: 'Telegram',
    icon: FaTelegramPlane,
    path: 'https://t.me/JetPad_Finance',
    social: true,
  },
  {
    title: 'Docs ',
    icon: IoDocumentTextOutline,
    gap: true,
    path: 'https://docs.jetpad.finance/',
    doc: true,
  },
];

export const bottomNavMenus = [
  { title: 'Airdrops', src: IoRocketOutline, path: '/airdrops' },
  { title: 'Chart', src: LuCandlestickChart, path: '/tokens/eth/0x21bfbda47a0b4b5b1248c767ee49f7caa9b23697' },
  { title: 'Home', src: IoHomeOutline, path: '/' },
  { title: 'Portfolio', src: IoBriefcaseOutline, path: '/portfolio' },
];

export const chainParams = {
  '0x38': {
    chainId: '0x38',
    chainName: 'BNB Smart Chain Mainnet',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/']
  },
  '0x89': {
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
        name: 'Matic',
        symbol: 'MATIC',
        decimals: 18
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/']
  },
  '0x1': {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
    },
    rpcUrls: ['https://eth.public-rpc.com/'],
    blockExplorerUrls: ['https://etherscan.io/']
  },
};
