import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// UTILITIES
import { calcDays, openInNewTab } from '../utils';

// ASSETS

const FeaturedAirdropCard = ({ FA }) => {
  const navigate = useNavigate();

  return (
    <div className=" flex flex-col  justify-center items-center mx-6 p-4 md:w-[70%] lg:w-[60%] border border-accent-1 rounded-xl bg-mainBg text-content hover:bg-subtleBg/50">
      <div className="flex flex-col md:flex-row items-center mb-2 w-full justify-center md:gap-4">
        <h3 className="font-bold text-base md:text-2xl">Featured Airdrop</h3>
        <div className="flex flex-col justify-center items-center">
          <span className={calcDays(FA?.startDate, FA?.endDate)?.style}>
            {calcDays(FA?.startDate, FA?.endDate)?.text}
          </span>
          {FA?.distributed && (
            <span className="text-subText font-semibold">
              Distributed
              <span className="text-[#21BF96] text-lg pr-2">&#10003;</span>
            </span>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-3">
        <div className="flex flex-col justify-start items-center gap-1">
          <img src={FA?.logoLink} alt="Project Logo" className="h-[50px] w-[50px] rounded-full" />
          <h4 className="font-bold text-xl md:text-2xl lg:text-3xl">{FA?.projectName}</h4>
          <div className=" w-full flex flex-col md:flex-row gap-2 items-center justify-between">
            <button
              type="button"
              className=" cursor-pointer text-link border-b border-accent-2 hover:text-link/80 after:content-['_↗']"
              onClick={() => openInNewTab(FA?.telegramGroup)}
            >
              Telegram
            </button>
            <button
              type="button"
              className=" cursor-pointer text-link border-b border-accent-2 hover:text-link/80 after:content-['_↗']"
              onClick={() => openInNewTab(FA?.twitterPageLink)}
            >
              Twitter
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-3 text-sm text-center justify-center font-normal text-subText">
          <span>
            {`$${((Number(FA?.estLaunchPrice) || 0) * Number(FA?.airdropAmount))?.toFixed(0)} worth of ${FA?.projectName}`}
          </span>
          <span className="text-content">Early participants gets special bonus</span>
        </div>
      </div>
      <div className="flex gap-8">
        <button
          type="button"
          className="border border-mainHeading text-mainHeading text-lg px-6 py-1 rounded-lg  hover:bg-mainHeading hover:text-mainBg font-semibold animate-heartbeat my-2"
          onClick={() => navigate(`/airdrop/${FA?.id}`)}
        >
          Join Now
        </button>
        {FA?.distributed && (
        <button
          type="button"
          className="bg-mainHeading text-content text-lg px-6 py-1 rounded-lg  hover:bg-mainHeading hover:text-mainBg font-semibold animate-heartbeat my-2"
          onClick={() => openInNewTab(FA?.explorerLink)}
        >
          View Txn
        </button>
        )}
      </div>
    </div>
  );
};

FeaturedAirdropCard.propTypes = {
  FA: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default FeaturedAirdropCard;
