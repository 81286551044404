import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// BASE URL
const CGCBASEURL = 'https://api.coingecko.com/api/v3/';
const GCTBASEURL = 'https://api.geckoterminal.com/api/v2/';
// const CMCBASEURL = 'https://pro-api.coinmarketcap.com/';
// const NODEBASEURL = process.env.REACT_APP_MORALIS_API_URL;

const initialState = {
  loading: false,
  trendingTokens: [],
  latestPools: [],
  tokenPrices: {
    bitcoin: {
      usd: 0,
    },
  },
  tokenDetails: {},
  dexTrades: [],
};

// Action-types
const FETCH_TRENDING = 'Web3Auth/web3Auth/FETCH_TRENDING';
const FETCH_LATEST = 'Web3Auth/web3Auth/FETCH_LATEST';
const GET_TOKEN_PRICE = 'Web3Auth/web3Auth/GET_TOKEN_PRICE';
const GET_TOKEN_BY_ID = 'Web3Auth/web3Auth/GET_TOKEN_BY_ID';
const GET_TOKEN_BY_ADDRESS = 'Web3Auth/web3Auth/GET_TOKEN_BY_ADDRESS';
const GET_OHLCV_DATA = 'Web3Auth/web3Auth/GET_OHLCV_DATA';
const SEARCH_TOKEN_BY_NAME = 'Web3Auth/web3Auth/SEARCH_TOKEN_BY_NAME';
const SEARCH_TOKEN_BY_ADDRESS = 'Web3Auth/web3Auth/SEARCH_TOKEN_BY_ADDRESS';
const GET_DEX_TRADES = 'Web3Auth/web3Auth/GET_DEX_TRADES';

// Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_TRENDING}/pending`:
      return { ...state, loading: true };
    case `${FETCH_TRENDING}/fulfilled`:
      return { ...state, trendingTokens: action.payload, loading: false };
    case `${FETCH_TRENDING}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${FETCH_LATEST}/pending`:
      return { ...state, loading: true };
    case `${FETCH_LATEST}/fulfilled`:
      return { ...state, latestPools: action.payload, loading: false };
    case `${FETCH_LATEST}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${GET_TOKEN_PRICE}/pending`:
      return { ...state, loading: true };
    case `${GET_TOKEN_PRICE}/fulfilled`:
      return {
        ...state, tokenPrices: { ...state.tokenPrices, ...action.payload }, loading: false,
      };
    case `${GET_TOKEN_PRICE}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${GET_TOKEN_BY_ID}/pending`:
      return { ...state, loading: true };
    case `${GET_TOKEN_BY_ID}/fulfilled`:
      return {
        ...state,
        tokenDetails: { ...state.tokenDetails, ...action.payload },
        loading: false,
      };
    case `${GET_TOKEN_BY_ID}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${GET_TOKEN_BY_ADDRESS}/pending`:
      return { ...state, loading: true };
    case `${GET_TOKEN_BY_ADDRESS}/fulfilled`:
      return {
        ...state,
        tokenDetails: { ...state.tokenDetails, ...action.payload },
        loading: false,
      };
    case `${GET_TOKEN_BY_ADDRESS}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${GET_DEX_TRADES}/pending`:
      return { ...state, loading: true };
    case `${GET_DEX_TRADES}/fulfilled`:
      return {
        ...state,
        dexTrades: action.payload,
        loading: false,
      };
    case `${GET_DEX_TRADES}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    default:
      return state;
  }
}

// Action Creators

// GET TRNDEING TOKENS
export const fetchTrendingTokens = createAsyncThunk(FETCH_TRENDING,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${CGCBASEURL}search/trending`);
      return response.data.coins;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// FETCH LATEST POOLS
export const fetchLatestPools = createAsyncThunk(FETCH_LATEST,
  async (data, { rejectWithValue }) => {
    const { network } = data;
    try {
      const response = await axios.get(`${GCTBASEURL}networks/${network}/new_pools`, {
        headers: {
          Accept: 'application/json;version=20230302',
        },
      });
      return response?.data?.data?.slice(0, 7);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// GET BTC PRICE IN USD
export const getTokenPrices = createAsyncThunk(GET_TOKEN_PRICE,
  async (data, { rejectWithValue }) => {
    const { ids } = data;
    try {
      const vsCurrencies = data?.vsCurrencies || 'usd';
      const response = await axios.get(`${CGCBASEURL}simple/price`, {
        params: {
          ids,
          vs_currencies: vsCurrencies,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// GET TOKEN BY ID
export const getTokenById = createAsyncThunk(GET_TOKEN_BY_ID,
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`
    ${CGCBASEURL}coins/${id}?localization=false&tickers=false&market_data=false&sparkline=false
    `);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// GET TOKEN BY NETWORK AND ADDRESS
export const getTokenByAddress = createAsyncThunk(GET_TOKEN_BY_ADDRESS,
  async (data, { rejectWithValue }) => {
    const { network, address } = data;
    try {
      const response = await axios.get(`${GCTBASEURL}networks/${network}/tokens/${address}`, {
        headers: {
          Accept: 'application/json;version=20230302',
        },
      });
      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// GET OHLCV DATA
export const getOhlcvData = createAsyncThunk(GET_OHLCV_DATA, async (data, { rejectWithValue }) => {
  const {
    network, poolAddress, timeframe, aggregate,
  } = data;
  try {
    const response = await axios.get(`
    ${GCTBASEURL}networks/${network}/pools/${poolAddress}/ohlcv/${timeframe}?aggregate=${aggregate}&limit=1000
    `, {
      headers: {
        Accept: 'application/json;version=20230302',
      },
    });

    return response.data?.data?.attributes?.ohlcv_list;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// SEARCH TOKEN BY NAME
export const searchTokenByName = createAsyncThunk(SEARCH_TOKEN_BY_NAME,
  async (searchQ, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${CGCBASEURL}search`, {
        params: {
          query: searchQ,
        },
      });
      return response?.data?.coins;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// SEARCH TOKEN BY NAME
export const searchTokenByAddress = createAsyncThunk(SEARCH_TOKEN_BY_ADDRESS,
  async (data, { rejectWithValue }) => {
    const { query, searchNetwork } = data;
    try {
      const response = await axios.get(`${GCTBASEURL}networks/${searchNetwork}/tokens/${query}`, {
        headers: {
          Accept: 'application/json;version=20230302',
        },
      });

      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  });

// GET DEX TRADES
export const getDexTrades = createAsyncThunk(GET_DEX_TRADES, async (data, { rejectWithValue }) => {
  const { poolAddress, network } = data;

  try {
    const response = await axios.get(`${GCTBASEURL}networks/${network}/pools/${poolAddress}/trades`, {
      headers: {
        Accept: 'application/json;version=20230302',
      },
    });

    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.message);
  }
});
