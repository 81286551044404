import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { createWeb3Name } from '@web3-name-sdk/core';
import SIDRegister from '@web3-name-sdk/register';
import { ethers } from '../../web3/ethers';

// INITIALISE
const web3name = createWeb3Name();

// BASE URL
const BASEURL = process.env.REACT_APP_MORALIS_API_URL;

const initialState = {
  loading: false,
  connectLoading: false,
  isConnected: false,
  tokens: [],
};

// Action-types
const CONNECT_WALLET = 'Web3Auth/web3Auth/CONNECT_WALLET';
const GET_TOKENS = 'Web3Auth/web3Auth/GET_TOKENS';
const SWITCH_NETWORK = 'Web3Auth/web3Auth/SWITCH_NETWORK';
const ADD_CHAIN = 'Web3Auth/web3Auth/ADD_CHAIN';
const RESOLVE_WEB3_ADDRESS = 'Web3Auth/web3Auth/RESOLVE_WEB3_ADDRESS';
const RESOLVE_WEB3_DOMAIN_NAME = 'Web3Auth/web3Auth/RESOLVE_WEB3_DOMAIN_NAME';
const CHECK_DOMAIN_AVAILABLE = 'Web3Auth/web3Auth/CHECK_DOMAIN_AVAILABLE';
const REGISTER_BNB_DOMAIN = 'Web3Auth/web3Auth/REGISTER_BNB_DOMAIN';
const REGISTER_ETH_DOMAIN = 'Web3Auth/web3Auth/REGISTER_ETH_DOMAIN';
const GET_WEB3_METADATA = 'Web3Auth/web3Auth/GET_WEB3_METADATA';

// Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${CONNECT_WALLET}/pending`:
      return { ...state, loading: true, connectLoading: true };
    case `${CONNECT_WALLET}/fulfilled`:
      return {
        ...state, isConnected: true, ...action.payload, loading: false, connectLoading: false,
      };
    case `${CONNECT_WALLET}/rejected`:
      return {
        ...state, loading: false, connectLoading: false, error: action.error.message,
      };
    case `${GET_TOKENS}/pending`:
      return { ...state, loading: true };
    case `${GET_TOKENS}/fulfilled`:
      return { ...state, tokens: action.payload, loading: false };
    case `${GET_TOKENS}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    default:
      return state;
  }
}

// Action Creators
// Connect user's wallet
export const connectUserWallet = createAsyncThunk(CONNECT_WALLET,
  async (_, { rejectWithValue }) => {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const wallets = await provider.send('eth_requestAccounts');
        const network = await provider.getNetwork();

        let balance = await provider.getBalance(wallets[0]);
        balance = ethers.utils.formatEther(balance);
        const web3DomainName = await web3name.getDomainName({
          address: wallets[0],
          queryChainIdList: [network.chainId],
        });
        const data = {
          chainID: network.chainId,
          networkName: network.name,
          address: wallets[0],
          web3DomainName,
          balance,
        };
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
    return rejectWithValue('Please Install Trust-wallet / MetaMask');
  });

// Get tokens
export const getAllUserTokens = createAsyncThunk(GET_TOKENS, async (data, { rejectWithValue }) => {
  const { chain, address } = data;
  try {
    const response = await axios.get(`${BASEURL}gettokens`, {
      params: { address, chain },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const switchNetwork = createAsyncThunk(SWITCH_NETWORK, async (data, { rejectWithValue }) => {
  const { chainIDHex } = data;

  if (window.ethereum) {
    const targetNetwork = { chainId: chainIDHex };

    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: targetNetwork.chainId }],
      });

      return 'Network switched successfully';
    } catch (error) {
      return rejectWithValue(error.message);
    }
  } else {
    return rejectWithValue('Ethereum provider not detected. Make sure MetaMask or another Web3 wallet is installed.');
  }
});

export const addChain = createAsyncThunk(ADD_CHAIN, async (params, { rejectWithValue }) => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [params],
      });

      return 'New Chain Added successfully';
    } catch (error) {
      return rejectWithValue(error.message);
    }
  } else {
    return rejectWithValue('Ethereum provider not detected. Make sure MetaMask or another Web3 wallet is installed.');
  }
});

export const resolveWeb3Address = createAsyncThunk(RESOLVE_WEB3_ADDRESS,
  async (data, { rejectWithValue }) => {
    const { address, chainId } = data;
    try {
      const name = await web3name.getDomainName({
        address,
        queryChainIdList: chainId,
      });

      return name;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

export const resolveWeb3DomainName = createAsyncThunk(RESOLVE_WEB3_DOMAIN_NAME,
  async (data, { rejectWithValue }) => {
    const { web3DomainName } = data;
    try {
      const address = await web3name.getAddress(web3DomainName);

      return address;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

export const checkDomainNameAvailability = createAsyncThunk(CHECK_DOMAIN_AVAILABLE,
  async (data, { rejectWithValue }) => {
    const { label, chainIDHex, chainId } = data;
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        await provider.send('wallet_switchEthereumChain', [{ chainId: chainIDHex }]);
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();

        // init SIDRegister
        const register = new SIDRegister({ signer, chainId });
        const available = await register.getAvailable(label);
        const price = await register.getRentPrice(label, 1);

        return { available, price: ethers.utils.formatEther(price) };
      } catch (error) {
        return rejectWithValue(error.message);
      }
    } else {
      return rejectWithValue('Ethereum provider not detected.  Make sure MetaMask or another Web3 wallet is installed.');
    }
  });

export const registerBNBDomain = createAsyncThunk(REGISTER_BNB_DOMAIN,
  async (data, { rejectWithValue }) => {
    const {
      address, label, isChecked, chainId,
    } = data;
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const signer = provider.getSigner();

        // init SIDRegister
        const register = new SIDRegister({ signer, chainId });
        // register for one year. params:{label, address, duration, {setPrimaryName, referrer}}
        const res = await register.register(label, address, 1, {
          setPrimaryName: isChecked,
          referrer: 'jetpad.bnb',
        });
        return { res, message: `${label}.bnb Registration Successfull` };
      } catch (error) {
        return rejectWithValue(error.message);
      }
    } else {
      return rejectWithValue('Ethereum provider not detected.  Make sure MetaMask or another Web3 wallet is installed.');
    }
  });

export const registerETHDomain = createAsyncThunk(REGISTER_ETH_DOMAIN,
  async (data, { rejectWithValue }) => {
    const {
      address, label, chainId,
    } = data;
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const signer = provider.getSigner();

        // init SIDRegister
        const register = new SIDRegister({ signer, chainId });
        const res = await register.register(label, address, 1, {
          // wait for commit to be valid, waitTime = 60 in milliseconds
          onCommitSuccess: (waitTime) => new Promise((resolve) => {
            setTimeout(resolve, waitTime * 1000);
          }),
        });

        return { res, message: `${label}.eth Registration Successfull` };
      } catch (error) {
        return rejectWithValue(error.message);
      }
    } else {
      return rejectWithValue('Ethereum provider not detected.  Make sure MetaMask or another Web3 wallet is installed.');
    }
  });

export const getWeb3NameMetadata = createAsyncThunk(GET_WEB3_METADATA,
  async (data, { rejectWithValue }) => {
    try {
      const metadata = await web3name.getMetadata({ name: data });
      return metadata;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
