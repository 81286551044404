import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// UTILITIES
import { openInNewTab } from '../utils';

// CONSTANTS
import { bottomNavMenus } from '../constants';

// ASSETS
import { more } from '../assets/images';

const BottomNavBar = () => {
  const [curActive, setCurActive] = useState('Home');
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [dappPath, setDappPath] = useState(false);
  const isLoggedIn = useSelector((state) => state.Auth?.isLoggedIn);

  const navigate = useNavigate();
  const location = useLocation();

  const subMenus = [
    { title: 'Profile', path: '/profile' },
    { title: 'Multi-Sender', path: '/multisender' },
    { title: 'Web3-Name', path: '/web3-name' },
    { title: 'Twitter', path: 'https://twitter.com/JetPad_Finance', ext: true },
    { title: 'Telegram', path: 'https://t.me/JetPad_Finance', ext: true },
    { title: 'Docs', path: 'https://docs.jetpad.finance', ext: true },
  ];

  const handleMenuClick = (menu) => {
    navigate(menu?.path);
    setCurActive(menu.title);
    if (isMoreOpen) {
      setIsMoreOpen(false);
    }
  };

  const toggleMoreMenu = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  const closeMoreMenu = () => {
    setIsMoreOpen(false);
  };

  const handleSubMenuClick = (subMenu) => {
    closeMoreMenu();
    setCurActive('More');
    if (subMenu?.ext) {
      openInNewTab(subMenu.path);
    } else {
      navigate(subMenu.path);
    }
  };

  const checkPathName = (path) => {
    if (path === '/dapp-demo') {
      return true;
    }
    const pattern = /^\/dapp\/\w+$/;

    return pattern.test(path);
  };

  useEffect(() => {
    setDappPath(checkPathName(location.pathname));
  }, [location.pathname]);

  return (
    <div className={`${dappPath && 'hidden'} font-kanit flex md:hidden w-full px-2 pb-1 bg-subtleBg text-content border-t border-accent-1
      fixed bottom-0 left-0 right-0 z-30 h-[56px] duration-100 rounded-t-md
      `}
    >
      <aside className="grid grid-cols-5 w-full place-content-start shrink-0">
        {bottomNavMenus.map((menu) => (
          <li
            key={menu.title}
            className="flex flex-col cursor-pointer items-center justify-start py-1 px-1 hover:bg-mainBg/90 rounded-lg text-content"
          >
            <button
              type="button"
              className={`${menu.title === curActive && ' font-semibold  bg-mainHeading text-white'} flex flex-col items-center gap-1  rounded-t-md relative w-[90%] pt-1`}
              onClick={() => handleMenuClick(menu)}
            >
              {React.createElement(menu.src, { className: 'h-[18px] w-[18px]' })}
              <span className="origin-left transition-all duration-100 text-xs ">
                {menu.title}
              </span>
            </button>
          </li>
        ))}
        <li
          className=" flex flex-col cursor-pointer  text-content justify-center py-1 px-1 hover:bg-mainBg/90 rounded-lg relative"
        >
          <button
            type="button"
            className={` ${curActive === 'More' && 'font-semibold  bg-mainHeading'} flex flex-col items-center gap-1 hover:bg-mainHeading h-full  rounded-md relative  w-[90%]`}
            onClick={toggleMoreMenu}
            onMouseEnter={() => setIsMoreOpen(true)}
          >
            <img src={more} alt="more" className="h-[15px] w-[15px] invert" />
            <span className="origin-left transition-all duration-100 text-xs">
              More
            </span>
          </button>
          {isMoreOpen && (
          <ul
            className="flex flex-col gap-2 w-[60vw] sm:w-[40vw] text-content bg-mainBg rounded-md p-2 absolute bottom-12 right-[-15px] border border-accent-1"
            onMouseLeave={closeMoreMenu}
          >
            {subMenus?.map((item) => (
              <li
                key={item.title}
                className={`border-b border-accent-1 hover:bg-mainHeading rounded-md ${!isLoggedIn && item.path === '/profile' ? 'hidden' : 'flex'}`}
              >
                <button type="button" className="w-full flex items-center justify-start py-2" onClick={() => handleSubMenuClick(item)}>
                  <span className="text-xs">{item?.title}</span>
                </button>
              </li>
            ))}
          </ul>
          )}
        </li>
      </aside>
    </div>
  );
};

export default BottomNavBar;
