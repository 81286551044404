import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBGab_pRgASFmX5BV3JH53DuOY62gAgg8o',
  authDomain: 'votex-edf79.firebaseapp.com',
  projectId: 'votex-edf79',
  storageBucket: 'votex-edf79.appspot.com',
  messagingSenderId: '1185453922',
  appId: '1:1185453922:web:967516039d55db53e43e3b',
  measurementId: 'G-CZDFD8LW1Z',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export {
  auth, db, storage, analytics,
};
