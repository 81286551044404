import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-infinite-logo-slider';
import Rating from '@mui/material/Rating';
import CountUp from 'react-countup';
import { RandomAvatar } from 'react-random-avatars';
import { Helmet } from 'react-helmet';

// REACT ICONS
import { FaPeopleGroup } from 'react-icons/fa6';
import { FaBroadcastTower } from 'react-icons/fa';
import { SiWalletconnect } from 'react-icons/si';
import { GiDroplets } from 'react-icons/gi';
import {
  FcPieChart, FcCandleSticks, FcVip, FcPositiveDynamic,
} from 'react-icons/fc';

// Assets
import {
  appStore, googlePlay, social1, social2,
  airdropArt1, airdropArt2, social3, social4,
} from '../assets/images';

// CONSTANTS
import {
  supportedChain, dAppEngagements, projectOwnersTestimonials,
  socialEngagements,
} from '../constants';

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>JetPad - Home</title>
        <meta
          name="description"
          content="AI-Powered Airdrop Launchpad"
        />
        <meta property="og:title" content="JetPad - Home" />
        <meta
          property="og:description"
          content="AI-Powered Airdrop Launchpad"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      <section className="bg-mainBg text-content isolate overflow-hidden py-4  sm:p-8 grid place-items-center">
        <div className="relative flex flex-col items-center justify-evenly pt-[3%] min-h-[55vh] md:min-h-[60vh]">

          {/* Airdrop faling animation images */}
          <img src={airdropArt1} alt="airdrop art" className=" h-24 aspect-auto art-image-animation-1 art-image-1" />
          <img src={airdropArt2} alt="airdrop art" className="h-12 aspect-auto art-image-animation-2 art-image-2" />
          <img src={airdropArt1} alt="airdrop art" className=" h-24 aspect-auto art-image-animation-3 art-image-3" />
          <div
            className="relative before:absolute before:h-1/2 before:w-3/4 before:rounded-tr-full before:rounded-bl-full before:blur-3xl before:bg-accent-2 before:animate-spin-slower before:-z-10
            after:absolute after:h-2/3 after:w-2/3 after:rounded-tr-full after:rounded-bl-full after:blur-3xl after:bg-accent-1/80 after:animate-spin-slow after:-z-10
            px-4 md:px-[3%] lg:px-[6%]
          "
          >
            <div className="flex flex-col justify-center items-center font-kanit ">
              <div className="flex flex-col items-center gap-1 md:gap-2 xl:gap-3">
                <h1 className=" text-darkerHeading text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-black text-center lg:text-start">
                  AI-Powered
                  <span className=" text-mainHeading"> Airdrop </span>
                  Launchpad
                </h1>
                <h5 className=" text-subHeading text-lg md:text-xl lg:text-2xl my-2 text-center ">
                  Streamline airdrop creation, management, distribution,
                  and participation with JetPad&apos;s powerful platform.
                </h5>
                <div className="flex gap-2">
                  <button
                    type="button"
                    className=" bg-mainHeading text-mainBg hover:bg-mainHeading/50 text-lg px-2 py-1 rounded-lg font-bold"
                    onClick={() => navigate('/create-airdrop')}
                  >
                    Create Airdrop &#x2192;
                  </button>
                  <button
                    type="button"
                    className=" border border-mainHeading text-mainHeading text-lg px-2 py-1 rounded-lg  hover:bg-mainHeading hover:text-mainBg font-semibold"
                    onClick={() => navigate('/airdrops')}
                  >
                    Join Airdrop
                  </button>
                </div>
                <div className="flex gap-2 mt-1">
                  <img
                    src={appStore}
                    alt="App Store"
                    className="w-[130px] h-[2.6rem] md:w-[150px] md:h-[3rem]"
                  />
                  <img
                    src={googlePlay}
                    alt="Google Play Store"
                    className="w-[130px] h-[2.6rem] md:w-[150px] md:h-[3rem]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full items-center md:px-[10%] gap-6 mt-2 mb-5 m-auto">
            <ul className="flex flex-wrap gap-2 items-center justify-center">
              <li className="flex flex-col justify-center items-center">
                <span className=" text-subHeading text-lg md:text-xl">Total Airdrops:</span>
                <span className=" text-mainHeading text-3xl md:text-4xl font-bold">
                  <CountUp start={105} end={105} delay={1} suffix="+" duration="3">
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                </span>
              </li>
              <li className="flex flex-col justify-center items-center">
                <span className=" text-subHeading text-lg md:text-xl">Airdrop Participations:</span>
                <span className=" text-mainHeading text-3xl md:text-4xl font-bold">
                  <CountUp start={10920} end={10920} delay={1} suffix="+" duration="4">
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                </span>
              </li>
              <li className="flex flex-col justify-center items-center">
                <span className=" text-subHeading text-lg md:text-xl">Active Users:</span>
                <span className=" text-mainHeading text-3xl md:text-4xl font-bold">
                  <CountUp start={58400} end={58400} delay={1} suffix="+" duration="5">
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                </span>
              </li>
            </ul>
          </div>
          <div className=" relative w-full self-end">
            <div className=" w-full px-3 overflow-x-hidden absolute top-0 overflow-y-hidden ">
              <Slider
                width="170px"
                duration={70}
                pauseOnHover
                blurBorders={false}
                blurBoderColor="#fff"
              >
                {supportedChain.map((network) => (
                  <Slider.Slide key={network?.name} className="mr-3" id="sliderId">
                    <div className={`flex flex-col text-center justify-center px-3 py-1 rounded-full text-[#262626] ${network.bgColor} font-bold text-xl w-full h-full `}>
                      <span className={`flex gap-2 text-sm self-center ${network.textColor}`}>
                        <img src={network.logo} alt={network.name} className=" h-6 aspect-auto rounded-full" />
                        {network.name}
                      </span>
                      <span className="text-xs text-subHeading self-center">{network?.text}</span>
                    </div>
                  </Slider.Slide>
                ))}
              </Slider>
              <div className="absolute inset-0">
                <div className="absolute inset-y-0 left-0 w-16 bg-gradient-to-r from-mainBg/90 via-mainBg/60 to-transparent" />
                <div className="absolute inset-y-0 right-0 w-12 bg-gradient-to-l from-mainBg/90 via-mainBg/60 to-transparent" />
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-24 w-full px-4 md:px-[5%] lg:px-[10%]">
          <div className="text-content">
            <h2 className=" text-darkerHeading text-3xl md:text-4xl lg:text-5xl font-bold text-center mt-10">
              Explore the&#160;
              <span className="text-mainHeading">Ecosystem</span>
            </h2>
            <h3 className=" text-subHeading text-lg md:text-xl font-semibold text-center mb-8">
              Revolutionizing Airdrop Creation and Participation – Leaving
              behind the outdated Telegram bot approach.
            </h3>
            <div className=" bg-subtleBg rounded-lg p-3 border border-accent-1">
              <h3 className="my-0 py-0 text-3xl">Airdrops</h3>
              <p className=" text-content/70 text-xs md:text-sm">&quot;Project Owners&quot;</p>
              <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-3">
                <div className=" flex flex-col justify-betwee">
                  <FaPeopleGroup className=" w-[2.5rem] h-[2.5rem] p-0 animate-heartbeat hover:text-mainHeading" />
                  <div className="flex flex-col">
                    <h4 className=" text-xl font-bold">Social Airdrop</h4>
                    <span className=" text-subText">
                      Create, launch and manage social airdrops effortlessly and instantly.
                    </span>
                    <a
                      href="/create-airdrop/social"
                      rel="noopener noreferrer"
                      className="after:content-['>'] text-link hover:underline font-bold text-base mt-3"
                    >
                      Create Now&#160;
                    </a>
                  </div>
                </div>
                <div className=" flex flex-col justify-between">
                  <SiWalletconnect className=" w-[2.5rem] h-[2.5rem] p-0 animate-heartbeat hover:text-mainHeading" />
                  <div className="flex flex-col">
                    <h4 className=" text-xl font-bold">Dapp Airdrop</h4>
                    <span className=" text-subText">
                      Create and deploy airdrop dapp instantly with just texts.
                    </span>
                    <a
                      href="/create-airdrop/dapp"
                      rel="noopener noreferrer"
                      className="after:content-['>'] text-link hover:underline font-bold text-base mt-3"
                    >
                      Choose template&#160;
                    </a>
                  </div>
                </div>
                <div className=" flex flex-col justify-between">
                  <FaBroadcastTower className=" w-[2.5rem] h-[2.5rem] p-0 animate-heartbeat hover:text-mainHeading" />
                  <div className="flex flex-col">
                    <h4 className=" text-xl font-bold">Distribute Airdrop</h4>
                    <span className=" text-subText">
                      Effortlessly send tokens to multiple airdrop addresses for free.
                    </span>
                    <a
                      href="/my-airdrops"
                      rel="noopener noreferrer"
                      className="after:content-['>'] text-link hover:underline font-bold text-base mt-3"
                    >
                      Try Now&#160;
                    </a>
                  </div>
                </div>
              </div>
              <div className=" mt-4 border-t border-accent-1 pt-4">
                <p className=" text-content/70 text-xs md:text-sm">&quot;Airdrop Hunters&quot;</p>
                <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-3">
                  <div className=" flex flex-col">
                    <GiDroplets className=" w-[2.5rem] h-[2.5rem] p-0 animate-heartbeat hover:text-mainHeading" />
                    <div className="flex flex-col">
                      <h4 className=" text-xl font-bold">Earn Airdrops</h4>
                      <span className=" text-subText">
                        Participate in verified airdrops and get free airdrop tokens.
                      </span>
                      <a
                        href="/airdrops"
                        rel="noopener noreferrer"
                        className="after:content-['>'] text-link hover:underline font-bold text-base mt-3"
                      >
                        Join Now&#160;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Airdrop description section */}
        <div className="w-full mt-28 px-4 md:px-[5%] lg:px-[10%]">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-12 md:gap-16 lg:gap-24 mb-12 md:mb-6">
            <div className="flex flex-col gap-6">
              <h3 className=" text-darkerHeading text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold">
                Create and Manage Social Engagement Airdrop
              </h3>
              {socialEngagements.map((item) => (
                <div key={item.title} className="flex flex-col md:flex-row gap-3">
                  <div className={` h-[48px] w-[48px] rounded-lg ${item.bgColor}  flex justify-center items-center shrink-0`}>
                    {React.createElement(item.icon, { className: 'w-[2.4rem] h-[2.4rem] text-center animate-heartbeat' })}
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className=" text-base md:text-lg xl:text-xl font-semibold">{item.title}</span>
                    <p className=" text-sm lg:text-base text-subText">{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full ">
              <div className="relative w-full flex justify-end overflow-hidden">
                <img src={social2} alt="social airdrop" className=" absolute h-[70%] top-[20%] -z-10 w-full object-cover object-left border border-accent-1 rounded-md" />
                <img src={social1} alt="social airdrop" className=" transition-all md:hover:scale-110  duration-500 delay-300 max-h-[570px] lg:max-h-[672px]  w-[60%] max-w-sm lg:max-w-md shadow-xl shadow-accent-2 rounded-t-md" />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-12 md:gap-16 lg:gap-24">
            <div className="flex flex-col gap-6 order-1 md:order-2 justify-center">
              <h3 className=" text-darkerHeading text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold">
                Launch Airdrop Claim Dapp with Ready-Made Templates
              </h3>
              {dAppEngagements.map((item) => (
                <div key={item.title} className="flex flex-col md:flex-row gap-3">
                  <div className={` h-[48px] w-[48px] rounded-lg ${item.bgColor}  flex justify-center items-center shrink-0`}>
                    {React.createElement(item.icon, { className: 'w-[2.4rem] h-[2.4rem] text-center animate-heartbeat' })}
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className=" text-base md:text-lg xl:text-xl font-semibold">{item.title}</span>
                    <p className=" text-sm md:text-base text-subText">{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full order-1">
              <div className="relative w-full flex justify-start">
                <img src={social4} alt="social airdrop" className=" absolute h-[70%] top-[20%] -z-10 w-full object-cover object-right border border-accent-1 rounded-md" />
                <img src={social3} alt="social airdrop" className=" transition-all md:hover:scale-110  duration-500 delay-300  h-full max-h-[570px] lg:max-h-[672px] w-[60%] max-w-sm lg:max-w-md shadow-xl shadow-black rounded-md" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-28 w-full flex justify-center items-center">
          <button
            type="button"
            className=" bg-mainHeading text-white text-lg px-2 py-1 rounded-lg hover:bg-mainHeading/50 font-bold w-[55%] md:w-[45%] animate-heartbeat shadow-xl shadow-black"
            onClick={() => navigate('/create-airdrop')}
          >
            Create Airdrop Now! &#x2192;
          </button>
        </div>

        {/* more Ecosystem Section */}
        <div className="mt-12 px-4 md:px-[5%] lg:px-[10%]">
          <h2 className=" text-darkerHeading text-3xl md:text-4xl lg:text-5xl font-bold text-center mt-10">
            More On JetPad
            <span className="text-mainHeading"> Ecosystem</span>
          </h2>
          <h3 className=" text-subHeading text-lg md:text-xl font-semibold text-center mb-8">
            Much more than just an Airdrop Launchpad
          </h3>
          <div className=" bg-subtleBg rounded-lg p-3 border border-accent-1">
            <div className="mt-4 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className=" flex flex-col justify-between">
                <div className="h-[48px] w-[48px] rounded-lg bg-gray-300 flex justify-center items-center shrink-0">
                  <FcPieChart className="w-[2.4rem] h-[2.4rem] text-center animate-heartbeat" />
                </div>
                <div className="flex flex-col justify-between h-full">
                  <h4 className=" text-xl font-bold">Explore Portfolio</h4>
                  <span className=" text-subText">
                    Easily see, track and manage tokens in your ERC/BSC address or any other address
                  </span>
                  <a
                    href="/portfolio"
                    rel="noopener noreferrer"
                    className="after:content-['>'] text-link hover:underline font-bold text-base mt-3"
                  >
                    Try Now&#160;
                  </a>
                </div>
              </div>
              <div className=" flex flex-col justify-between">
                <div className="h-[48px] w-[48px] rounded-lg bg-blue-300 flex justify-center items-center shrink-0">
                  <FcCandleSticks className="w-[2.4rem] h-[2.4rem] text-center animate-heartbeat" />
                </div>
                <div className="flex flex-col justify-between h-full">
                  <h4 className=" text-xl font-bold">Live Chart</h4>
                  <span className=" text-subText">
                    View price charts for any token, search by name or contract address.
                  </span>
                  <a
                    href="/tokens/eth/0x14fee680690900ba0cccfc76ad70fd1b95d10e16"
                    rel="noopener noreferrer"
                    className="after:content-['>'] text-link hover:underline font-bold text-base mt-3"
                  >
                    Try Now&#160;
                  </a>
                </div>
              </div>
              <div className=" flex flex-col justify-between">
                <div className="h-[48px] w-[48px] rounded-lg bg-[#880f4f] flex justify-center items-center shrink-0">
                  <FcVip className="w-[2.4rem] h-[2.4rem] text-center animate-heartbeat" />
                </div>
                <div className="flex flex-col justify-between h-full">
                  <h4 className=" text-xl font-bold">Token MultiSender</h4>
                  <span className=" text-subText">
                    Send Tokens to multiple addresses with ease instantly.
                  </span>
                  <a
                    href="/create-airdrop/social"
                    rel="noopener noreferrer"
                    className="after:content-['>'] text-link hover:underline font-bold text-base mt-3"
                  >
                    Send Now&#160;
                  </a>
                </div>
              </div>
              <div className=" flex flex-col justify-between">
                <div className="h-[48px] w-[48px] rounded-lg bg-yellow-400 flex justify-center items-center shrink-0">
                  <FcPositiveDynamic className="w-[2.4rem] h-[2.4rem] text-center animate-heartbeat" />
                </div>
                <div className="flex flex-col justify-between h-full">
                  <h4 className=" text-xl font-bold">Trending Coins</h4>
                  <span className=" text-subText">
                    Get the latest list of top performing coins accross multiple networks
                  </span>
                  <a
                    href="/tokens/eth/0x14fee680690900ba0cccfc76ad70fd1b95d10e16#trending"
                    rel="noopener noreferrer"
                    className="after:content-['>'] text-link hover:underline font-bold text-base mt-3"
                  >
                    View Now&#160;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* User Testimonials section */}
        <div className="flex flex-col justify-center items-center">
          <div className="text-content  px-4 md:px-[5%] lg:px-[10%]">
            <h2 className=" text-darkerHeading text-3xl md:text-4xl lg:text-5xl font-bold text-center mt-10">
              Don’t take our word for it, see what
              <span className="text-mainHeading"> Other Users </span>
              are saying
            </h2>
            <h3 className=" text-subHeading text-sm md:text-base font-semibold text-center mb-8">
              Read why thousands of new projects and airdrop hunters love JetPad.
            </h3>
          </div>

          <div className="test-slider">
            <div className=" relative mb-4">
              <div className=" ">
                <Slider
                  width="300px"
                  duration={65}
                  pauseOnHover
                  blurBorders={false}
                  blurBoderColor="#fff"
                >
                  {projectOwnersTestimonials.map((testimoney) => (
                    <Slider.Slide key={testimoney.name} className=" bg-subtleBg text-content border border-accent-1 flex flex-col gap-2 p-2 rounded-lg mr-4">
                      <div className="flex gap-4 self-start">
                        <RandomAvatar name={testimoney.name} size={40} className="bg-mainBg p-1 rounded-full" />
                        <div className="flex flex-col">
                          <span className="text-base ">{testimoney.name}</span>
                          <span className=" text-subHeading text-sm ">{testimoney.role}</span>
                        </div>
                      </div>
                      <div className="self-start">
                        <Rating
                          name="half-rating-read"
                          defaultValue={testimoney.rating}
                          precision={0.5}
                          readOnly
                        />
                      </div>
                      <p>
                        {testimoney.text}
                      </p>
                      <span className="text-subText font-light self-start">{testimoney.date}</span>
                    </Slider.Slide>
                  ))}
                </Slider>
                <div className="absolute inset-0">
                  <div className="absolute inset-y-0 left-0 w-16 bg-gradient-to-r from-mainBg/90 via-mainBg/60 to-transparent" />
                  <div className="absolute inset-y-0 right-0 w-12 bg-gradient-to-l from-mainBg/90 via-mainBg/60 to-transparent" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Expaliner video section */}
        <div className=" mt-4 px-3 md:px-[3%] lg:px-[8%] w-full">
          <div className="flex flex-col justify-center items-center gap-4 bg-[#122b44] p-2 rounded-lg ">
            <span className=" text-white font-bold text-base md:text-xl ">
              Ready to get started? Create an account for free!
            </span>
            <button
              type="button"
              className=" bg-mainHeading text-white text-lg px-2 py-1 rounded-lg hover:bg-mainHeading/50 font-bold w-[50%] md:w-[40%] animate-heartbeat shadow-xl shadow-black"
              onClick={() => navigate('/signup')}
            >
              SignUp &#x2192;
            </button>
          </div>
        </div>

        {/* Premium memebership section */}
        <div className="flex flex-col justify-center items-center px-4 md:px-[5%] lg:px-[10%]">
          <div className="text-content">
            <h2 className=" text-darkerHeading text-3xl md:text-4xl lg:text-5xl font-bold text-center mt-10">
              Unlock exclusive benefits with
              <span className="text-mainHeading"> Premium </span>
              membership
            </h2>
            <h3 className=" text-subHeading text-sm md:text-base font-semibold text-center mb-8">
              Get Automatic addition to Exclusive Airdrops, Daily 10x Coins and analysis, and More
              – Elevate Your Crypto Rewards Today!
            </h3>
          </div>
          <button
            type="button"
            className=" bg-mainHeading text-white text-lg px-2 py-1 rounded-lg hover:bg-mainHeading/50 font-bold w-[40%]"
            onClick={() => navigate('/premium')}
          >
            Learn More &#x2192;
          </button>
        </div>
      </section>
    </>
  );
};

export default LandingPage;
