import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'rsuite';
import { Helmet } from 'react-helmet';

// Redux
import { getAllUserTokens } from '../Redux/Web3Auth/web3Auth';

// Images
import { tokenIMG } from '../assets/images';
import { errorToast, successToast } from '../utils';

const Portfolio = () => {
  const [data, setData] = useState({ address: '', chain: '56', networkName: 'BSC' });
  const [selectedOption, setSelectedOption] = useState('');
  const { tokens, loading } = useSelector((state) => state.Web3Auth);
  const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
  const user = useSelector((state) => state.Auth);
  const isConnected = useSelector((state) => state.Web3Auth?.isConnected);
  const address = useSelector((state) => state.Web3Auth?.address);
  const chain = useSelector((state) => state.Web3Auth?.chainID);
  const networkName = useSelector((state) => state.Web3Auth?.networkName);

  const dispatch = useDispatch();

  const getUserTokens = (userData) => {
    if (tokens?.length <= 0) {
      dispatch(getAllUserTokens(userData)).then((res) => {
        if (res.error) {
          errorToast(res.payload);
        } else {
          successToast('Tokens loaded');
        }
      });
    }
  };

  const handleOptionChange = (e) => {
    const networkText = e.target.options[e.target.selectedIndex].text;
    const networkID = e.target.options[e.target.selectedIndex].id;
    const networkValue = e.target.value;
    setSelectedOption(networkValue);

    if (user?.[networkValue] === '') {
      errorToast(`You have not set your ${networkValue}`);
    } else {
      setData({
        ...data, address: user?.[networkValue], chain: networkID, networkName: networkText,
      });
      getUserTokens({ address: user?.[networkValue], chain: networkID });
    }
  };

  const handleSearch = (e) => {
    setData({ ...data, address: e.target.value, chain: 56 });

    if (e.target.value.length >= 42) {
      getUserTokens({ address: e.target.value, chain: data.chain });
    }
  };

  useEffect(() => {
    if (isConnected) {
      setData({
        ...data, address, chain, networkName,
      });
      getUserTokens({ address, chain });
    } else if (isLoggedIn) {
      setData({ ...data, address: user?.BSCAddress, chain: 56 });
      getUserTokens({ address: user?.BSCAddress, chain: 56 });
    }
  }, [address, chain, user?.BSCAddress, isLoggedIn, isConnected]);

  return (
    <>
      <Helmet>
        <title>JetPad - Portfolio</title>
        <meta
          name="description"
          content="View your portfolio"
        />
        <meta property="og:title" content="JetPad - Portfolio" />
        <meta
          property="og:description"
          content="View your portfolio"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <section className="flex justify-center bg-mainBg text-content items-center">
        <div className=" my-8 mx-8 w-full flex flex-col gap-10 justify-center items-center">
          <div className="flex flex-col w-full gap-5 items-center min-h-screen">
            <div className="flex justify-between w-full bg-subtleBg border border-accent-1 shadow-md shadow-accent-1 px-5 pt-5 rounded-lg font-extrabold text-2xl lg:text-3xl">
              <select
                value={selectedOption}
                onChange={handleOptionChange}
                className=" border-accent-1 border text-center rounded-xl font-mono text-sm md:text-lg bg-subtleBg text-subText"
              >
                <option value="BSCAddress" id="56">BSC</option>
                <option value="ethereumAddress" id="1">Ethereum</option>
                <option value="polygonAddress" id="137">Polygon</option>
              </select>
              <h4>Portfolio</h4>
            </div>
            <div className="flex justify-center items-center w-full">
              <input
                type="text"
                placeholder="Enter wallet address"
                className="w-[50%] border-accent-1 border text-center rounded-xl py-1 hover:border-accent-1/80 bg-subtleBg text-subText"
                value={data.address}
                onChange={handleSearch}
                readOnly
              />
            </div>
            <div className="flex flex-col gap-5 w-[95%] md:w-[80%] p-5 border border-accent-1 rounded-lg bg-subtleBg">
              <div className="flex justify-between self-center w-full md:w-[60%] lg:w-[40%] px-4 pt-4 rounded-lg bg-mainBg shadow-lg shadow-accent-1 mb-4">
                <h4 className="font-bold text-base md:text-xl text-subHeading underline">Tokens</h4>
                <h4 className="font-bold text-base md:text-xl text-subHeading/50">Address Tnx</h4>
              </div>
              <div className="grid md:pl-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                <div className="w-full bg-gradient-to-r from-accent-1 via-accent-2/60 to-accent-1 border border-accent-1 rounded-xl py-8 px-3 ">
                  <p className=" italic font-bold text-sm mb-2">Total Assets in USD</p>
                  <h4 className=" font-bold text-4xl md:text-4xl md:mb-2">{`$${(tokens[tokens?.length - 1] || 0).toLocaleString()}`}</h4>
                </div>
                <div className="w-full bg-gradient-to-r from-accent-1 via-accent-2/60 to-accent-1 border border-accent-1  rounded-xl py-8 px-3 ">
                  <p className=" italic font-bold text-sm mb-2">Total</p>
                  <h4 className=" font-bold text-4xl md:text-4xl md:mb-2">{tokens.length > 0 ? `${tokens?.length - 1} token(s)` : '0 token(s)'}</h4>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="grid grid-cols-2 md:grid-cols-3 w-full place-content-center pl-2 font-bold text-xl pb-2 border-b-4 border-b-accent-1 shadow-sm shadow-accent-1">
                  <h4>Token</h4>
                  <h4>Balance</h4>
                  <h4 className="hidden md:block">Portfolio %</h4>
                </div>
                <div>
                  { // eslint-disable-next-line
                loading ? (
                  <div className="flex flex-col gap-4 justify-center items-center my-[15%]">
                    <div>
                      <Loader size="sm" content="Loading Tokens..." speed="fast" />
                    </div>
                  </div>
                ) : tokens.length === 0 ? (
                  <div className="flex flex-col gap-4 justify-center items-center my-[15%]">
                    <span className=" font-mono text-bold text-lg">You do not have any token in this wallet address</span>
                    <p className=" font-thin italic sm:text-xs md:text-base overflow-hidden">{data.address}</p>
                  </div>
                ) : (
                  tokens?.map((token) => (token?.usdPrice && (

                    <div key={token?.walletBalance?.token_address} className="grid grid-cols-2 md:grid-cols-3 w-full place-content-center pl-2 mt-2 font-mono border bg-mainBg hover:bg-mainBg/70 border-accent-1 rounded-md cursor-pointer">
                      <div className="">
                        <div className="flex items-center">
                          <img src={tokenIMG} alt="token" className="h-8 w-8 rounded-full" />
                          <h5 className="font-bold flex flex-wrap">
                            {token?.walletBalance?.name}
                            <span className="italic text-subText text-sm font-medium pl-3">{`$${token?.usdPrice?.toFixed(2).toLocaleString()}`}</span>
                          </h5>
                        </div>
                      </div>
                      <div>
                        <h5 className="font-bold">
                          <span>{`${token?.calculatedBalance?.toLocaleString()} `}</span>
                          <span className="italic text-xs self-center">{token?.walletBalance?.symbol}</span>
                        </h5>
                        <span className="italic text-subText text-sm font-medium">
                          {`$${(
                            token?.calculatedBalance * token?.usdPrice
                          )?.toFixed(2)}`}
                        </span>
                      </div>
                      <div className="hidden md:block">
                        <h5 className="font-bold">
                          {`${(
                            ((token?.calculatedBalance * token?.usdPrice)
                            / tokens[tokens?.length - 1]) * 100
                          ).toFixed(2)} %`}
                        </h5>
                      </div>
                    </div>
                  )))
                )
}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
