import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// COMPONENTS
import { JoinedAirdrops, CreatedAirdrops } from '../components';

const MyAirdrops = () => {
  const [activeComponent, setActiveComponent] = useState('JoinedAirdrops');
  const user = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  const {
    userID, joinedAirdrops, createdAirdrops, fetchedUser,
  } = user;

  return (
    <>
      <Helmet>
        <title>JetPad - My-Airdrops</title>
        <meta
          name="description"
          content="View All Joined/Created Airdrops"
        />
        <meta property="og:title" content="JetPad - My-Airdrops" />
        <meta
          property="og:description"
          content="View All Joined/Created Airdrops"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <section className="flex justify-center bg-mainBg items-center">
        <div className=" my-6 mx-1 md:mx-4 lg:mx-8 w-full flex flex-col gap-10 justify-center items-center">
          <div className="flex flex-col text-content w-full gap-3 items-center">
            <div className="flex justify-center items-center w-full">
              <input
                type="text"
                placeholder="Search Airdrop"
                className="w-[50%] border-accent-1 border-2 text-center rounded-xl py-1 hover:border-accent-2"
              // value="Search airdrop"
                disabled
              />
            </div>
            <div className="flex flex-col gap-5 w-[95%] md:w-[95%] lg:w-[80%] p-5 border border-accent-1 rounded-lg bg-subtleBg min-h-screen">
              <div className="flex justify-between self-center w-full md:w-[80%] lg:w-[50%] px-4 pt-4 pb-1 rounded-lg bg-mainBg shadow-md shadow-accent-1 mb-4">
                <button
                  type="button"
                  className={`font-bold text-link px-2 py-1 hover:text-subText ${activeComponent === 'JoinedAirdrops' && 'text-mainHeading bg-accent-1/40 border border-link px-2 py-1 rounded-lg '}`}
                  onClick={() => setActiveComponent('JoinedAirdrops')}
                >
                  My Airdrops
                </button>
                <button
                  type="button"
                  className={`font-bold text-link px-2 py-1 hover:text-subText ${activeComponent === 'CreatedAirdrops' && 'text-mainHeading bg-accent-1/40 border border-link px-2 py-1 rounded-lg '}`}
                  onClick={() => setActiveComponent('CreatedAirdrops')}
                >
                  Created By You
                </button>
              </div>
              <div>
                {fetchedUser ? (
                  <>
                    {activeComponent === 'JoinedAirdrops' ? (
                      <>
                        {joinedAirdrops?.length !== 0 && joinedAirdrops !== undefined ? (
                          <JoinedAirdrops
                            userID={userID}
                            joinedAirdrops={joinedAirdrops}
                          />
                        ) : (
                          <div className="w-full text-center font-mono font-bold flex flex-col gap-2">
                            <span>YOU HAVE NOT PARTICIPATED IN ANY AIRDROP YET</span>
                            <button
                              type="button"
                              className="bg-mainHeading border-mainHeading font-light text-sm md:font-semibold border px-2 py-1 rounded-lg hover:bg-mainHeading/80 w-[60%] self-center"
                              onClick={() => navigate('/airdrops')}
                            >
                              View Airdrops
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {createdAirdrops?.length !== 0 && createdAirdrops !== undefined ? (
                          <CreatedAirdrops
                            createdAirdrops={createdAirdrops}
                          />
                        ) : (
                          <div className="w-full text-center font-mono font-bold flex flex-col gap-2">
                            <span>YOU HAVE NOT CREATED IN ANY AIRDROP YET</span>
                            <button
                              type="button"
                              className="bg-mainHeading border-mainHeading font-light text-sm md:font-semibold border px-2 py-1 rounded-lg hover:bg-mainHeading/80 w-[60%] self-center"
                              onClick={() => navigate('/create-airdrop')}
                            >
                              Create Airdrop
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <div>LOADING...</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default MyAirdrops;
