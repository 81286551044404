import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

export default function iconToast(message, icon) {
  toast(message, {
    icon,
  });
}

// Themed Toasts

const themedSuccessToast = (message) => {
  toast.success(message, {
    style: {
      border: '1px solid #006CFF',
      padding: '16px',
      color: '#006CFF',
    },
    iconTheme: {
      primary: '#006CFF',
      secondary: '#FFFAEE',
    },
  });
};
const themedErrorToast = (message) => {
  toast.error(message, {
    style: {
      border: '1px solid #ff02b3',
      padding: '16px',
      color: '#ff02b3',
    },
    iconTheme: {
      primary: '#ff02b3',
      secondary: '#FFFAEE',
    },
  });
};

// Normal Toasts
const successToast = (message) => {
  toast.success(message);
};

const errorToast = (message) => {
  toast.error(message);
};

export const JSXToast = (url, text, message) => {
  toast((t) => (
    <span>
      {message}
      <button
        type="button"
        className="border border-[#006CFF] rounded-lg px-2 py-1 ml-1 bg-black text-white hover:bg-gray-600"
        onClick={() => {
          toast.dismiss(t.id);
        }}
      >
        <Link to={url}>{text}</Link>
      </button>
    </span>
  ));
};

const splitString = (str, start, end) => {
  if (start < 0 || end > str?.length || start >= end) {
    return str;
  }

  let newStr = `${str?.substring(0, start)}...`;
  newStr += str?.substring(end);

  return newStr;
};

const formatNumber = (number) => number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const convertNumberToString = (num) => {
  if (num === 0) return '0';
  const numString = String(num);
  const numLength = numString.length;
  const suffixes = ['', 'K', 'M', 'B', 'T', 'Q', 'QQ', 'QQQ', 'QQQQ', 'QQQQQ', 'QQQQQQ', 'QQQQQQQ', 'QQQQQQQQ', 'QQQQQQQQQ'];
  // Add more as needed

  let suffixIndex = Math.floor((numLength - 1) / 3);
  const divisor = 10 ** (suffixIndex * 3);

  let result = num / divisor;

  if (result >= 1000) {
    result /= 1000;
    suffixIndex += 1;
  }

  // Custom formatting function to ensure two decimal places without commas
  let formattedResult;
  if (suffixIndex === 0) {
    formattedResult = Math.round(result * 100) / 100;
  } else {
    formattedResult = result.toFixed(2);
  }

  return formattedResult + suffixes[suffixIndex];
};

const formatTimeStamp = async (airdropList) => {
  const airdrops = airdropList.map((itm) => {
    const timeStamp = new Date(itm.timeStamp.seconds * 1000 + itm.timeStamp.nanoseconds / 1000000);
    const options = {
      year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit',
    };
    const formattedTime = timeStamp.toLocaleString('en-US', options);

    return { ...itm, timeStamp: formattedTime }; // Add formattedTimestamp to the object
  });
  return airdrops;
};

const formatDateToSec = (dateString) => {
  const date = new Date(dateString);
  const options = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };
  return date.toLocaleString('en-US', options);
};

const calcDays = (startDateStr, endDateStr) => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const currentDate = new Date();

  const timeUntilStart = startDate - currentDate;
  const timeUntilEnd = endDate - currentDate;

  const daysUntilStart = Math.ceil(timeUntilStart / (1000 * 60 * 60 * 24));
  const daysUntilEnd = Math.ceil(timeUntilEnd / (1000 * 60 * 60 * 24));

  // Create the statement
  let statement = '';
  if (daysUntilStart > 0) {
    statement = {
      text: `Starts in ${daysUntilStart} days`,
      style: 'font-semibold text-sm text-yellow-500',
    };
  } else if (daysUntilEnd >= 0) {
    if (daysUntilEnd === 0) {
      statement = {
        text: 'Ends today',
        style: 'font-semibold text-sm text-yellow-700',
      };
    } else if (daysUntilEnd === 1) {
      statement = {
        text: 'Ends tomorrow',
        style: 'font-semibold text-sm text-yellow-500',
      };
    } else {
      statement = {
        text: `Ends in ${daysUntilEnd} days`,
        style: 'font-semibold text-sm text-green-500',
      };
    }
  } else {
    statement = {
      text: 'Ended',
      style: 'text-sm font-bold text-red-400 rounded-full px-2 w-fit',
    };
  }

  return statement;
};

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const checkActive = (startDateStr, endDateStr) => {
  // Parse the input date strings
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Get the current date
  const currentDate = new Date();

  if (currentDate >= startDate && currentDate <= endDate) {
    return false;
  }
  return true;
};

const updateReferral = async (participants, userID, refAmount) => {
  for (let i = 0; i < participants.length; i += 1) {
    const participant = participants[i];

    if (participant.userID === userID) {
      participant.noOfReferral += 1;

      participant.referralReward += Number(refAmount);

      break;
    }
  }

  return participants;
};

const isStarted = (startDateStr) => {
  const startDate = new Date(startDateStr);

  // Get the current date
  const currentDate = new Date();

  if (startDate >= currentDate) {
    return false;
  }
  return true;
};

const updateUserAirdropRef = async (id, referralAmount, joinedAirdrops) => {
  for (let i = 0; i < joinedAirdrops.length; i += 1) {
    const joinedAirdrop = joinedAirdrops[i];

    if (joinedAirdrop.airdropID === id) {
      joinedAirdrop.refCount += 1;
      joinedAirdrop.refBalance += Number(referralAmount);
      break;
    }
  }

  return joinedAirdrops;
};

const checkJoinedStatus = (participants, userID) => {
  for (let i = 0; i < participants.length; i += 1) {
    const participant = participants[i];

    if (participant.userID === userID) {
      return true;
    }
  }
  return false;
};

const removeAtSymbol = (inputString) => {
  if (inputString.startsWith('@')) {
    return inputString.slice(1);
  }
  return inputString;
};

const getUsernameFromTelegramURL = (url) => {
  try {
    const parts = url.split('/');
    const username = parts[parts.length - 1];

    if (!username.startsWith('@')) {
      return `@${username}`;
    }

    return username;
  } catch (error) {
    return `Error extracting username:, ${error}`;
  }
};

const convertTokenDistribution = (tokenDistribution) => {
  const newData = [['Token', 'Allocation']];

  Object.keys(tokenDistribution).forEach((key) => {
    const value = parseInt(tokenDistribution[key], 10); // Specify radix 10 for parseInt
    newData.push([key, value.isNaN ? 0 : value]);
  });

  return newData;
};

const handleCopy = (inputValue) => {
  if (inputValue === 'Connect Your Wallet') {
    return false;
  }

  const textarea = document.createElement('textarea');
  textarea.value = inputValue;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);

  return true;
};

const formatDate = (inputDateString) => {
  let dateString = inputDateString;

  if (dateString.startsWith('"') && dateString.endsWith('"')) {
    dateString = dateString.slice(1, -1);
  }
  const parsedDate = new Date(dateString);

  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  const day = parsedDate.getDate();
  const monthIndex = parsedDate.getMonth();
  const year = parsedDate.getFullYear();

  const dayOfWeek = parsedDate.toLocaleDateString('en-US', { weekday: 'short' });

  const formattedDate = `${dayOfWeek} ${monthNames[monthIndex]} ${day} ${year}`;
  return formattedDate;
};

const convertBTCtoUSD = (priceInBTC, btcToUSDExchangeRate) => {
  if (typeof priceInBTC !== 'number' || typeof btcToUSDExchangeRate !== 'number') {
    throw new Error('Both inputs must be valid numbers');
  }

  const priceInUSD = priceInBTC * btcToUSDExchangeRate;

  return priceInUSD;
};

const getNetworkId = (networks, key) => {
  const newKey = key?.replace(/[-\\/_]/g, ' ');
  const lowercaseKey = newKey?.toLowerCase();
  return networks[lowercaseKey] || '';
};

const formatNumberToReadable = (number) => {
  if (Number.isNaN(Number(number))) {
    return 'Invalid number';
  }

  const million = 1000000;
  const billion = 1000000000;
  const trillion = 1000000000000;

  const num = parseFloat(number);

  if (num >= trillion) {
    const truncatedNumber = (num / trillion).toFixed(1);
    return `${truncatedNumber} trillion`;
  } if (num >= billion) {
    const truncatedNumber = (num / billion).toFixed(1);
    return `${truncatedNumber} billion`;
  } if (num >= million) {
    const truncatedNumber = (num / million).toFixed(1);
    return `${truncatedNumber} million`;
  } if (num >= 1000) {
    const truncatedNumber = (num / 1000).toFixed(1);
    return `${truncatedNumber} thousand`;
  }

  return num.toString();
};

const extractHexPart = (inputString) => {
  if (inputString.includes('0x')) {
    const parts = inputString.split('0x');
    if (parts.length === 2) {
      return `0x${parts[1]}`;
    }
  } else {
    const parts = inputString.split('_');
    if (parts.length === 2) {
      return parts[1];
    }
  }
  return inputString;
};

const convertOHLCVFormat = (data) => {
  data.sort((a, b) => a[0] - b[0]);

  const candlestickData = data.map((innerArray) => {
    if (innerArray.length >= 5) {
      const [time, open, high, low, close, volume] = innerArray;
      return {
        time: time * 1000, open, high, low, close, volume,
      };
    }
    return null;
  }).filter((item) => item !== null);

  const deduplicatedData = candlestickData.filter(
    (item, index, self) => self.findIndex((x) => x.time === item.time) === index,
  );

  return deduplicatedData;
};

const isValidEthereumAddress = (input) => {
  const ethAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

  return ethAddressRegex.test(input);
};

const endsWithBnb = (inputString) => {
  const regex = /\.bnb$/;
  return regex.test(inputString);
};

const endsWithEth = (inputString) => {
  const regex = /\.eth$/;
  return regex.test(inputString);
};

const endsWithBnbOrEth = (inputString) => {
  const regex = /\.(bnb|eth)$/;
  return regex.test(inputString);
};

const endsWithSupportedDomain = (inputString) => {
  const regex = /\.(bnb|eth|gno|arb)$/;
  return regex.test(inputString);
};

// Function to validate the username
const isValidUsername = (username) => {
  const usernameRegex = /^[a-z0-9_]+$/;
  return usernameRegex.test(username);
};

const countTradeKinds = (tradesArray) => {
  let buyCount = 0;
  let sellCount = 0;

  tradesArray.forEach((trade) => {
    const tradeKind = trade.attributes.kind;
    if (tradeKind === 'buy') {
      buyCount += 1;
    } else if (tradeKind === 'sell') {
      sellCount += 1;
    }
  });

  return {
    buyCount,
    sellCount,
  };
};

const getCountryCounts = (participants, threshold = 4) => {
  const countryCounts = {};

  participants.forEach((participant) => {
    const country = participant.country || 'Unknown';

    if (country in countryCounts) {
      countryCounts[country] += 1;
    } else {
      countryCounts[country] = 1;
    }
  });

  const sortedCountries = Object.entries(countryCounts).sort((a, b) => b[1] - a[1]);

  const data = [['Country', 'Users']];

  let otherCount = 0;

  sortedCountries.forEach((country, index) => {
    const [countryName, count] = country;

    if (index < threshold) {
      data.push([countryName, count]);
    } else {
      otherCount += count;
    }
  });

  if (otherCount > 0) {
    data.push(['Other', otherCount]);
  }

  return data;
};

const roundToFourDecimalsOrTwoDecimal = (number) => {
  if (Number.isInteger(number)) {
    return number;
  }
  if (Math.round(number) === number) {
    return parseFloat(number.toFixed(2));
  }
  return parseFloat(number.toFixed(4));
};

export {
  successToast, errorToast, splitString,
  themedErrorToast, themedSuccessToast, formatNumber,
  formatTimeStamp, calcDays, openInNewTab, checkActive, updateReferral,
  checkJoinedStatus, removeAtSymbol, getUsernameFromTelegramURL, convertTokenDistribution,
  handleCopy, updateUserAirdropRef, formatDate, convertBTCtoUSD, getNetworkId, extractHexPart,
  formatNumberToReadable, convertOHLCVFormat, isStarted, isValidEthereumAddress, isValidUsername,
  convertNumberToString, formatDateToSec, countTradeKinds, getCountryCounts, endsWithBnb,
  endsWithEth, endsWithBnbOrEth, roundToFourDecimalsOrTwoDecimal, endsWithSupportedDomain,
};
