import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import JoinedCard from './JoinedCard';

// ASSETS

const JoinedAirdrops = ({ joinedAirdrops, userID }) => {
  const reversedJoinedAirdrops = [...joinedAirdrops]?.reverse();
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {reversedJoinedAirdrops?.map((airdrop) => (
        <JoinedCard
          key={airdrop.projectName}
          airdrop={airdrop}
          userID={userID}
        />
      ))}
    </div>
  );
};
JoinedAirdrops.propTypes = {
  userID: PropTypes.string.isRequired,
  joinedAirdrops: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default JoinedAirdrops;
