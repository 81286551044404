import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { Loader } from 'rsuite';
import { Helmet } from 'react-helmet';

// Redux
import { resetUserPassword } from '../../Redux/Auth/auth';

// Utils
import { errorToast, successToast } from '../../utils';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useSelector((state) => state.Auth?.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetPassword = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      errorToast('Enter a valid Email');
    } else {
      setLoading(true);
      dispatch(resetUserPassword(email)).then((res) => {
        if (res.error) {
          errorToast(res.payload);
          setLoading(false);
        } else {
          successToast('Email verification link sent');
          setLoading(false);
        }
      });
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/profile" />;
  }

  return (
    <>
      <Helmet>
        <title>JetPad - Forgot-Password</title>
        <meta
          name="description"
          content="Recover your account password"
        />
        <meta property="og:title" content="JetPad - Forgot-Password" />
        <meta
          property="og:description"
          content="Recover your account password"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <section className=" min-h-screen overflow-hidden  flex justify-center bg-mainBg text-content items-center">
        <div className=" my-8 mx-2  md:mx-8 w-full flex flex-col  justify-center items-center">
          <div className=" bg-subtleBg border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-2 md:p-6 flex flex-col items-center shadow-md shadow-accent-1">
            <h2 className=" font-extrabold leading-3 text-3xl mb-10 mt-5">Reset Password</h2>
            <span className=" text-center font-light text-lg md:text-xl mb-10">
              Enter your email address to reset your password.
              You may need to check your spam folder
            </span>
            <div
              className=" flex flex-col gap-5 w-full items-center"
            >
              <input
                name="email"
                className="auth-input placeholder:text-xs border border-accent-1"
                type="text"
                placeholder="Enter Email address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className=" w-[90%] flex  justify-end">
                <button
                  type="button"
                  className="bg-none text-link font-semibold text-lg outline-none hover:text-link/90"
                  onClick={() => navigate('/signin')}
                >
                  Go to Login
                </button>
              </div>
              <button
                type="button"
                onClick={() => resetPassword()}
                className="self-center bg-mainHeading text-mainBg px-4 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
              >
                {
                loading ? <Loader size="sm" content="Sending..." speed="fast" inverse /> : 'Reset Password'
              }
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
