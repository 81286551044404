import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import 'react-loading-skeleton/dist/skeleton.css';
import 'rsuite/dist/rsuite.min.css';

// Firebase
import { auth } from './firebase';

// Redux
import { checkAuthStatus, fetchUserData, resendVerificatonEmail } from './Redux/Auth/auth';

// Pages
import {
  LandingPage, SignIn, SignUp, VerifyEmail, Profile, Portfolio,
  ForgotPassword, CreateAirdrop, SocialAirdrop, Airdrops,
  AirdropDetails, DappDemo, DappAirdrop, Dapp, MyAirdrops, Tokens, DistributeAirdrop,
  MultiSender, AuthActions, SpaceID,
} from './pages';

// Components
import {
  Navbar, Footer, ProtectedRoute, Sidebar, BottomNavBar,
} from './components';

// Utils
import { errorToast, successToast } from './utils';

// Stylesheet
import './App.css';

function App() {
  const [open, setOpen] = useState(true);
  const [dappPath, setDappPath] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        if (authUser.emailVerified) {
          const user = {
            name: authUser.displayName,
            email: authUser.email,
            userID: authUser.uid,
            joinedDate: authUser.metadata.creationTime,
            isLoggedIn: true,
            emailVerified: authUser.emailVerified,
            isAuthCompleted: true,
          };
          dispatch(checkAuthStatus(user));
          dispatch(fetchUserData(authUser.uid));
        } else {
          errorToast('Your Email is not verified Yet!');
          dispatch(resendVerificatonEmail()).then((res) => {
            if (res.error) {
              errorToast(res.payload);
            } else {
              successToast('Email verification link sent');
              navigate('/verify-email');
            }
          });
        }
      } else {
        const user = {
          isLoggedIn: false,
          isAuthCompleted: true,
        };
        dispatch(checkAuthStatus(user));
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    console.clear();
  }, [window.location.pathname]);

  const gridCols = () => {
    if (dappPath) {
      return 'grid-cols-1';
    }
    if (open) {
      return 'md:grid-cols-[4rem_auto] lg:grid-cols-[12rem_auto]';
    }
    return 'md:grid-cols-[64px_auto]';
  };

  console.clear();

  return (
    <>
      <Toaster />
      <Navbar />
      <section className={`md:grid ${gridCols()} transition-all duration-500 font-kanit`}>
        <div className={`${dappPath && 'hidden'} bg-mainBg transition-all duration-500`}>
          <Sidebar open={open} setOpen={setOpen} setDappPath={setDappPath} />
        </div>

        <main className="transition-all duration-300">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/reset-pass" element={<ForgotPassword />} />
            <Route path="/auth/action" element={<AuthActions />} />
            {/* User's protected Routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="/my-airdrops" element={<MyAirdrops />} />
              <Route path="/distribute/:id" element={<DistributeAirdrop />} />
            </Route>
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/create-airdrop" element={<CreateAirdrop />} />
            <Route path="/create-airdrop/social" element={<SocialAirdrop />} />
            <Route path="/create-airdrop/dapp" element={<DappAirdrop />} />
            <Route path="/airdrops" element={<Airdrops />} />
            <Route path="/airdrop/:id" element={<AirdropDetails />} />
            <Route path="/dapp/:id" element={<Dapp />} />
            <Route path="/dapp-demo" element={<DappDemo />} />
            <Route path="/tokens/:network/:address" element={<Tokens />} />
            <Route path="/multisender" element={<MultiSender />} />
            <Route path="/web3-name" element={<SpaceID />} />
            <Route path="*" element={<LandingPage />} />
          </Routes>
          <Footer />
        </main>
      </section>
      <BottomNavBar />
    </>
  );
}

export default App;
