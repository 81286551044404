import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'rsuite';
import { IoIosSearch } from 'react-icons/io';
import Checkbox from '@mui/material/Checkbox';
import { IoCheckmarkDoneCircle } from 'react-icons/io5';

// COMPONENTS
import { ClickToolTip, NameLookUp } from '../components';

// REDUX
import {
  checkDomainNameAvailability, connectUserWallet, registerBNBDomain,
  registerETHDomain, switchNetwork,
} from '../Redux/Web3Auth/web3Auth';
import { getTokenPrices } from '../Redux/TokenData/tokenData';

// UTILITIES
import { errorToast, roundToFourDecimalsOrTwoDecimal, successToast } from '../utils';

// ASSETS
import { BSCLogo, ETHLogo } from '../assets/images';

const SpaceID = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [curstatus, setCurstatus] = useState('');
  const [searchText, setSearchText] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [activeChain, setActiveChain] = useState(null);
  const [chainImg, setChainImg] = useState('');
  const [searchResult, setSearchResult] = useState(false);
  const [label, setLabel] = useState('jetpad');
  const [price, setPrice] = useState(0);
  const [available, setAvailable] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [regSuccess, setRegSuccess] = useState(false);
  const [toolTipState, setToolTipState] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [activeTab, setActiveTab] = useState('registration');

  const {
    isConnected, chainID, connectLoading, balance, address,
  } = useSelector((state) => state.Web3Auth);
  const tokenPrices = useSelector((state) => state.tokenData?.tokenPrices);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tokenPrices?.ethereum) {
      const ids = 'bitcoin,ethereum,binancecoin,matic-network';
      dispatch(getTokenPrices({ ids }));
    }
  }, []);

  useEffect(() => {
    if (isConnected) {
      setActiveChain(chainID);
      const chainImage = {
        56: BSCLogo,
        1: ETHLogo,
      };

      setChainImg(chainImage[chainID]);
    }
  }, [isConnected, chainID]);

  const connectWallet = () => {
    setSearchText('');
    dispatch(connectUserWallet()).then((res) => {
      if (res.error) {
        errorToast(res.payload);
      } else {
        successToast('Wallet connected');
      }
    });
  };

  const handleSwitchNetwork = (chainIDHex) => {
    if (!isConnected) {
      return errorToast('Connect Wallet first');
    }
    setSearchText('');
    dispatch(switchNetwork({ chainIDHex })).then((res) => {
      if (res.error) {
        errorToast(res.payload);
      } else {
        // connectWallet();
        successToast(res.payload);
        setSearchResult(false);
      }
    });
    return {};
  };

  const handleCheckAvailability = (data) => {
    dispatch(checkDomainNameAvailability(data)).then((res) => {
      if (res.error) {
        errorToast(res.payload);
        setCurstatus('');
      } else {
        setSearchResult(true);
        setPrice(roundToFourDecimalsOrTwoDecimal(Number(res.payload?.price) || 0));
        setAvailable(res.payload?.available);
        setCurstatus('');
      }
    });
  };

  useEffect(() => {
    setRegSuccess(false);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTypingTimeout = setTimeout(() => {
      if (searchText.trim() !== '') {
        if (!isConnected) {
          return errorToast('Please connect to supported chain');
        }
        if (searchText.length < 3) {
          return errorToast('Name Must be up to 3 characters');
        }
        setCurstatus('Searching...');
        setSearchResult(false);
        const labelText = searchText.split('.')[0].toLocaleLowerCase();
        setLabel(labelText);
        if (chainID === 1) {
          return handleCheckAvailability({ label: labelText, chainIDHex: '0x1', chainId: 1 });
        }
        if (chainID === 56) {
          return handleCheckAvailability({ label: labelText, chainIDHex: '0x38', chainId: 56 });
        }
      }
      return {};
    }, 2000);

    setTypingTimeout(newTypingTimeout);
  }, [searchText]);

  const handleRegister = () => {
    const data = {
      address,
      isChecked,
      label,
      chainId: chainID,
    };
    if (chainID === 1) {
      if (price >= balance) {
        return errorToast('Insufficient eth balance');
      }
      setRegistering(true);
      dispatch(registerETHDomain(data)).then((res) => {
        if (res.error) {
          errorToast('Name registration failed, Try again!!');
        } else {
          successToast(`${label}.eth Successfully registered!!!`);
          setRegSuccess(true);
        }
        setRegistering(false);
      });
    } else if (chainID === 56) {
      // if (price >= balance) {
      //   return errorToast('Insufficient bnb balance');
      // }
      setRegistering(true);
      dispatch(registerBNBDomain(data)).then((res) => {
        if (res.error) {
          errorToast('Name registration failed, Try again!!');
        } else {
          successToast(`${label}.bnb Successfully registered!!!`);
          setRegSuccess(true);
        }
        setRegistering(false);
      });
    }

    return {};
  };

  const getPlaceholderText = () => {
    let placeholderText = '';

    if (isConnected) {
      if (chainID === 1 || chainID === 56) {
        placeholderText = `Search ${chainID === 1 ? '.eth' : '.bnb'} name`;
      } else {
        placeholderText = 'Please connect to supported chain';
      }
    } else {
      placeholderText = 'Please connect Your Wallet first';
    }

    return placeholderText;
  };
  const getEnableInputStatus = () => {
    let enableStatus = true;

    if (isConnected) {
      if (chainID === 1 || chainID === 56) {
        enableStatus = false;
      } else {
        enableStatus = true;
      }
    } else {
      enableStatus = true;
    }

    return enableStatus;
  };

  return (
    <>
      <Helmet>
        <title>JetPad - SpaceID</title>
        <meta
          name="description"
          content="Web3 Name Service by spaceID"
        />
        <meta property="og:title" content="JetPad - SpaceID" />
        <meta
          property="og:description"
          content="Web3 Name Service by spaceID"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      <section className="flex justify-center bg-mainBg text-content items-center">
        <div className="relative flex flex-col items-center gap-1 w-[95%] md:w-[95%] lg:w-[80%] p-5 my-[3%] border border-accent-1 rounded-lg bg-subtleBg min-h-screen">
          <div className="flex gap-3 absolute self-center top-0">
            <button
              type="button"
              className={`${activeTab === 'registration' ? 'bg-accent-2' : 'bg-accent-1/60'} flex justify-center items-center px-2 py-1 rounded-b-md hover:bg-accent-1 hover:text-subText`}
              onClick={() => setActiveTab('registration')}
            >
              Name Registration
              {activeTab === 'registration' && <IoCheckmarkDoneCircle className="text-[#1EEFA4]" />}
            </button>
            <button
              type="button"
              className={`${activeTab === 'lookup' ? 'bg-accent-2' : 'bg-accent-1/60'} flex justify-center items-center px-2 py-1 rounded-b-md hover:bg-accent-1 hover:text-subText`}
              onClick={() => setActiveTab('lookup')}
            >
              Name LookUp
              {activeTab === 'lookup' && <IoCheckmarkDoneCircle className="text-[#1EEFA4]" />}
            </button>
          </div>
          {activeTab === 'lookup' ? (
            <NameLookUp />
          ) : (
            <>

              <div className="flex gap-4 self-center justify-between w-full md:w-[80%] lg:w-[50%] px-4 pt-4 pb-1 rounded-lg bg-mainBg shadow-md shadow-accent-1 mb-4 mt-[7%] md:mt-[5%]">
                <div className="w-full flex gap-4">
                  <button
                    type="button"
                    className={`flex gap-1 items-center border border-mainHeading rounded-lg text-link hover:bg-subText hover:text-content px-1 py-0 h-fit ${activeChain === 56 && 'bg-mainHeading text-white'}`}
                    onClick={() => handleSwitchNetwork('0x38')}
                  >
                    <img src={BSCLogo} alt="bsc logo" className="  w-auto h-[14px]" />
                    BSC
                  </button>
                  <button
                    type="button"
                    className={`flex gap-1 items-center border border-mainHeading rounded-lg text-link hover:bg-subText hover:text-content px-1 py-0 h-fit ${activeChain === 1 && 'bg-mainHeading text-white'}`}
                    onClick={() => handleSwitchNetwork('0x1')}
                  >
                    <img src={ETHLogo} alt="eth logo" className="  w-auto h-[14px]" />
                    ETH
                  </button>
                </div>
                <div className="w-full flex justify-end">
                  <button
                    type="button"
                    className=" bg-mainHeading text-mainBg py-1 px-2 rounded-lg hover:bg-mainHeading/60 text-xs md:text-sm disabled:bg-mainHeading/30"
                    onClick={() => connectWallet()}
                    disabled={connectLoading}
                  >
                    {connectLoading ? (
                      <>
                        <Loader size="xs" content="connecting..." speed="fast" className=" text-xs m-0 p-0" />
                      </>
                    ) : (
                      <>
                        {isConnected ? (
                          <span className="flex gap-1 items-center">
                            {(chainID !== 1 && chainID !== 56) ? (
                              <span>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    handleSwitchNetwork('0x1');
                                    e.stopPropagation();
                                  }}
                                >
                                  Switch to Supported Chain
                                </button>
                              </span>
                            ) : (
                              <>
                                <img src={chainImg} alt="chain logo" className="  w-auto h-[14px]" />
                                {`${roundToFourDecimalsOrTwoDecimal(Number(balance) || 0)} ${chainID === 1 ? 'ETH' : 'BNB'}`}
                              </>
                            )}
                          </span>
                        ) : (
                          'Connect Wallet'
                        )}
                      </>
                    )}
                  </button>
                </div>
              </div>
              <div>
                <h3 className=" font-bold text-3xl text-content">
                  .Bnb/.Eth Name Service
                </h3>
                <span className="flex gap-1 justify-center text-subText text-sm">
                  On
                  <span className="flex gap-1 items-center font-semibold">
                    <img src={BSCLogo} alt="chain logo" className="  w-auto h-[14px]" />
                    BNB Smart Chain
                  </span>
                  |
                  <span className="flex gap-1 items-center font-semibold">
                    <img src={ETHLogo} alt="chain logo" className="  w-auto h-[14px]" />
                    Ethereum Chain
                  </span>
                </span>
                <p className=" text-sm text-content flex justify-center gap-1">
                  Powered By
                  <span className="text-[#DEAB0A]"> spaceid.bnb</span>
                </p>
              </div>
              <div className="w-[90%] md:-w-[70%] flex flex-col items-center gap-1 mt-4">
                <div
                  className={`flex justify-center items-center h-full w-full md:w-[70%] lg:w-[60%] border border-accent-1 rounded-lg px-3  focus:border-accent-2 hover:border-accent-2 text-content bg-mainBg ${getEnableInputStatus() && 'bg-subText'}`}
                >
                  <input
                    type="text"
                    placeholder={getPlaceholderText()}
                    value={searchText}
                    disabled={getEnableInputStatus()}
                    className="w-full py-2 text-content bg-mainBg disabled:bg-subText focus:outline-none "
                    onChange={(e) => setSearchText(e.target.value)}
                    onFocus={() => {
                      setIsFocused(true);
                      setCurstatus('Waiting...');
                    }}
                    onBlur={() => {
                      setIsFocused(false);
                      setSearchText('');
                    }}
                  />
                  <IoIosSearch className="text-content h-8 w-auto" />
                </div>

                <div>
                  {isFocused && curstatus && (
                  <span>
                    <Loader size="xs" content={curstatus} speed="fast" className=" text-xs m-0 p-0" />
                  </span>
                  )}
                </div>
              </div>
              {searchResult && (
              <div className="w-full md:w-[70%] lg:w-[60%] bg-mainBg mt-[5%] rounded-lg">
                <div className=" flex flex-col gap-12">
                  <div className="flex gap-2 p-2">
                    <span className="relative h-28 w-28 rounded-md flex justify-center items-center bg-[#DEAB0A]">
                      <img src={chainImg} alt="chain logo" className="  w-auto h-14" />
                      <span className=" absolute top-0 right-0 w-full h-full flex items-center justify-center bg-white text-sm rounded-md bg-opacity-[40%]">
                        {label}
                        {chainID === 1 ? '.eth' : '.bnb'}
                      </span>
                    </span>
                    <div className="flex flex-col gap-1 h-28 justify-center">
                      <span className=" font-bold text-2xl md:text-3xl">
                        {label}
                        <span className="text-[#DEAB0A]">
                          {chainID === 1 ? '.eth' : '.bnb'}
                        </span>
                      </span>
                      <span className="text-sm bg-accent-1 p-1 rounded-md">
                        {chainID === 1 ? '.eth ' : '.bnb '}
                        Name Service By Space.id
                      </span>
                    </div>
                  </div>

                  <div className="relative border border-t-4 border-accent-1 rounded-md p-2 flex flex-col">
                    <div className="absolute bg-accent-1 px-2 py-1 rounded-t-md -top-9 self-center">
                      <span
                        className={`text-semibold ${available ? 'text-[#DEAB0A]' : 'text-red-500'}`}
                      >
                        {available ? 'Available' : 'Not Available'}
                      </span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className=" text-sm">Registration Price</p>
                      <div className="flex items-center">
                        <img src={chainImg} alt="chain logo" className="  w-auto h-8" />
                        <span className="text-2xl font-bold">
                          {price}
                        </span>
                        /Year
                      </div>
                    </div>

                    <div className=" mt-4 border-t border-accent-1">
                      <ul>
                        <li className="flex items-center justify-between py-1 border-b-[0.5px] border-accent-1">
                          <span>Registration Year</span>
                          <span>1 year</span>
                        </li>
                        <li className="flex items-center justify-between py-1 border-b-[0.5px] border-accent-1">
                          <span>
                            Set as primary name
                            <ClickToolTip
                              openState={toolTipState}
                              setOpenState={setToolTipState}
                              title="This links your address to this name, allowing apps to display this name as your profile when connected to them."
                            />
                          </span>
                          <span>
                            <Checkbox
                              aria-label="checkbox"
                              checked={isChecked}
                              onChange={() => setIsChecked(!isChecked)}
                              color="success"
                              className="h-0 w-0"
                            />
                          </span>
                        </li>
                        <li className="flex items-center justify-between py-1">
                          <span>Total</span>
                          <span className="flex flex-col items-end">
                            <span className=" flex items-center">
                              <img src={chainImg} alt="chain logo" className="  w-auto h-5" />
                              <span className=" font-bold">
                                {price}
                              </span>
                            </span>
                            <span className="text-sm">
                              USD&nbsp;
                              {chainID === 1 ? (
                                <>
                                  {(price * (tokenPrices?.ethereum?.usd || 0)).toFixed(2)}
                                </>
                              ) : (
                                <>
                                  {(price * (tokenPrices?.binancecoin?.usd || 0)).toFixed(2)}
                                </>
                              )}
                            </span>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <button
                        type="button"
                        className=" w-full bg-mainHeading text-mainBg py-1 px-2 rounded-md hover:bg-mainHeading/60 text-xs md:text-sm disabled:bg-mainHeading/30 mt-3"
                        disabled={!available || registering}
                        onClick={handleRegister}
                      >
                        {registering ? (
                          <Loader size="xs" content="Registering..." speed="fast" className=" text-xs m-0 p-0" />
                        ) : (
                          <>
                            Register
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              )}
              {regSuccess && (
              <div className="w-full md:w-[70%] lg:w-[60%] bg-mainBg mt-[5%] rounded-lg">
                <div className="flex flex-col gap-8 items-center">
                  <h4 className="text-2xl md:text-3xl font-bold text-[#1EEFA4]">Registration Successfull!!</h4>
                  <p className="text-center">
                    You Have Successfully registered
                    <span className=" font-bold px-1">
                      {label}
                      <span className="text-[#DEAB0A]">
                        {chainID === 1 ? '.eth' : '.bnb'}
                      </span>
                    </span>
                    Web3 Domain Name for
                    <span className=" font-semibold text-sm md:text-base block text-center">
                      {address}
                    </span>
                  </p>

                  <button
                    type="button"
                    className="bg-mainHeading text-mainBg py-1 px-2 rounded-md hover:bg-mainHeading/60 text-xs md:text-sm disabled:bg-mainHeading/30 mt-3"
                    onClick={() => navigate('/profile')}
                  >
                    Update your Jetpad Account Wallet with web3-Name
                  </button>
                  <p className=" text-sm text-content flex justify-center gap-1">
                    Powered By
                    <span className="text-[#DEAB0A]"> spaceid.bnb</span>
                  </p>
                </div>
              </div>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default SpaceID;
