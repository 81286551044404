import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Auth/auth';
import web3AuthReducer from './Web3Auth/web3Auth';
import web3AirdropReducer from './Web3Airdrop/web3Airdrop';
import firebaseAirdropReducer from './FirebaseAirdrop/firebaseAirdrop';
import tokenDataReducer from './TokenData/tokenData';

const store = configureStore({
  reducer: {
    Auth: authReducer,
    Web3Auth: web3AuthReducer,
    web3Airdrop: web3AirdropReducer,
    firbaseAirdrop: firebaseAirdropReducer,
    tokenData: tokenDataReducer,
  },
});

export default store;
