import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { Helmet } from 'react-helmet';

// Redux
import { resendVerificatonEmail } from '../../Redux/Auth/auth';

// Utils
import { errorToast, successToast } from '../../utils';

const VerifyEmail = () => {
  const email = useSelector((state) => state.Auth?.email);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resendEmail = () => {
    dispatch(resendVerificatonEmail()).then((res) => {
      if (res.error) {
        errorToast(res.payload);
      } else {
        successToast('Email verification link sent');
      }
    });
  };

  const handleClick = async () => {
    const auth = getAuth();
    await signOut(auth);

    return navigate('/signin');
  };

  return (
    <>
      <Helmet>
        <title>JetPad - Verfy-Email</title>
        <meta
          name="description"
          content="Verify your email address"
        />
        <meta property="og:title" content="JetPad - Verfy-Email" />
        <meta
          property="og:description"
          content="Verify your email address"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <section className=" min-h-screen overflow-hidden  flex justify-center bg-mainBg text-content items-center">
        <div className=" my-8 mx-2  md:mx-8 w-full flex flex-col  justify-center items-center">
          <div className=" bg-subtleBg border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-2 md:p-6 flex flex-col items-center shadow-md shadow-accent-1">
            <h2 className=" font-extrabold leading-3 text-3xl mb-10 mt-5">Verify Email</h2>
            <span className=" text-center font-light text-lg md:text-xl mb-10">
              Verification email have been sent to
              the email address below, click on the link to verify your account
            </span>
            <div
              className=" flex flex-col gap-5 w-full items-center"
            >
              <input
                name="email"
                className="auth-input placeholder:text-xs border border-accent-1"
                type="text"
                placeholder="Enter Email address"
                required
                readOnly
                value={email}
              />
              <div className=" w-[90%] flex  justify-end">
                <button
                  type="button"
                  className=" bg-none text-link font-semibold text-lg outline-none hover:text-link/90"
                  onClick={() => resendEmail()}
                >
                  Resend Verification email
                </button>
              </div>
              <button
                type="button"
                onClick={handleClick}
                className="self-center bg-mainHeading text-mainBg px-4 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyEmail;
