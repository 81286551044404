import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FaSquareXTwitter, FaXTwitter } from 'react-icons/fa6';
import { GrTooltip } from 'react-icons/gr';
import { RxOpenInNewWindow } from 'react-icons/rx';
import { MdOutlineFileCopy } from 'react-icons/md';
import { BsTelegram } from 'react-icons/bs';
import { PiTelegramLogo } from 'react-icons/pi';
import { Loader } from 'rsuite';
import Rating from '@mui/material/Rating';
import { Helmet } from 'react-helmet';

// COMPONENTS
import { CountDownTImmer, JoinedPopup, VerifyButton } from '../components';

// REDUX
import {
  checkFollowStatus, checkTelegramMemebership,
  getAirdropDetails, joinAirdrop,
} from '../Redux/FirebaseAirdrop/firebaseAirdrop';
import { updateProjectStat, fetchUserData } from '../Redux/Auth/auth';

// UTILITIES
import {
  JSXToast,
  checkActive,
  checkJoinedStatus, errorToast, formatNumber, getUsernameFromTelegramURL,
  handleCopy,
  isStarted,
  openInNewTab, removeAtSymbol, splitString, successToast,
} from '../utils';

// ASSETS
import {
  website1, excellentGif, goodGif, averageGif, brandMark,
} from '../assets/images';

const AirdropDetails = () => {
  const [airdropDetail, setAirdropDetail] = useState(null);
  const [referralID, setReferralID] = useState(null);
  const [joined, setJoined] = useState(false);
  const [followedTwitter, setFollowedTwitter] = useState(false);
  const [retweetPinned, setRetweetPinned] = useState(false);
  const [joinedChannel, setJoinedChannel] = useState(false);
  const [joinedGroup, setJoinedGroup] = useState(false);
  const [rating, setRating] = useState({});

  // Action Loading States
  const [joiningAirdrop, setJoiningAirdrop] = useState(false);
  const [followingTwitter, setFollowingTwitter] = useState(false);
  const [retweetingPinned, setRetweetingPinned] = useState(false);
  const [joiningChannel, setJoiningChannel] = useState(false);
  const [joiningGroup, setJoiningGroup] = useState(false);

  // CLICKED LINK ACTION
  const [clickedGroup, setClickedGroup] = useState(false);
  const [clickedChannel, setClickedChannel] = useState(false);

  // COMPONENTS STATES
  const [joinedPopActive, setJoinedPopActive] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth);

  const {
    email, userID, BSCAddress, ethereumAddress, polygonAddress, displayName,
  } = user;

  // GET AIRDROP DETAILS WITH ID
  useEffect(() => {
    dispatch(getAirdropDetails(id)).then((res) => {
      if (res.error) {
        errorToast(res.payload);
        navigate('/airdrops');
      } else {
        setAirdropDetail(res.payload);
      }
    });
  }, [id]);

  // HANDLE JOING AN AIRDROP
  const handleJoin = () => {
    if (airdropDetail?.totalClaimed >= Number(airdropDetail?.totalToken)) {
      return errorToast('All Allocated tokens have been claimed!');
    }
    if (!followedTwitter || !retweetPinned || !joinedChannel || !joinedGroup) {
      return errorToast('You have an unverified activity');
    }

    let address = '';
    let network = '';
    if (airdropDetail?.networkValue === 'BSCAddress') {
      address = BSCAddress;
      network = 'BSC';
    } else if (airdropDetail?.networkValue === 'ethereumAddress') {
      address = ethereumAddress;
      network = 'Ethereum';
    } else {
      address = polygonAddress;
      network = 'Polygon';
    }
    if (!address || address === undefined) {
      return JSXToast(
        '/profile', 'Visit Profile', `Please fill in your ${network} address in your profile`,
      );
      // (url, text, message)
    }
    setJoiningAirdrop(true);
    const newParticipant = {
      email,
      userID,
      address,
      displayName,
      joinDate: new Date().toString(),
      airdropAmount: airdropDetail?.airdropAmount,
      referralReward: 0,
      noOfReferral: 0,
      country: user?.locationData?.country || 'Unknown',
    };
    const {
      referralAmount, projectName, networkName, logoLink, ticker,
      endDate, contractAddress, airdropAmount, telegramGroup, estLaunchPrice,
    } = airdropDetail;

    const data = {
      id,
      newParticipant,
      referralID,
      referralAmount,
      userID,
      projectName,
      networkName,
      logoLink,
      endDate,
      contractAddress,
      airdropAmount,
      telegramGroup,
      ticker,
      estLaunchPrice,
    };

    dispatch(joinAirdrop(data)).then((res) => {
      if (res.error) {
        errorToast(res.payload);
        setJoiningAirdrop(false);
      } else {
        successToast('Airdrop Joined Successfully');
        setJoiningAirdrop(false);
        dispatch(updateProjectStat('totalParticipants'));
        setJoinedPopActive(true);
      }
    });
    return {};
  };

  // GETING THE REF ID FROM URL IF ANY
  useEffect(() => {
    const currentURL = window.location.href;

    const url = new URL(currentURL);

    if (url.searchParams.has('ref')) {
      const referrerValue = url.searchParams.get('ref');
      setReferralID(referrerValue);
    }
  }, []);

  // CHECK IF A USER HAS JOINED AN AIRDROP ON PAGE LOAD
  useEffect(() => {
    if (airdropDetail) {
      setJoined(checkJoinedStatus(airdropDetail?.participants, userID));
    }
  }, [airdropDetail, userID]);

  // FUNCTION TO VERIFY IF A USER HAS FOLLOWED TWITTER PAGE
  const verifyTwitterFollow = async () => {
    if (user?.twitterUsername === '') {
      return JSXToast(
        '/profile', 'Visit Profile', 'Please fill in your twitter user Name in your profile',
      );
    }
    setFollowingTwitter(true);
    const data = {
      userUsername: user?.twitterUsername,
      projectUsername: removeAtSymbol(airdropDetail?.twitterPageUserName),
    };
    dispatch(checkFollowStatus(data)).then((res) => {
      if (res.error) {
        setFollowingTwitter(false);
      } else {
        setFollowedTwitter(true);
        setFollowingTwitter(false);
      }
    });
    return {};
  };

  // FUNTION TO CHECK IF A USER HAS FOLLOWED TWITTR PAGE - (TEMPORARY SOLUTION)
  const verifyTwitterFollowTemp = () => {
    if (user?.twitterUsername === '' || user?.twitterUsername === undefined) {
      return JSXToast(
        '/profile', 'Visit Profile', 'Please fill in your twitter user Name in your profile',
      );
    }
    setFollowingTwitter(true);

    setTimeout(() => {
      setFollowedTwitter(true);
      setFollowingTwitter(false);
    }, 4000);

    return {};
  };

  // FUNCTION TO CHECK IF A USER RETWITTED PINNED POST - (TEMPORARY SOLUTION)
  const verifyRetweetPinned = () => {
    if (user?.twitterUsername === '' || user?.twitterUsername === undefined) {
      return JSXToast(
        '/profile', 'Visit Profile', 'Please fill in your twitter user Name in your profile',
      );
    }
    setRetweetingPinned(true);

    setTimeout(() => {
      setRetweetPinned(true);
      setRetweetingPinned(false);
    }, 3000);

    return {};
  };

  // Temporal Solution for group and channel
  const tempverifyJoinedChannel = () => {
    if (user?.telegramID === '' || user?.telegramID === undefined) {
      return JSXToast(
        '/profile', 'Visit Profile', 'Please fill in your Telegram user ID in your profile',
      );
    }

    if (!clickedChannel) {
      return errorToast('Please Click Join Channel and join Telegram Channel');
    }
    setJoiningChannel(true);

    setTimeout(() => {
      setJoinedChannel(true);
      setJoiningChannel(false);
    }, 3000);

    return {};
  };

  const tempverifyJoinedGroup = () => {
    if (user?.telegramID === '' || !user?.telegramID) {
      return JSXToast(
        '/profile', 'Visit Profile', 'Please fill in your Telegram user ID in your profile',
      );
    }

    if (!clickedGroup) {
      return errorToast('Please Click Join Group and join Telegram Group');
    }
    setJoiningGroup(true);

    setTimeout(() => {
      setJoinedGroup(true);
      setJoiningGroup(false);
    }, 4000);

    return {};
  };

  // CHECK IF A USER HAS JOINED TELEGRAM CHANNEL
  const verifyJoinedChannel = () => {
    if (user?.telegramID === '' || user?.telegramID === undefined) {
      return JSXToast(
        '/profile', 'Visit Profile', 'Please fill in your Telegram user ID in your profile',
      );
    }
    setJoiningChannel(true);
    const data = {
      chatId: getUsernameFromTelegramURL(airdropDetail?.telegramChannel),
      userId: user?.telegramID,
    };
    dispatch(checkTelegramMemebership(data)).then((res) => {
      if (res.error) {
        setJoiningChannel(false);
        errorToast(res.payload);
      } else {
        setJoinedChannel(res.payload?.isMember);
        setJoiningChannel(false);
      }
    });
    return {};
  };

  // CHECK IF A USER HAS JOINED TELEGRAM GROUP
  const verifyJoinedGroup = () => {
    if (user?.telegramID === '' || !user?.telegramID) {
      return JSXToast(
        '/profile', 'Visit Profile', 'Please fill in your Telegram user ID in your profile',
      );
    }
    setJoiningGroup(true);
    const data = {
      chatId: getUsernameFromTelegramURL(airdropDetail?.telegramGroup),
      userId: user?.telegramID,
    };
    dispatch(checkTelegramMemebership(data)).then((res) => {
      if (res.error) {
        errorToast('User not found');
        setJoiningGroup(false);
      } else {
        setJoinedGroup(res.payload?.isMember);
        setJoiningGroup(false);
      }
    });
    return {};
  };

  // RANDOMLY GENERATE A RATING FOR THE AIRDROP
  useEffect(() => {
    const getRating = () => {
      const num = Math.floor(Math.random() * (99 - 50 + 1)) + 50;

      if (num <= 70) {
        return {
          score: num,
          grade: 'Average',
          icon: averageGif,
          star: 3,
        };
      } if (num > 70 && num < 90) {
        return {
          score: num,
          grade: 'Good',
          icon: goodGif,
          star: 3,
        };
      }
      return {
        score: num,
        grade: 'Excellent',
        icon: excellentGif,
        star: 5,
      };
    };

    setRating(getRating());
    return () => {
      getRating();
    };
  }, []);

  // HANDLE CONTINUE AND CLOSE POPUP;
  const handleContinue = () => {
    dispatch(fetchUserData(userID));
    setJoinedPopActive(false);
    navigate('/my-airdrops');
  };

  return (
    <>
      {airdropDetail && (
      <Helmet>
        <title>{`JetPad - ${airdropDetail?.projectName} Airdrop`}</title>
        <meta
          name="description"
          content={
            `Claim ${airdropDetail?.airdropAmount} Worth of ${airdropDetail?.projectName} Token For free Join Now`
          }
        />
        <meta property="og:title" content={`JetPad - ${airdropDetail?.projectName} Airdrop`} />
        <meta
          property="og:description"
          content={
            `Claim ${airdropDetail?.airdropAmount} Worth of ${airdropDetail?.projectName} Token For free Join Now`
          }
        />
        <meta property="og:image" content={airdropDetail?.logoLink || brandMark} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      )}
      <section className=" flex justify-center bg-mainBg text-content h-full md:min-h-screen w-full px-0 md:px-[2.5%] lg:px-[5%] ">
        <div className="w-full ">
          <AiOutlineArrowLeft
            className="mt-3 ml-3 text-content cursor-pointer hover:text-content/90 font-bold text-2xl"
            onClick={() => navigate('/airdrops')}
          />
          <div className=" grid gap-6 grid-cols-1 md:grid-cols-9">
            <div className=" md:col-span-6">
              <div className=" bg-gradient-to-r from-accent-1 via-accent-2/60 to-accent-1 text-content flex flex-col items-center mt-[15%] rounded-t-3xl ">
                <div className=" border-2 border-accent-1 ounded-3xl h-[150px] w-[150px] translate-y-[-50%] flex justify-center items-center bg-subtleBg rounded-3xl">
                  <img src={airdropDetail?.logoLink || brandMark} alt="token logo" className="rounded-3xl" />
                </div>
                <div className="translate-y-[-25%] flex flex-col items-center w-full">
                  <h4 className=" font-black text-2xl font-serif">{airdropDetail?.projectName}</h4>
                  <div className="mt-4 w-full flex flex-col justify-center items-center gap-6">
                    <div className="flex items-start justify-center gap-6 w-full">
                      <div className="flex flex-col font-bold items-center">
                        Amount
                        <span className=" text-2xl italic font-black">
                          {airdropDetail?.airdropAmount}
                          <span className=" text-xs italic font-black">{airdropDetail?.ticker}</span>
                        </span>
                      </div>
                      <div className="flex flex-col font-bold items-center">
                        Total Entries
                        <span className=" text-2xl italic font-black ">{airdropDetail?.noOfPart}</span>
                      </div>
                    </div>
                    <div className="flex flex-col w-full items-center font-semibold">
                      {airdropDetail !== null && isStarted(airdropDetail?.startDate) ? (
                        <>
                          Airdrop Ends in
                          <div className="w-[80%]">
                            <CountDownTImmer targetDate={airdropDetail?.endDate || '2023-11-12T12:00:00'} />
                          </div>
                        </>
                      ) : (

                        <>
                          Airdrop Starts in
                          <div className="w-[80%]">
                            <CountDownTImmer targetDate={airdropDetail?.startDate || '2023-11-12T12:00:00'} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="translate-y-[-5%] flex flex-col items-center w-full bg-subtleBg rounded-t-3xl p-3 border border-accent-1">
                <div className=" m-2 p-1 rounded-lg self-center w-[70%] flex gap-2 items-center border border-notice bg-notice text-subText">
                  <GrTooltip
                    className="h-[65px] w-[75px] text-content"
                  />
                  <span className="text-sm font-semibold">
                    Please ensure you&rsquo;ve clicked on each link, joined the group
                    or followed the account, and then click &rsquo;Verify&rsquo; to confirm.
                  </span>
                </div>
                <div className={checkActive(airdropDetail?.startDate, airdropDetail?.endDate) || !user?.isLoggedIn ? 'mt-4 w-full blur-sm pointer-events-none' : 'mt-4 w-full'}>
                  <h3 className="font-extrabold text-3xl my-3">Activities</h3>
                  <div className={joined ? 'flex flex-col w-full gap-3 blur-sm pointer-events-none' : 'flex flex-col w-full gap-3'}>
                    <div className="flex justify-between items-center bg-mainBg border border-accent-1 rounded-lg p-2">
                      <BsTelegram
                        className="text-blue-500 h-[35px] w-auto"
                      />
                      <button
                        type="button"
                        className="flex gap-1 items-center justify-center font-semibold text-link hover:text-link/80"
                        onClick={() => {
                          setClickedChannel(true);
                          openInNewTab(airdropDetail?.telegramChannel);
                        }}
                      >
                        Join Telegram Channel
                        <RxOpenInNewWindow />
                      </button>
                      <div className="flex flex-col gap-2">
                        {joinedChannel ? (
                          <span
                            className="bg-green-300 border border-green-700 px-2 rounded-xl text-subText text-xs"
                          >
                            Verified
                          </span>
                        ) : (
                          <>
                            {joiningChannel ? <Loader size="sm" content="Verifing..." speed="fast" /> : (
                              <>
                                <span className="hidden">
                                  <VerifyButton handleVerify={verifyJoinedChannel} />
                                </span>
                                <VerifyButton handleVerify={tempverifyJoinedChannel} />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between items-center bg-mainBg border border-accent-1 rounded-lg p-2">
                      <BsTelegram
                        className="text-blue-500 h-[35px] w-auto"
                      />
                      <button
                        type="button"
                        className="flex gap-1 items-center justify-center font-semibold text-link hover:text-link/80"
                        onClick={() => {
                          setClickedGroup(true);
                          openInNewTab(airdropDetail?.telegramGroup);
                        }}
                      >
                        Join Telegram Group
                        <RxOpenInNewWindow />
                      </button>
                      <div className="flex flex-col gap-2">
                        {joinedGroup ? (
                          <span
                            className="bg-green-300 border border-green-700 px-2 rounded-xl italic text-xs"
                          >
                            Verified
                          </span>
                        ) : (
                          <>
                            {joiningGroup ? <Loader size="sm" content="Verifing..." speed="fast" /> : (
                              <>
                                <span className="hidden">
                                  <VerifyButton handleVerify={verifyJoinedGroup} />
                                </span>
                                <VerifyButton handleVerify={tempverifyJoinedGroup} />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between items-center bg-mainBg border border-accent-1 rounded-lg p-2">
                      <FaSquareXTwitter
                        className="h-[35px] w-auto text-content"
                      />
                      <button
                        type="button"
                        className="flex gap-1 items-center justify-center font-semibold text-link hover:text-link/80"
                        onClick={() => {
                          const uName = removeAtSymbol(airdropDetail?.twitterPageUserName);
                          const link = `https://twitter.com/intent/follow?screen_name=${uName}&original_referer=https://jetpad.finance`;
                          openInNewTab(link);
                        }}
                      >
                        Follow X account
                        <RxOpenInNewWindow />
                      </button>
                      <div className="flex flex-col gap-2">
                        {followedTwitter ? (
                          <span
                            className="bg-green-300 border border-green-700 px-2 rounded-xl italic text-xs"
                          >
                            Verified
                          </span>
                        ) : (
                          <>
                            {followingTwitter ? <Loader size="sm" content="Verifing..." speed="fast" /> : (
                              <VerifyButton handleVerify={verifyTwitterFollowTemp} />
                            )}
                            <span className=" hidden">
                              {followingTwitter ? <Loader size="sm" content="Verifing..." speed="fast" /> : (
                                <VerifyButton handleVerify={verifyTwitterFollow} />
                              )}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between items-center bg-mainBg border border-accent-1 rounded-lg p-2">
                      <FaSquareXTwitter
                        className="h-[35px] w-auto text-content"
                      />
                      <button
                        type="button"
                        className="flex gap-1 items-center justify-center font-semibold text-link hover:text-link/80"
                        onClick={() => openInNewTab(airdropDetail?.twitterPinnedPost)}
                      >
                        Retweet Pinned Post
                        <RxOpenInNewWindow />
                      </button>
                      <div className="flex flex-col gap-2">
                        {retweetPinned ? (
                          <span
                            className="bg-green-300 border border-green-700 px-2 rounded-xl italic text-xs"
                          >
                            Verified
                          </span>
                        ) : (
                          <>
                            {retweetingPinned ? <Loader size="sm" content="Verifing..." speed="fast" /> : (
                              <VerifyButton handleVerify={verifyRetweetPinned} />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" my-4 flex justify-center items-center w-[70%] bg-green-300 border border-green-700 rounded-lg p-2">
                  <span className="text-xs  font-semibold text-subText">
                    Please remember to stay following and remain in
                    the group even after the airdrop is complete. Thank you!
                  </span>
                </div>
                {user?.isLoggedIn
                  ? (
                    <>
                      {joined ? (
                        <div className="flex flex-col gap-2 w-full items-center">
                          <span className="text-sm font-light text-green-700">You have already Joined this Airdrop</span>
                          <button
                            type="button"
                            className="self-center  bg-mainHeading hover:bg-mainHeading/50 px-10 py-2 rounded-xl text-lg font-bold mb-2 text-white"
                            onClick={() => navigate('/my-airdrops')}
                          >
                            Visit Profile
                          </button>
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="self-center bg-[#11cc00] px-10 py-2 rounded-xl hover:bg-[#11cc00cc] text-lg font-bold mb-2 text-white disabled:bg-gray-500"
                          onClick={() => handleJoin()}
                          disabled={checkActive(airdropDetail?.startDate, airdropDetail?.endDate)}
                        >
                          {isStarted(airdropDetail?.startDate) ? (
                            <>
                              {
                            checkActive(airdropDetail?.startDate, airdropDetail?.endDate) ? 'Airdrop Ended'
                              : (
                                <span>
                                  {joiningAirdrop ? (
                                    <>
                                      <Loader size="sm" content="Joining..." speed="fast" />
                                    </>
                                  ) : 'Complete Airdrop'}
                                </span>
                              )
                          }
                            </>
                          ) : (
                            <span>Airdrop Not Started</span>
                          )}
                        </button>
                      )}
                    </>
                  ) : (
                    <div className="flex flex-col gap-2 w-full items-center">
                      <span className="text-sm font-light text-red-700">You are not logged in!. You need to be logged-in to join the airdrop</span>
                      <button
                        type="button"
                        className="self-center  bg-mainHeading hover:bg-mainHeading/50 px-10 py-2 rounded-xl text-lg font-bold mb-2 text-white"
                        onClick={() => navigate('/signin')}
                      >
                        Login
                      </button>
                    </div>
                  )}
              </div>
            </div>
            <div className="  rounded-md md:col-span-3 flex flex-col items-center md:mt-[30%] mb-[2%] mx-2 md:mx-0">
              <div className="border border-accent-1 w-full rounded-lg p-2 bg-subtleBg text-content flex flex-col items-center shadow-md shadow-accent-1">
                <h4 className="border border-accent-1 w-[70%] text-2xl font-semibold text-center py-1 rounded-md bg-mainBg shadow-md shadow-accent-1">
                  Token Data
                </h4>
                <ul className="w-full mt-4 flex flex-col gap-2">
                  <li className="w-full flex justify-between">
                    <span className=" font-bold">Name:</span>
                    <span className=" text-subText">{airdropDetail?.tokenName}</span>
                  </li>
                  <li className="w-full flex justify-between">
                    <span className=" font-bold">Ticker:</span>
                    <span className=" text-subText">{airdropDetail?.ticker}</span>
                  </li>
                  <li className="w-full flex justify-between">
                    <span className=" font-bold">Total Supply:</span>
                    <span className=" text-subText">{formatNumber(airdropDetail?.totalSupply || 0)}</span>
                  </li>
                  <li className="w-full flex justify-between">
                    <span className=" font-bold">Contract:</span>
                    <span className=" text-subText flex gap-1 items-center">
                      {splitString(airdropDetail?.contractAddress || '0x', 4, 38)}
                      <MdOutlineFileCopy
                        className="hover:cursor-pointer hover:text-link"
                        onClick={() => {
                          handleCopy(airdropDetail?.contractAddress);
                          successToast('Contract Address copied to clipboard');
                        }}
                      />
                    </span>
                  </li>
                </ul>
              </div>
              <div className="border border-accent-1 w-full my-4 flex flex-col items-center bg-subtleBg shadow-md shadow-accent-1 p-2 rounded-md text-content">
                <h4 className="border border-accent-1 w-[70%] text-2xl font-semibold text-center py-1 rounded-lg bg-mainBg shadow-md shadow-accent-1">
                  Airdrop Details
                </h4>
                <p className=" text-subText text-sm mt-4">{airdropDetail?.airdropDetails}</p>
              </div>
              <div className="border border-accent-1 w-full my-4 flex flex-col items-center bg-subtleBg shadow-md shadow-accent-1 p-2 rounded-md text-content ">
                <h4 className="border border-accent-1 w-[70%] text-2xl font-semibold text-center py-1 rounded-md bg-mainBg shadow-md shadow-accent-1">
                  About Project
                </h4>
                <p className=" text-subText text-sm mt-4">{airdropDetail?.projectDetails}</p>
              </div>
              <div className="w-full my-4 flex flex-col items-center bg-subtleBg shadow-md shadow-accent-1 p-2 rounded-lg">
                <h4 className="border border-accent-1 w-[70%] text-2xl font-semibold text-center py-1 rounded-md bg-mainBg shadow-md shadow-accent-1">
                  Links/Socials
                </h4>
                <div className="flex gap-2 my-4">
                  <a
                    href={airdropDetail?.projectWebsite}
                    rel="noopener noreferrer"
                    target="_blank"
                    className=" h-[32px] w-[32px]  hover:bg-black text-white flex justify-center items-center rounded-lg"
                    title="Official Website"
                  >
                    <img
                      src={website1}
                      alt="explorer"
                      className="h-[30px] w-[30px] rounded-full"
                    />
                  </a>
                  <a
                    href={airdropDetail?.telegramGroup}
                    rel="noopener noreferrer"
                    target="_blank"
                    className=" h-[32px] w-[32px]  hover:bg-black text-blue-500 bg-white flex justify-center items-center rounded-lg"
                    title="Official Telegram"
                  >
                    <PiTelegramLogo
                      className="h-[30px] w-[30px] rounded-full"
                    />
                    <span className="sr-only">Visit our Telegram Group</span>
                  </a>
                  <a
                    href={airdropDetail?.twitterPageLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    className=" h-[32px] w-[32px] text-black bg-white  flex justify-center items-center rounded-lg"
                    title="Official Twitter"
                  >
                    <FaXTwitter
                      className="h-[30px] w-[30px] rounded-full"
                    />
                    <span className="sr-only">Visit our Twitter Page</span>
                  </a>
                </div>
              </div>
              <div className="w-full my-4 flex flex-col items-center bg-subtleBg shadow-md shadow-accent-1 p-2 rounded-lg">
                <h4 className="border border-accent-1 w-[70%] text-2xl font-semibold text-center py-1 rounded-md shadow-md shadow-accent-1">
                  Trust Score / Ratings
                </h4>
                <div className="flex flex-col mt-4 ">
                  <p className="flex gap-1 items-center justify-center">
                    <img src={rating?.icon} alt="flame" className=" h-[44px] w-[24px] " />
                    <span className="text-2xl font-bold">
                      {rating?.grade}
                    </span>
                    <span className="text-4xl font-semibold font-sans text-mainHeading">
                      {`${rating?.score}+`}
                    </span>
                  </p>
                  <div className="w-full flex justify-start">
                    <Rating
                      name="half-rating-read"
                      defaultValue={4}
                      precision={0.5}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {joinedPopActive && (
        <JoinedPopup
          handleContinue={handleContinue}
          projectName={airdropDetail?.projectName}
          tokenName={airdropDetail?.tokenName}
          airdropAmount={airdropDetail?.airdropAmount}
          ticker={airdropDetail?.ticker}
          estLaunchPrice={airdropDetail?.estLaunchPrice}
          referralAmount={airdropDetail?.referralAmount}
          userID={userID}
          id={id}
        />
        )}
      </section>
    </>
  );
};
export default AirdropDetails;
