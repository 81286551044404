import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// UTILITIES
import { calcDays, openInNewTab } from '../utils';

// Assets
import {
  ETHLogo, BSCLogo, POLYGONLogo, brandMark,
} from '../assets/images';

const AirdropCard = ({ airdrop, showAirdropDetails }) => {
  const [borderColor, setBorderColor] = useState();
  const [networkLogo, setNetworkLogo] = useState(brandMark);
  const {
    logoLink, projectName, airdropDetails, endDate, id, startDate, airdropAmount, estLaunchPrice,
    networkValue,
  } = airdrop;

  useEffect(() => {
    if (networkValue === 'BSCAddress') {
      setBorderColor('border-[#0056CC]');
      setNetworkLogo(BSCLogo);
    } else if (networkValue === 'ethereumAddress') {
      setBorderColor('border-[#0784C3]');
      setNetworkLogo(ETHLogo);
    } else {
      setBorderColor('border-[#7342DC]');
      setNetworkLogo(POLYGONLogo);
    }
  }, []);

  const isMobile = () => Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf('Mobi') > -1;
  return (
    <div className={`grid grid-cols-7 gap-3 items-center bg-mainBg text-content border ${borderColor} rounded-lg p-2 pt-4 my-1 w-full relative`}>
      <div className={`
        absolute bottom-[89%] md:bottom-[90%] left-1/2 transform -translate-x-1/2 flex w-8 h-8] bg-mainBg px-2 justify-center items-center py-1 rotate-45 rounded-md overflow-hidden border
        ${borderColor}
        `}
      >
        <img src={networkLogo} alt="network logo" className=" -rotate-45 w-auto h-[21px]" />
      </div>
      <img
        src={logoLink}
        alt={projectName}
        className="h-[38px] w-[38px] rounded-full"
      />
      <div className="col-span-6 flex flex-col">
        <div className="grid grid-cols-3 place-items-start gap-2">
          <div className=" col-span-2">
            <h3 className="font-bold text-base md:text-2xl">
              {projectName?.length > 20 ? `${projectName?.slice(0, 20)} ...` : projectName}
            </h3>
            <p className=" font-light  overflow-visible whitespace-normal">{isMobile() ? `${airdropDetails?.slice(0, 58)} ...` : `${airdropDetails?.slice(0, 60)} ...`}</p>
          </div>
          <div className="flex flex-col">
            <span className={calcDays(startDate, endDate)?.style}>
              {calcDays(startDate, endDate)?.text}
            </span>
            {airdrop?.distributed && (
              <span className="text-subText font-semibold">
                Distributed
                <span className="text-[#21BF96] text-lg pr-2">&#10003;</span>
              </span>
            )}
          </div>
        </div>
        <span className=" text-center mt-1 font-bold text-subText">
          {`$${((Number(estLaunchPrice) || 0) * Number(airdropAmount))?.toFixed(0)} worth of ${projectName}`}
        </span>
      </div>
      <div className=" w-full col-span-7 flex gap-3">
        <button
          type="button"
          className="bg-mainHeading/80 border-mainHeading font-light text-sm md:font-semibold border px-2 py-1 rounded-lg hover:bg-mainHeading col-span-7 w-full text-white"
          onClick={() => showAirdropDetails(id)}
        >
          Join Airdrop
        </button>
        {airdrop?.distributed && (
        <button
          type="button"
          className="bg-mainHeading/80 border-mainHeading font-light text-sm md:font-semibold border px-2 py-1 rounded-lg hover:bg-mainHeading col-span-7 w-full text-white"
          onClick={() => openInNewTab(airdrop?.explorerLink)}
        >
          View Tnx.
        </button>
        )}
      </div>
    </div>
  );
};

AirdropCard.propTypes = {
  airdrop: PropTypes.oneOfType([PropTypes.object]).isRequired,
  showAirdropDetails: PropTypes.func.isRequired,
};
export default AirdropCard;
