import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// UTILITIES
import {
  calcDays, handleCopy, openInNewTab, successToast,
} from '../utils';

const JoinedCard = ({ airdrop, userID }) => {
  const navigate = useNavigate();
  const URL = window.location.origin;
  const refLink = `${URL}/airdrop/${airdrop.airdropID}?ref=${userID}`;

  const refText = `🎁 Join ${airdrop.projectName} Airdrop and get free
  ${airdrop.airdropAmount} ${airdrop.ticker} tokens worth
  $${((Number(airdrop.estLaunchPrice) || 0.01) * Number(airdrop.airdropAmount))?.toFixed(0)}.
  Act fast before the airdrop ends!
  ${refLink}`;

  return (
    <div className="bg-mainBg text-content border border-accent-1 p-3 rounded-lg flex flex-col gap-3">
      <div className="flex justify-between items-center w-full">
        <div className="flex flex-col gap-1 overflow-hidden whitespace-nowrap">
          <div className="flex gap-1 items-center">
            <img
              src={airdrop.logoLink}
              alt="coin logo"
              className="h-[38px] w-[38px] rounded-full"
            />
            <span className=" font-semi-bold text-3xl truncate overflow-hidden whitespace-nowrap">{airdrop.projectName}</span>
          </div>
          <span className=" font-barlow text-sm text-subHeading">{airdrop.networkName}</span>
        </div>
        <span className={calcDays('2023-10-01', airdrop.endDate)?.style}>
          {calcDays('2023-10-01', airdrop.endDate)?.text}
        </span>
      </div>
      <div className="w-full grid grid-cols-3 place-content-center place-items-center">
        <div className="flex flex-col gap-1 place-self-start">
          <span className=" font-bold text-subHeading text-sm md:text-base">Airdrop Bal</span>
          <span className="  text-subText font-semibold text-xs md:text-sm">
            {`${airdrop.airdropAmount} ${airdrop.ticker}`}
          </span>
        </div>
        <div className="flex flex-col gap-1 items-center">
          <span className=" font-bold text-subHeading text-sm md:text-base ">Ref Count</span>
          <span className="  text-subText font-semibold text-xs md:text-sm">{airdrop.refCount}</span>
        </div>
        <div className="flex flex-col gap-1 place-self-end">
          <span className=" font-bold text-subHeading text-sm md:text-base">Referral Bal</span>
          <span className="  text-subText font-semibold text-xs md:text-sm">
            {`${airdrop.refBalance} ${airdrop.ticker}`}
          </span>
        </div>
      </div>
      <div className="grid grid-cols-3 my-4 gap-3">
        <button
          type="button"
          className=" px-1 md:px-2 py-1 md:py-2 border border-mainHeading rounded-md hover:bg-mainHeading hover:text-white"
          onClick={() => navigate(`/airdrop/${airdrop.airdropID}`)}
        >
          View Airdrop
        </button>
        <button
          type="button"
          className=" px-1 md:px-2 py-1 md:py-2 border border-mainHeading rounded-md hover:bg-mainHeading hover:text-white"
          onClick={() => {
            if (handleCopy(refText)) {
              successToast('Referral Text Copied');
            }
          }}
        >
          Ref Text
        </button>
        <button
          type="button"
          className=" px-1 md:px-2 py-1 md:py-2 border border-mainHeading rounded-md hover:bg-mainHeading hover:text-white"
          onClick={() => openInNewTab(
            `https://twitter.com/intent/tweet?text=${refText}`,
          )}
        >
          Tweet
        </button>
      </div>
    </div>
  );
};

JoinedCard.propTypes = {
  userID: PropTypes.string.isRequired,
  airdrop: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default JoinedCard;
