import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { Loader } from 'rsuite';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { blue } from '@mui/material/colors';
import { Helmet } from 'react-helmet';

// ICONS
import { AiOutlineEye } from 'react-icons/ai';

// Redux
import {
  createAccount,
  getLocationInfo,
  handleReferral,
  isUsernameAvailable,
  updateProjectStat,
} from '../../Redux/Auth/auth';

// Utils
import { successToast, errorToast, isValidUsername } from '../../utils';

const SignUp = () => {
  const [userData, setUserDate] = useState({
    email: '',
    password: '',
    passwordConfirmation: '',
    displayName: '',
    userName: '',
    referrer: '',
    userType: '',
  });
  const [boxChecked, setBoxChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [userNameErrorMSG, setUserNameErrorMSG] = useState('');
  const [userNamesuccessMSG, setUserNamesuccessMSG] = useState('');
  const [loading, setLoading] = useState(false);
  const [checkingUserName, setCheckingUserName] = useState(false);
  const [validUserName, setValidUserName] = useState(false);
  const [referrerValue, setReferrerValue] = useState('');

  const isLoggedIn = useSelector((state) => state.Auth?.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputErrors = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(userData.email)) {
      setEmailError(true);
      errorToast('Enter a valid email address');
      return true;
    }
    if (userData.password.length < 8) {
      setPasswordLengthError(true);
      errorToast('Password is too short');
      return true;
    }
    if (userData.password !== userData.passwordConfirmation) {
      setConfirmPasswordError(true);
      errorToast('Password and Password comfirmation does not match');
      return true;
    }
    if (!userData.userType) {
      errorToast('You have not selected a user type');
      return true;
    }

    return false;
  };

  const handleChange = (e) => {
    setUserDate({ ...userData, [e.target.name]: e.target.value });
  };

  const handleUserName = (e) => {
    setUserNameError(false);
    setUserNameErrorMSG('');
    setUserNamesuccessMSG('');
    setValidUserName(false);
    const usernameValue = e.target.value?.trim()?.toLowerCase();

    setUserDate({ ...userData, userName: usernameValue });

    if (usernameValue.length < 3) {
      setUserNameErrorMSG('User-name Must Have more than 3 characters');
      return setUserNameError(true);
    }
    if (!isValidUsername(usernameValue)) {
      setUserNameErrorMSG(
        "Your username can only contain lowercase letters, numbers and '_'",
      );
      return setUserNameError(true);
    }

    // check username availability
    setCheckingUserName(true);
    dispatch(isUsernameAvailable(usernameValue)).then((res) => {
      if (res.error) {
        errorToast(`Something Went Wrong - ${res?.payload}`);
        setCheckingUserName(false);
        setValidUserName(false);
      } else if (res.payload === false) {
        setUserNamesuccessMSG('User-name is Available');
        setUserNameErrorMSG('');
        setUserNameError(false);
        setCheckingUserName(false);
        setValidUserName(true);
      } else {
        setUserNameErrorMSG('User-name is taken, try-again');
        setUserNameError(true);
        setCheckingUserName(false);
        setValidUserName(false);
      }
    });
    return true;
  };

  // FUNCTION THAT CREATES USER'S ACCOUNT
  const createUserAccount = (locationData) => {
    dispatch(createAccount({ ...userData, locationData })).then((res) => {
      if (res.error) {
        errorToast(res.payload);
        setLoading(false);
      } else {
        successToast('Account created successfuly');
        setLoading(false);

        if (
          userData?.referrer.trim()
          && userData?.userName.toLowerCase() !== userData?.referrer.toLowerCase()
        ) {
          dispatch(handleReferral(userData?.referrer?.toLowerCase()));
        }
        navigate('/verify-email');
      }
    });
  };

  // FORM SUBMIT FUNCTION
  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailError(false);
    setPasswordLengthError(false);
    setConfirmPasswordError(false);
    setLoading(true);
    if (handleInputErrors()) {
      setLoading(false);
      return {};
    }
    if (!boxChecked) {
      setLoading(false);
      return errorToast('You have to agree to the Terms and Conditions');
    }
    if (userData?.userName?.trim().length < 3 || !validUserName) {
      setLoading(false);
      return errorToast('Your Username is not Valid');
    }

    if (
      !emailError
      && !passwordLengthError
      && !confirmPasswordError
      && boxChecked
      && validUserName
    ) {
      let locationData = {
        country: '',
        IP: '',
        city: '',
      };

      dispatch(updateProjectStat('totalUsers'));
      dispatch(getLocationInfo()).then((res) => {
        if (res.error) {
          createUserAccount(locationData);
        } else {
          locationData = {
            ...locationData,
            country: res.payload.country,
            IP: res.payload.ip,
            city: res.payload.city,
          };
          createUserAccount(locationData);
        }
      });
    }
    return true;
  };

  // GETING THE REF ID FROM URL IF ANY
  useEffect(() => {
    const currentURL = window.location.href;

    const url = new URL(currentURL);

    if (url.searchParams.has('ref')) {
      const ref = url.searchParams.get('ref');
      setUserDate({ ...userData, referrer: ref });
      setReferrerValue(ref);
    }
  }, []);

  if (isLoggedIn) {
    return <Navigate to="/profile" />;
  }

  return (
    <>
      <Helmet>
        <title>JetPad - SignUp</title>
        <meta name="description" content="Create Account on Jetpad" />
        <meta property="og:title" content="JetPad - SignUp" />
        <meta property="og:description" content="Create Account on Jetpad" />
        <meta
          property="og:image"
          content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      <section className="  min-h-screen overflow-hidden  flex justify-center bg-mainBg text-content items-center">
        <div className=" my-8 mx-2  md:mx-8 w-full flex flex-col  justify-center items-center">
          <div className=" bg-subtleBg border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-2 md:p-6 flex flex-col items-center">
            <h2 className=" font-extrabold leading-3 text-3xl mb-10 mt-5">
              Sign-Up
            </h2>
            <form
              onSubmit={handleSubmit}
              className=" flex flex-col gap-3 w-full items-center"
            >
              <div className="grid grid-cols-2 gap-2 w-full">
                <div className="flex w-full justify-center flex-col items-start ml-[10%]">
                  <input
                    name=""
                    className={`auth-input placeholder:text-xs border border-accent-1 ${
                      userNameError && ' border-red-500'
                    }`}
                    type="text"
                    placeholder="Enter User-name"
                    value={userData.userName}
                    onChange={(e) => handleUserName(e)}
                    required
                  />
                  {/* <span className="text-xs italic text-red-500"> */}
                  {checkingUserName ? (
                    <>
                      <Loader
                        size="xs"
                        content="checking..."
                        speed="fast"
                        className=" text-darkerHeading font-bold mt-1"
                      />
                    </>
                  ) : (
                    <>
                      {userNameError ? (
                        <span className="text-xs md:text-sm font-light text-red-500">
                          {userNameErrorMSG}
                        </span>
                      ) : (
                        <span className="text-xs md:text-sm font-light text-green-500">
                          {userNamesuccessMSG}
                        </span>
                      )}
                    </>
                  )}
                  {/* </span> */}
                </div>
                <input
                  name="displayName"
                  className="auth-input border border-accent-1 placeholder:text-xs h-fit"
                  type="text"
                  placeholder="Enter Display name"
                  value={userData.displayName}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
              <input
                name="email"
                className={`auth-input placeholder:text-xs border border-accent-1 ${
                  emailError && ' border-red-500'
                }`}
                type="email"
                placeholder="Enter Email address"
                value={userData.email}
                onChange={(e) => handleChange(e)}
                required
              />
              <div
                className={`auth-pass-div border border-accent-1 ${
                  passwordLengthError && ' border-red-500'
                }`}
              >
                <input
                  name="password"
                  className="auth-pass placeholder:text-xs"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your password"
                  value={userData.password}
                  onChange={(e) => handleChange(e)}
                  required
                />
                <AiOutlineEye
                  className="hover:cursor-pointer text-content hover:text-accent-1 "
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              <div
                className={`auth-pass-div border border-accent-1 ${
                  confirmPasswordError && ' border-red-500'
                }`}
              >
                <input
                  name="passwordConfirmation"
                  className="auth-pass placeholder:text-xs"
                  type={showConPassword ? 'text' : 'password'}
                  placeholder="Confrim your password"
                  value={userData.passwordConfirmation}
                  onChange={(e) => handleChange(e)}
                  required
                />
                <AiOutlineEye
                  className="hover:cursor-pointer text-content hover:text-accent-1"
                  onClick={() => setShowConPassword(!showConPassword)}
                />
              </div>
              <div className="flex w-full justify-center flex-col items-start ml-[10%] mt-4">
                <span className="text-xs md:text-sm font-light pl-2 text-content">
                  {referrerValue && 'Referrer'}
                </span>
                <input
                  name="referrer"
                  className="auth-input placeholder:text-xs border border-accent-1 disabled:bg-slate-800 disabled:border-gray-500 disabled:text-gray-400"
                  type="text"
                  placeholder="Referrer's user-name (Optional)"
                  value={userData.referrer}
                  onChange={(e) => handleChange(e)}
                  disabled={referrerValue}
                />
              </div>
              <div className="w-full ml-[10%]">
                <FormControl className="w-[90%]">
                  <FormLabel id="user type">
                    <span className="text-content font-light">
                      Are You a :-
                    </span>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="user type"
                    name="userType"
                    value={userData.userType}
                    onChange={handleChange}
                  >
                    <div className="flex flex-row shrink-0 gap-2 w-full">
                      <span className="flex flex-col  border border-accent-1 p-1 text-xs rounded-md text-subText w-full">
                        <FormControlLabel
                          value="Project Owner"
                          control={(
                            <Radio
                              sx={{
                                color: blue[800],
                                '&.Mui-checked': { color: blue[600] },
                              }}
                            />
                          )}
                          label="Project Owner"
                          className=""
                        />
                        I own/manage a crypto project
                      </span>
                      <span className="flex flex-col  border border-accent-1 p-1 text-xs rounded-md text-subText w-full">
                        <FormControlLabel
                          value="Airdrop Hunter"
                          control={(
                            <Radio
                              sx={{
                                color: blue[800],
                                '&.Mui-checked': { color: blue[600] },
                              }}
                            />
                          )}
                          label="Airdrop Hunter"
                        />
                        I&apos;m an airdrop/crypto enthusiast
                      </span>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
              <label
                htmlFor="tc"
                className=" flex justify-start w-[90%] text-xs font-light md:text-base items-center"
              >
                <input
                  type="checkbox"
                  checked={boxChecked}
                  name="tc"
                  id="tc"
                  className=""
                  onChange={() => setBoxChecked(!boxChecked)}
                />
                &nbsp;I agree to the
                <a
                  href="https://docs.jetpad.finance/terms-and-conditions"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="after:content-['_↗'] hover:text-link/90 cursor-pointer font-light text-link my-3"
                  title="Advert"
                >
                  &nbsp;terms and conditions
                </a>
              </label>
              <button
                type="submit"
                className="self-center bg-mainHeading text-mainBg px-4 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
              >
                {loading ? (
                  <>
                    <Loader size="sm" content="Creating..." speed="fast" />
                  </>
                ) : (
                  'Create Account'
                )}
              </button>
              <div className="flex my-5 font-light items-center">
                Already have an Account?&nbsp;
                <button
                  type="button"
                  className=" bg-none text-link font-semibold text-lg outline-none hover:text-link/90"
                  onClick={() => navigate('/signin')}
                >
                  Sign-In
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
