import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IoIosSearch } from 'react-icons/io';
import { Loader } from 'rsuite';

// UTILITIES
import {
  endsWithSupportedDomain, errorToast, isValidEthereumAddress, openInNewTab,
} from '../utils';
import { getWeb3NameMetadata, resolveWeb3Address } from '../Redux/Web3Auth/web3Auth';

const NameLookUp = () => {
  const [inputValue, setInputValue] = useState('');
  const [searching, setSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [searchedName, setSearchedName] = useState(false);
  const [searchResult, setSearchResult] = useState(null);

  const dispatch = useDispatch();

  const handleLookUp = () => {
    setSearchResult(null);
    setSearching(true);
    setSearched(false);
    if (isValidEthereumAddress(inputValue)) {
      setSearchedName(inputValue);
      const data = {
        address: inputValue,
        chainId: [56, 1, 10200, 42161],
      };
      dispatch(resolveWeb3Address(data)).then((res) => {
        if (res.error) {
          errorToast(res.payload);
        } else if (!res.error) {
          if (endsWithSupportedDomain(res.payload)) {
            dispatch(getWeb3NameMetadata(res.payload)).then((res) => {
              if (res.error) {
                errorToast(res.payload);
              } else {
                setSearchResult(res.payload);
              }
              setSearched(true);
              setSearching(false);
              setInputValue('');
            });
          } else {
            setSearchResult(res.payload);
            setSearched(true);
            setSearching(false);
            setInputValue('');
          }
        }
      });
    } else if (endsWithSupportedDomain(inputValue)) {
      setSearchedName(inputValue);
      dispatch(getWeb3NameMetadata(inputValue)).then((res) => {
        if (res.error) {
          errorToast(res.payload);
        } else {
          setSearchResult(res.payload);
        }
        setSearched(true);
        setSearching(false);
        setInputValue('');
      });
    } else {
      errorToast('Enter a valid address or Domain name');
      setSearching(false);
    }
  };

  const formatDate = (value, dataType) => {
    if (dataType !== 'date') {
      return value;
    }

    let timestamp = value;
    if (timestamp.toString()?.length <= 10) {
      timestamp = value * 1000;
    }

    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');

    return `${year}.${month}.${day} at ${hour}:${minute}`;
  };

  return (
    <div className=" mt-[7%] md:mt-[5%] flex flex-col gap-2 items-center w-full">
      <div className="flex flex-col gap-1 items-center">
        <div>
          <h3 className=" font-bold text-3xl text-content text-center">
            Web3 Domain Name Look-Up
          </h3>
          <p className=" text-sm text-content flex justify-center gap-1">
            Powered By
            <span className="text-[#DEAB0A]"> spaceid.bnb</span>
          </p>
        </div>
        <p className="text-xs md:text-sm text-center py-1 text-subText w-full md:w-[70%] lg:w-[60%]">
          SPACE ID is a distributed, extensible naming system based on the BNB Smart Chain
          that can be used to resolve a wide variety of resources such as BSC addresses.
        </p>
      </div>
      <div className="w-full self-center flex flex-col gap-1 justify-center items-center my-4 ">
        <div className="flex w-full md:w-[70%] lg:w-[60%] justify-center">
          <input
            type="text"
            className="w-full border border-accent-1 focus:border-accent-2 focus:outline-none hover:border-accent-2 text-content bg-mainBg rounded-l-md h-10 px-2"
            placeholder="Search by Address or Domain Name"
            value={inputValue}
            onChange={(e) => {
              setInputValue((e.target.value).toLocaleLowerCase());
              setSearched(false);
            }}
          />
          <button
            type="button"
            className="bg-mainHeading hover:bg-mainHeading/70 hover:border-mainHeading/70 disabled:bg-gray-500 rounded-r-md px-2 border border-mainHeading"
            disabled={searching}
            onClick={handleLookUp}
          >
            <IoIosSearch className="text-mainBg h-6 w-auto hover:text-subText" />
          </button>
        </div>
        <span className="text-sm text-subHeading flex flex-col items-center">
          Supported Name Service
          <span className="text-xs text-subText">
            .bnb / .eth / .gno / .arb
          </span>
        </span>
      </div>
      <div className="flex self-center justify-center w-full md:w-[80%] lg:w-[60%] pt-4 pb-1 rounded-lg bg-mainBg shadow-md shadow-accent-1 mb-4">
        {searching && (
          <span className="w-full self-center flex items-center justify-center">
            <Loader size="xs" content="Searching..." speed="fast" className=" text-xs m-0 p-0" />
          </span>
        )}
        {/* <div className="w-full"> */}
        {searchResult && searchResult?.image ? (
          <div className="w-full bg-mainBg rounded-lg">
            <div className=" flex flex-col gap-12">
              <div className="flex gap-2 p-2">
                <span className=" h-28 w-28 rounded-md flex justify-center items-center bg-[#DEAB0A]">
                  <img src={searchResult?.image} alt="name logo" className="  w-auto h-28 rounded-md " />
                </span>
                <div className="flex flex-col gap-1 h-28 justify-center">
                  <span className=" font-bold text-2xl md:text-3xl">
                    {searchResult?.name}
                  </span>
                  <span className="text-sm bg-accent-1 p-1 rounded-md">
                    {searchResult?.description}
                  </span>
                </div>
              </div>

              <div className="relative border border-t-4 border-accent-1 rounded-md p-2 flex flex-col">
                <div className="absolute bg-accent-1 px-2 py-1 rounded-t-md -top-8 md:-top-9 self-center">
                  <span
                    className="text-semibold text-[#DEAB0A]"
                  >
                    {searchResult?.name}
                  </span>
                </div>

                <div className=" mt-4 border-t border-accent-1">
                  <ul>
                    {searchResult?.attributes?.map((item) => (
                      <li key={item?.trait_type} className="flex items-center justify-between py-1 border-b-[0.5px] border-accent-1">
                        <span className="text-sm">
                          {item?.trait_type}
                        </span>
                        <span className="text-subText">
                          {formatDate(item?.value, item?.display_type)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <button
                    type="button"
                    className=" w-full bg-mainHeading text-mainBg py-1 px-2 rounded-md hover:bg-mainHeading/60 text-xs md:text-sm disabled:bg-mainHeading/30 mt-3"
                    onClick={() => openInNewTab('https://space.id/')}
                  >
                    Visit Space.id
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <span className="flex flex-col gap-1 text-center">
            {searched && (
              <>
                {searchResult?.message ? (
                  <span>
                    {searchResult?.message}
                  </span>
                ) : (
                  <>
                    No Record found for
                    <span className=" font-bold">
                      {searchedName}
                    </span>
                  </>
                )}
              </>
            )}
          </span>
        )}
        {/* </div> */}
      </div>
    </div>
  );
};

export default NameLookUp;
