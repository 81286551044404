import React from 'react';
import PropTypes from 'prop-types';

// UTILITIES
import { handleCopy, successToast } from '../utils';

const JoinedPopup = (
  {
    handleContinue, projectName, tokenName, airdropAmount, ticker,
    estLaunchPrice, referralAmount, userID, id,
  },
) => {
  const URL = window.location.origin;

  const refText = `🎁 Join ${tokenName} Airdrop and get free ${airdropAmount}${ticker}
  tokens worth $${((Number(estLaunchPrice) || 0) * Number(airdropAmount))?.toFixed(0)}.
  Act fast before the airdrop ends!`;

  return (
    <section className="w-full animated fadeInUp z-40 fixed bg-subtleBg bg-opacity-80 top-0 bottom-0 left-0 flex justify-center items-center h-full overflow-y-auto overflow-x-hidden">
      <div className=" relative grid grid-cols-1 place-content-start place-items-center min-[0px]:m-0 min-[0px]:h-full">
        <div className=" relative bg-mainBg border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col items-center text-content shadow-xl shadow-accent-1 mt-2">
          <h4 className=" font-bold text-xl md:text-3xl mb-10 mt-5 text-center">
            Thank You for Joining the
            {' '}
            <span className=" font-extrabold">
              {projectName}
            </span>
            {' '}
            Airdrop! 🚀
          </h4>

          <div className=" my-4 flex justify-center items-center w-[70%] bg-green-300 border border-green-700 rounded-lg p-2">
            <span className="text-xs font-semibold text-subText text-center">
              Congratulations, You&apos;re now part of an exciting crypto journey with
              {' '}
              {projectName}
              . 🎉
            </span>
          </div>
          <div className=" font-mono flex justify-center items-center flex-col gap-3 text-xs md:text-base w-full md:w-[80%] lg:w-[70%] my-3">
            <p className=" text-center text-lg text-[#0ecb81]">
              Here&apos;s how you can maximize your rewards:
            </p>
            <ul className=" flex flex-col gap-2 text-subText">
              <li>
                <span className=" font-bold mr-1 text-subHeading">1:</span>
                <span>
                  <span className=" font-bold mr-1 text-subHeading">💬 Share Your Referral Link:</span>
                  Invite friends and earn more tokens! The more, the merrier.
                </span>
              </li>
              <li>
                <span className=" font-bold mr-1 text-subHeading">2:</span>
                <span>
                  <span className=" font-bold mr-1 text-subHeading">📢 Spread the Word:</span>
                  Help others discover the airdrop. Your network grows, and so do your rewards.
                </span>
              </li>
            </ul>
            <div className=" m-2 py-1 rounded-lg self-center w-[90%] md:w-[80%] flex flex-col gap-2 items-center border border-accent-1 shadow-md shadow-accent-1">
              <span className=" font-bold underline">Referral Text</span>
              <button
                type="button"
                className=" w-full"
                onClick={() => {
                  if (handleCopy(`${refText} ${URL}/airdrop/${id}?ref=${userID}`)) {
                    successToast('Airdrop Referral Text copied!');
                  }
                }}
              >
                <span className="flex flex-col w-full items-center p-2 border bg-gradient-to-r from-accent-1 via-accent-2/60 to-accent-1 rounded-md whitespace-normal overflow-hidden">
                  <span className=" font-bold">Special Offer: </span>
                  <span className=" ">
                    {refText}
                  </span>
                  <span className=" whitespace-normal overflow-hidden text-[0.65rem] md:text-base">
                    {`${URL}/airdrop/${id}?ref=${userID}`}
                  </span>
                </span>
              </button>
              <button
                type="button"
                className=" text-xs border border-mainHeading text-mainHeading px-2 py-1 rounded-lg  hover:bg-mainHeading hover:text-content"
                onClick={() => {
                  if (handleCopy(`${refText} ${URL}/airdrop/${id}?ref=${userID}`)) {
                    successToast('Airdrop Referral Text copied!');
                  }
                }}
              >
                Click to copy Text
              </button>
              <span className=" text-sm font-bold italic text-amber-500 text-center">
                {`You Get ${referralAmount}${ticker} for every successfull referral`}
              </span>
            </div>
          </div>
          <div className=" my-3 flex flex-col gap-1">
            <span className=" font-bold text-center underline">Your Airdrop Link</span>
            <button
              type="button"
              className="text-link font-light flex flex-col items-center"
              onClick={() => {
                if (handleCopy(`${URL}/airdrop/${id}?ref=${userID}`)) {
                  successToast('Airdrop Link copied!');
                }
              }}
            >
              {`${URL}/airdrop/${id}?ref=${userID}`}
              <span className=" text-xs  text-content">Click to copy Link</span>
            </button>
          </div>
          <div className="w-full flex flex-col gap-3 justify-center items-center">
            <span className=" text-center text-sm text-subText">
              Thank you for being part of the Jetpad community! Together,
              let&apos;s soar to new heights in the crypto space. 🚀💰
            </span>
            <button
              type="button"
              className="self-center bg-mainHeading text-mainBg hover:bg-mainHeading/50 hover:text-content py-2 w-[80%] md:w-[60%] rounded-xl text-lg font-bold mb-2 flex justify-center items-center"
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </section>

  );
};

JoinedPopup.propTypes = {
  projectName: PropTypes.string.isRequired,
  tokenName: PropTypes.string.isRequired,
  airdropAmount: PropTypes.string.isRequired,
  ticker: PropTypes.string.isRequired,
  estLaunchPrice: PropTypes.string.isRequired,
  referralAmount: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  handleContinue: PropTypes.func.isRequired,
};

export default JoinedPopup;
