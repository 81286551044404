import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  applyActionCode, confirmPasswordReset, getAuth, signOut,
} from 'firebase/auth';
import { Loader } from 'rsuite';

// ICONS
import { AiOutlineEye } from 'react-icons/ai';

// UTILITIES
import { errorToast, openInNewTab, successToast } from '../../utils';

const AuthActions = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [verificationComplete, setVerificationComplete] = useState(false);
  const [showNewPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetingPassword, setResetingPassword] = useState(false);
  const [emailVerifyError, setEmailVerifyError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    const handleEmailVerification = async () => {
      if (!mode && !oobCode) {
        navigate('/');
      }

      if (mode === 'verifyEmail' && oobCode) {
        try {
          const auth = getAuth();
          const res = await applyActionCode(auth, oobCode);
          if (res?.error) {
            errorToast('Error verifying email');
            setEmailVerifyError(true);
          } else {
            setVerificationComplete(true);
            successToast('Email Verified Successfully');
          }
        } catch (error) {
          errorToast(`Error verifying email: ${error.message}`);
          setVerificationComplete(true);
          setEmailVerifyError(true);
        }
      }
    };

    handleEmailVerification();
  }, []);

  const handleResetPassword = async () => {
    if (newPassword !== confirmNewPassword) {
      return errorToast('Password and Password comfirmation does not match');
    }
    try {
      setResetingPassword(true);
      const auth = getAuth();
      await confirmPasswordReset(auth, oobCode, newPassword);

      setResetSuccessful(true);
      setResetingPassword(false);
      successToast('Password Reset SuccessFul');
    } catch (error) {
      errorToast(`Error resetting password: ${error.message}`);
      setResetingPassword(false);
    }

    return {};
  };

  const handleClick = async () => {
    const auth = getAuth();
    await signOut(auth);

    return navigate('/signin');
  };

  return (
    <section className=" min-h-screen overflow-hidden  flex justify-center bg-mainBg text-content items-center">
      <div className=" my-8 mx-2  md:mx-8 w-full flex flex-col  justify-center items-center">
        <div className=" bg-subtleBg border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-2 md:p-6 flex flex-col items-center shadow-md shadow-accent-1">
          {mode && mode === 'verifyEmail' ? (
            <div>
              {verificationComplete ? (
                <div className="flex flex-col w-full items-center gap-4">
                  {emailVerifyError ? (
                    <>
                      <p>There was an error verifying your Email.. Contact support now!</p>
                      <button
                        type="button"
                        onClick={() => openInNewTab('https://t.me/JetPad_Finance')}
                        className="self-center bg-mainHeading text-mainBg px-4 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                      >
                        Contact Telegram Support
                      </button>
                    </>
                  ) : (
                    <>
                      <p>Email verification successful! You can now log in.</p>
                      <button
                        type="button"
                        onClick={handleClick}
                        className="self-center bg-mainHeading text-mainBg px-4 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                      >
                        Continue
                      </button>
                    </>
                  )}
                </div>
              ) : (
                <div className="flex flex-col gap-2 items-center">
                  <Loader size="sm" content="Please Wait..." speed="fast" />
                  <p className=" font-bold text-lg ">Verifying email...</p>
                </div>
              )}
            </div>
          ) : (
            <div>
              {resetSuccessful ? (
                <div className="flex flex-col w-full items-center gap-4">
                  <p>Password reset successful! You can now log in with your new password.</p>
                  <button
                    type="button"
                    className="self-center bg-mainHeading text-mainBg px-4 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                    onClick={() => navigate('/signin')}
                  >
                    Login
                  </button>
                </div>
              ) : (
                <>
                  <h2 className=" font-extrabold text-3xl mb-10 mt-5">Reset Your Password</h2>
                  <div className=" flex flex-col gap-5 w-full items-center">
                    <label htmlFor="newPassword" className="flex flex-col gap-1 w-full text-gray-400 font-semibold">
                      New Password:
                      <div className="w-full flex justify-center items-center gap-1 border border-accent-1 rounded-md pr-1">
                        <input
                          type={showNewPassword ? 'text' : 'password'}
                          name="newPassword"
                          value={newPassword}
                          className=" bg-transparent  py-2 px-1 w-full placeholder:text-xs  rounded-md"
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <AiOutlineEye
                          className="hover:cursor-pointer hover:text-[#006CFF]"
                          onClick={() => setShowPassword(!showNewPassword)}
                        />
                      </div>
                    </label>
                    <label htmlFor="confirmNewPassword" className="flex flex-col gap-1 w-full text-gray-400 font-semibold">
                      Confirm Password:
                      <div className="w-full flex justify-center items-center gap-1 border border-accent-1 rounded-md pr-1">
                        <input
                          type={showConfirmPassword ? 'text' : 'password'}
                          name="confirmNewPassword"
                          value={confirmNewPassword}
                          className=" bg-transparent  py-2 px-1 w-full placeholder:text-xs rounded-md"
                          onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                        <AiOutlineEye
                          className="hover:cursor-pointer hover:text-[#006CFF]"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      </div>
                    </label>
                  </div>
                  <div className=" flex flex-col gap-5 w-full items-center my-4">
                    <button
                      type="button"
                      onClick={handleResetPassword}
                      className="self-center bg-mainHeading text-mainBg px-4 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                    >
                      {resetingPassword ? (
                        <Loader size="sm" content="Please Wait..." speed="fast" />
                      ) : (
                        <>
                          Reset Password
                        </>
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AuthActions;
