import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

const SearchingSkeleton = ({ count }) => {
  const numberArray = Array.from({ length: count }, (_, index) => index + 1);

  return (
    <div className="flex flex-col w-full gap-4">
      <span>Searching...</span>
      <div className="flex flex-col w-full gap-3">
        {numberArray?.map(((item) => (
          <div key={item} className="flex gap-3 bg-mainBg p-2 rounded-md w-full h-full">
            <div className="h-[28px] w-[28px] rounded-full self-center">
              <Skeleton
                circle
                height="100%"
              />
            </div>
            <div className="flex flex-col w-full">
              <Skeleton width={50} />
              <div className="w-full flex gap-3">
                <Skeleton
                  width={50}
                  baseColor="#0ecb81"
                  highlightColor="#004b81"
                />
                <Skeleton
                  width={50}
                  baseColor="#0ecb81"
                  highlightColor="#004b81"
                />
              </div>
            </div>
          </div>
        )))}
      </div>
    </div>
  );
};

SearchingSkeleton.propTypes = {
  count: PropTypes.number.isRequired,
};

export default SearchingSkeleton;
