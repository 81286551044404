import React from 'react';

// UTILITIES
import { successToast } from '../utils';

// ASSETS
import { binanceLogo, ethereumLogo, polygonMaticLogo } from '../assets/images';

const MultiSender = () => (
  <>
    <section className="relative py-28 bg-mainBg text-content h-[100%]">
      <div className="max-w-screen-xl mx-auto text-mainBg gap-x-12 items-center justify-between overflow-hidden md:flex md:px-8">
        <div className="flex-none space-y-5 px-4 sm:max-w-lg md:px-0 lg:max-w-xl">
          <h1 className="text-sm text-indigo-600 font-medium">
            Effortless, Affordable, Instant!
          </h1>
          <h2 className="text-4xl text-darkerHeading font-extrabold md:text-5xl">
            Send Tokens to multiple addresses with ease.
          </h2>
          <p className="text-subHeading   ">
            JETPAD Multi-sender allows you to send ERC20, BEP20, and more.. token in
            batch by easiest way.
          </p>
          <div className="items-center flex gap-3 w-full flex-col md:flex-row">
            <button
              type="button"
              className=" w-full md:w-fit py-2 px-4 text-center text-white font-medium bg-indigo-600 duration-150 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg shadow-lg hover:shadow-none"
              onClick={() => successToast(
                '🚀 Exciting News! The Multisender Feature is Under Development! Stay tuned for the launch! 🌐✨',
              )}
            >
              Start Multisending Now
            </button>
            <button
              type="button"
              className="w-full md:w-fit items-center justify-center py-2 px-4 text-indigo-600
              hover:bg-indigo-600 hover:text-indigo-400 font-medium duration-150 border border-indigo-600 rounded-lg"
            >
              Learn More
            </button>
          </div>
        </div>
        <div className="flex-none mt-14 md:mt-0 md:max-w-xl">
          <img
            src="https://images.unsplash.com/photo-1573164713619-24c711fe7878?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80"
            className=" md:rounded-tl-[108px]"
            alt="ig"
          />
        </div>
      </div>
      <div className="mt-14 px-4 md:px-8">
        <p className="text-center text-sm text-subHeading font-semibold">Supported Networks</p>
        <div className="flex justify-center items-center flex-wrap gap-x-12 gap-y-6 mt-6">
          <img
            src={polygonMaticLogo}
            alt="polygon"
            className="h-[43px] w-[190px]"
          />
          <img
            src={binanceLogo}
            alt="binance"
            className="h-[43px] w-[190px]"
          />
          <img
            src={ethereumLogo}
            alt="ethereum"
            className="h-[43px] w-[190px]"
          />
        </div>
      </div>
      <div
        className="absolute inset-0 top-2 left-[35%] max-w-xs h-[307px] blur-[118px] sm:max-w-md md:max-w-lg z"
        style={{ background: 'linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%)' }}
      />
    </section>
  </>
);

export default MultiSender;
