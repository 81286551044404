import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// COMPONENTS
import { ConfirmPopup } from '../components';

// REDUX
import {
  createWeb3DappAirdrop,
  getTokenData,
  transferAirdropToken,
} from '../Redux/Web3Airdrop/web3Airdrop';
import { createDappAirdrop } from '../Redux/FirebaseAirdrop/firebaseAirdrop';
import {
  addChain,
  connectUserWallet,
  switchNetwork,
} from '../Redux/Web3Auth/web3Auth';
import { getTokenPrices } from '../Redux/TokenData/tokenData';

// Assets
import { kittenOne } from '../assets/images';

// UTILITIES
import {
  errorToast,
  successToast,
  formatNumber,
  JSXToast,
  openInNewTab,
} from '../utils';
import { updateProjectStat } from '../Redux/Auth/auth';
import { chainParams } from '../constants';

const DappAirdrop = () => {
  const [active, setActive] = useState(false);
  const [step, setStep] = useState(0);
  const [creatingMSG, setCreatingMSG] = useState('');
  const isLoggedIn = useSelector((state) => state.Auth?.isLoggedIn);
  const isConnected = useSelector((state) => state.Web3Auth?.isConnected);
  const { chainID, address } = useSelector((state) => state.Web3Auth);
  const { userID } = useSelector((state) => state.Auth);
  const tokenPrices = useSelector((state) => state.tokenData?.tokenPrices);
  const [airdropData, setAirdropData] = useState({
    contractAddress: '',
    tokenName: '',
    ticker: '',
    totalSupply: '',
    decimal: '',
    airdropRound: '',
    totalToken: '',
    airdropAmount: '',
    referralAmount: '',
    estLaunchPrice: '',
    startDate: '',
    endDate: '',
    telegramGroup: '',
    telegramChannel: '',
    projectName: '',
    projectWebsite: '',
    twitterPageLink: '',
    whitepaper: '',
    logoLink: '',
    networkValue: 'BSCAddress',
    networkID: '56',
    networkToken: 'BNB',
    networkName: 'BINANCE SMART-CHAIN',
    airdropCA: '0xF9D68e81c055c871886E1363994b40829C2b990E',
    dappFee: 0,
    featured: false,
    websiteHeadline: '',
    headlineDescription: '',
    template: 'T1',
    agreeTerms: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tokenPrices?.ethereum) {
      const ids = 'bitcoin,ethereum,binancecoin,matic-network';
      dispatch(getTokenPrices({ ids }));
    }

    setAirdropData({
      ...airdropData,
      dappFee: (99 / tokenPrices?.binancecoin?.usd || 0).toFixed(2),
    });
  }, [tokenPrices]);

  const checkData = () => {
    const keys = Object.keys(airdropData);
    let emptyInput = null;

    keys.forEach((key) => {
      if (airdropData[key] === '') {
        emptyInput = key;
      }
    });

    return emptyInput;
  };

  const validateDates = () => {
    // const currentTimestamp = Date.now();
    const startTimestamp = Date.parse(airdropData.startDate);
    const endTimestamp = Date.parse(airdropData.endDate);

    // if (startTimestamp < currentTimestamp) {
    //   return 'Start date should be in the future.';
    // }

    if (startTimestamp >= endTimestamp) {
      return 'End date should be greater than start date.';
    }

    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emptyInput = checkData();

    if (emptyInput) {
      return errorToast(`Please fill in the ${emptyInput} field.`);
    }

    const validationError = validateDates();
    if (validationError) {
      return errorToast(validationError);
    }

    if (!airdropData.agreeTerms) {
      return errorToast('You have to agree to the terms and conditions');
    }

    if (airdropData.dappFee === 0) {
      return errorToast('Airdrop Fee cannot be 0');
    }
    setActive(true);
    return {};
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setAirdropData({ ...airdropData, [name]: value });
  };

  const handleOptionChange = (e) => {
    const networkID = e.target.options[e.target.selectedIndex].id;
    const networkValue = e.target.value;
    const chainIDHex = e.target?.options[e.target.selectedIndex].title;
    const tempName = e.target.options[e.target.selectedIndex].textContent;
    let networkToken = '';
    let dappFee = 0;
    let networkName = '';
    let airdropCA = '';
    dispatch(switchNetwork({ chainIDHex })).then((res) => {
      if (res.error) {
        const regex = /wallet_addEthereumChain/i;
        if (regex.test(res.payload)) {
          errorToast(`No ${tempName}. Adding Chain to Wallet... `);
          dispatch(addChain(chainParams[chainIDHex])).then((res) => {
            if (res.error) {
              errorToast(res?.payload);
            } else {
              successToast(res?.payload);
              dispatch(connectUserWallet());
            }
          });
        } else {
          errorToast(res.payload);
        }
      } else {
        dispatch(connectUserWallet());
        successToast(res.payload);
        if (networkValue === 'BSCAddress') {
          networkToken = 'BNB';
          dappFee = (99 / tokenPrices?.binancecoin?.usd || 0).toFixed(2);
          networkName = 'BINANCE SMART-CHAIN';
          airdropCA = '0x58529CeE4ecbfc18C5852e17ba2c28633F104Fd4';
          // dappFee = 0.001;
          // networkName = 'BINANCE SMART-CHAIN TEST';
          // airdropCA = '0x426b62c3ff5f2f3CEc4762253a25d4B01D8771d9';
        } else if (networkValue === 'ethereumAddress') {
          networkToken = 'ETH';
          dappFee = (99 / tokenPrices?.ethereum?.usd || 0).toFixed(2);
          networkName = 'ETHEREUM';
          airdropCA = '0xF9D68e81c055c871886E1363994b40829C2b990E';
        } else {
          networkToken = 'MATIC';
          dappFee = (99 / tokenPrices['matic-network']?.usd || 0).toFixed(0);
          networkName = 'POLYGON MATIC';
          airdropCA = '0x01Ff99bbd61117a4Aabbf4e2fddaCB9B074b75F7';
        }
        setAirdropData({
          ...airdropData,
          networkValue,
          networkID,
          networkToken,
          dappFee,
          networkName,
          airdropCA,
        });
      }
    });
  };

  const handleTempChange = (e) => {
    setAirdropData({ ...airdropData, template: e.target.value });
  };

  const updateStateWithTokenData = (apiResponse) => {
    const {
      tokenName,
      tokenSymbol,
      totalSupply,
      tokenDecimals,
      name,
      symbol,
      decimals,
    } = apiResponse;

    setAirdropData({
      ...airdropData,
      tokenName: tokenName || name || '',
      ticker: tokenSymbol || symbol || '',
      decimal: tokenDecimals || decimals || '',
      totalSupply: totalSupply || '',
    });
  };

  useEffect(() => {
    if (airdropData.contractAddress >= 42) {
      const tokenData = {
        contractAddress: airdropData.contractAddress,
        networkId: airdropData.networkID,
      };
      dispatch(getTokenData(tokenData)).then((res) => {
        if (res.error) {
          errorToast('Make sure you are connected to the right network');
        } else {
          updateStateWithTokenData(res.payload);
          successToast(res.payload.message);
        }
      });
    }
  }, [airdropData.networkID, airdropData.contractAddress]);

  const createAirdrop = (e) => {
    e.preventDefault();
    let airdropIndexID = '';
    let transactionHash = '';

    if (!isLoggedIn) {
      return JSXToast('/signin', 'LogIn', 'You are not Logged In');
    }

    if (!isConnected) {
      return errorToast('Please connect Your wallet');
    }

    setCreatingMSG('Starting Transaction...');
    const data = {
      airdropFee: airdropData.dappFee,
      startDate: airdropData.startDate,
      endDate: airdropData.endDate,
      totalAmount: airdropData.totalToken,
      airdropAmount: airdropData.airdropAmount,
      tokenAddress: airdropData.contractAddress,
      referralAmount: airdropData.referralAmount,
      airdropContractAddress: airdropData.airdropCA,
      decimal: airdropData.decimal,
    };
    dispatch(createWeb3DappAirdrop(data)).then((res) => {
      if (res.error) {
        errorToast(res.payload);
        setActive(false);
      } else {
        successToast('Transaction successfull, Initiating Token Tansfer...');
        setCreatingMSG('Transfering Token...');
        airdropIndexID = res.payload?.airdropIndexID;
        transactionHash = res.payload?.transactionHash;

        const data = {
          contractAddress: airdropData.contractAddress,
          decimal: airdropData.decimal,
          tokenAmount: airdropData.totalToken,
          airdropCA: airdropData.airdropCA,
        };

        dispatch(transferAirdropToken(data)).then((res) => {
          if (res.error) {
            errorToast(res.payload);
            setActive(false);
            setCreatingMSG('');
          } else {
            successToast('Token transfer successfull, Creating airdrop...');
            setCreatingMSG('Creating airdrop...');

            dispatch(
              createDappAirdrop({
                ...airdropData,
                userID,
                chainID,
                address,
                airdropIndexID,
                transactionHash,
              }),
            ).then((res) => {
              if (res.error) {
                errorToast(res.payload);
                setActive(false);
                setCreatingMSG('');
              } else {
                successToast('Dapp Airdrop created successfully');
                dispatch(updateProjectStat('totalDapp'));
                setActive(false);
                setCreatingMSG('');
                navigate('/my-airdrops');
              }
            });
          }
        });
      }
    });

    return {};
  };

  return (
    <>
      <Helmet>
        <title>JetPad - Dapp-Airdrop</title>
        <meta name="description" content="Create Dapp Airdrop" />
        <meta property="og:title" content="JetPad - Dapp-Airdrop" />
        <meta property="og:description" content="Create Dapp Airdrop" />
        <meta
          property="og:image"
          content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <section className="flex flex-col justify-center bg-mainBg text-conten items-center">
        <div className=" my-8 mx-8 w-full flex flex-col  justify-center items-center">
          <div className="bg-subtleBg relative border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col items-center text-content shadow-md shadow-accent-1">
            <img
              src={kittenOne}
              alt="kitten"
              className=" absolute w-[99px] aspect-auto top-[-48px] right-0"
            />
            <h5 className="mb-6 font-black">
              Unleash the Power of Your Tokens with our Effortless DApp Builder!
            </h5>
            <p className=" font-mono mb-4 text-subHeading">
              The ultimate solution that empowers new token owners to take
              control of their digital assets. Our groundbreaking web
              application redefines the way you approach blockchain, making it
              effortless to build, deploy, and thrive with fully functional
              decentralized airdrop claim applications (DApps).
            </p>
            <span className=" font-light text-subText">
              Our robust Smart Contract allows your community to effortlessly
              claim your token, Refer their peers and get referral rewards too.
            </span>
          </div>
        </div>
        <div className=" my-8 mx-8 w-full flex flex-col  justify-center items-center">
          <div className="bg-subtleBg border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col items-center text-content">
            <h2 className=" font-extrabold text-2xl mb-5 mt-5">
              Create Airdrop Dapp
            </h2>
            <form
              onSubmit={handleSubmit}
              className=" flex flex-col gap-3 w-full items-center"
            >
              {step === 0 && (
                <div className=" animated fadeInDown flex flex-col gap-3 w-full items-center">
                  <h2 className=" font-bold text-2xl mb-10 mt-5 text-mainHeading">
                    Token data
                  </h2>
                  <button
                    type="button"
                    className=" text-xs md:text-sm border border-mainHeading text-mainHeading hover:bg-mainHeading hover:text-subHeading  px-2 py-1 rounded-xl"
                    onClick={() => openInNewTab('/dapp-demo')}
                  >
                    View Templates
                  </button>
                  <select
                    value={airdropData.networkValue}
                    onChange={handleOptionChange}
                    className=" border-accent-1 bg-mainBg border-2
                text-center rounded-xl font-mono text-sm md:text-lg hover:border-accent-1/90"
                  >
                    <option value="BSCAddress" id="56" title="0x38">
                      BSC
                    </option>
                    {/* <option value="BSCAddress" id="97" title="0x61">
                      BSC TestNet
                    </option> */}
                    <option value="ethereumAddress" id="1" title="0x1">
                      Ethereum
                    </option>
                    <option value="polygonAddress" id="137" title="0x89">
                      Polygon
                    </option>
                  </select>
                  <input
                    name="contractAddress"
                    className="auth-input placeholder:text-xs border border-accent-1"
                    type="text"
                    placeholder="Paste token Contract address"
                    value={airdropData.contractAddress}
                    onChange={(e) => handleChange(e)}
                  />
                  <div className="flex gap-4 w-[90%]">
                    <input
                      name="tokenName"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Token name"
                      value={airdropData.tokenName}
                      onChange={(e) => handleChange(e)}
                      disabled
                    />
                    <input
                      name="ticker"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Ticker"
                      value={airdropData.ticker}
                      onChange={(e) => handleChange(e)}
                      disabled
                    />
                  </div>
                  <div className="flex gap-4 w-[90%]">
                    <input
                      name="totalSupply"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Total supply"
                      value={formatNumber(airdropData.totalSupply)}
                      onChange={(e) => handleChange(e)}
                      disabled
                    />
                    <input
                      name="decimal"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Decimal"
                      value={airdropData.decimal}
                      onChange={(e) => handleChange(e)}
                      disabled
                    />
                  </div>
                  <span className=" flex justify-start w-[90%] text-xs md:text-base text-subText">
                    {`Create Dapp airdrop fee: ${airdropData.dappFee} ${airdropData.networkToken}`}
                  </span>
                  <button
                    type="button"
                    className="self-center bg-mainHeading text-mainBg px-8 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                    onClick={() => setStep(1)}
                  >
                    Next
                  </button>
                </div>
              )}
              {step === 1 && (
                <div className=" animated fadeInDown flex flex-col gap-3 w-full items-center">
                  <h2 className=" font-bold text-2xl mb-10 mt-5 text-mainHeading">
                    Airdrop details
                  </h2>
                  <div className="flex gap-4 w-[90%]">
                    <input
                      name="airdropRound"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Airdrop round (Round 1)"
                      value={airdropData.airdropRound}
                      onChange={(e) => handleChange(e)}
                    />
                    {/* 25%, 50% */}
                    <input
                      name="totalToken"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="number"
                      placeholder="Total token for Airdrop (EG: 100,000,000)"
                      value={airdropData.totalToken}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="flex gap-4 w-[90%]">
                    <input
                      name="airdropAmount"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="number"
                      placeholder="Airdrop amount (EG: 550)"
                      value={airdropData.airdropAmount}
                      onChange={(e) => handleChange(e)}
                    />
                    <input
                      name="referralAmount"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="number"
                      placeholder="Token amount per referral (EG: 100)"
                      value={airdropData.referralAmount}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="flex gap-4 w-[100%] justify-center">
                    <input
                      name="estLaunchPrice"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="number"
                      placeholder="Estimated token Launch Price ($0.04)"
                      value={airdropData.estLaunchPrice}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="flex gap-4 w-[90%]">
                    <label htmlFor="startDate" className="w-[100%]">
                      Start Date:
                      <input
                        id="startDate"
                        name="startDate"
                        className="auth-input placeholder:text-xs border border-accent-1 appearance-none date-input w-[100%] text-content"
                        type="date"
                        placeholder="Airdrop start date"
                        value={airdropData.startDate}
                        onChange={(e) => handleChange(e)}
                      />
                    </label>

                    <label htmlFor="endDate" className="w-[100%]">
                      End Date:
                      <input
                        id="endDate"
                        name="endDate"
                        className="auth-input placeholder:text-xs border border-accent-1 appearance-none date-input w-[100%] text-content"
                        type="date"
                        placeholder="Airdrop end date"
                        value={airdropData.endDate}
                        onChange={(e) => handleChange(e)}
                      />
                    </label>
                  </div>
                  <div className="flex gap-4 w-[90%]">
                    <input
                      name="telegramGroup"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Telegram group link"
                      value={airdropData.telegramGroup}
                      onChange={(e) => handleChange(e)}
                    />
                    <input
                      name="telegramChannel"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Telegram channel link"
                      value={airdropData.telegramChannel}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="flex gap-4 w-[90%]">
                    <input
                      name="twitterPageLink"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Twitter Page Link"
                      value={airdropData.twitterPageLink}
                      onChange={(e) => handleChange(e)}
                    />
                    <input
                      name="whitepaper"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="White Paper link"
                      value={airdropData.whitepaper}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="flex gap-6">
                    <button
                      type="button"
                      className="self-center bg-mainHeading text-mainBg px-8 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                      onClick={() => setStep(0)}
                    >
                      Prev
                    </button>
                    <button
                      type="button"
                      className="self-center bg-mainHeading text-mainBg px-8 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                      onClick={() => setStep(2)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className=" animated fadeInDown flex flex-col gap-3 w-full items-center">
                  <h2 className=" font-bold text-2xl mb-5 mt-5 text-mainHeading">
                    Project details
                  </h2>
                  <div className="flex gap-4 w-[90%]">
                    <input
                      name="projectName"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Project Name"
                      value={airdropData.projectName}
                      onChange={(e) => handleChange(e)}
                    />
                    <input
                      name="projectWebsite"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Project Website"
                      value={airdropData.projectWebsite}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="flex gap-4 w-[100%] justify-center">
                    <input
                      name="websiteHeadline"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Enter website Headline"
                      value={airdropData.websiteHeadline}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="flex gap-4 w-[100%] justify-center">
                    <textarea
                      name="headlineDescription"
                      className="auth-input placeholder:text-xs border border-accent-1"
                      type="text"
                      placeholder="Enter Website Headline Desc"
                      value={airdropData.headlineDescription}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="flex flex-col w-[90%]">
                    <input
                      name="logoLink"
                      className="auth-input placeholder:text-xs border border-accent-1 w-full"
                      type="text"
                      placeholder="Logo link"
                      value={airdropData.logoLink}
                      onChange={(e) => handleChange(e)}
                    />
                    <p
                      id="featured-info"
                      className="text-xs md:text-sm text-subText"
                    >
                      URL must end with a supported image extension png, jpg,
                      jpeg or gif. You can upload your image at - Coming-soon
                    </p>
                  </div>
                  <div className="flex flex-col items-center w-full mt-4 gap-3">
                    <h4 className=" font-semibold font-barlow">
                      Choose Dapp Template
                    </h4>
                    <div className="flex flex-col md:flex-row  gap-4 justify-center">
                      <select
                        value={airdropData.template}
                        onChange={handleTempChange}
                        className="  border-accent-1 bg-mainBg border-2
                    text-center rounded-xl font-mono text-sm md:text-lg hover:border-accent-1/90"
                      >
                        <option value="T1" id="1" title="Template 1">
                          Template 1
                        </option>
                        <option value="T2" id="2" title="Template 2">
                          Template 2
                        </option>
                        <option value="T3" id="3" title="Template 3">
                          Template 3
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-1 w-[90%]">
                    <label
                      htmlFor="featured"
                      className="lg:w-[40%] text-xs text-subText"
                    >
                      <input
                        type="checkbox"
                        name="featured"
                        id="featured"
                        className="mr-1"
                        onClick={() => setAirdropData({
                          ...airdropData,
                          agreeTerms: !airdropData.agreeTerms,
                        })}
                      />
                      I Agree to the Terms and Conditions
                    </label>
                  </div>
                  <div className="flex gap-6">
                    <button
                      type="button"
                      className="self-center bg-mainHeading text-mainBg px-8 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
                      onClick={() => setStep(1)}
                    >
                      Prev
                    </button>
                    <button
                      type="submit"
                      className="self-center bg-[#11cc00] px-10 py-2 rounded-xl hover:bg-[#11cc00cc] text-lg font-bold mb-2"
                    >
                      Create
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
        {active && (
          <ConfirmPopup
            setActive={setActive}
            handleClick={createAirdrop}
            title="Create Airdrop"
            creatingMSG={creatingMSG}
            message={`
        This is going to trigger two transactions.
        1: A transaction of ${airdropData.dappFee} ${airdropData.networkToken}.
        2: A transfer of ${airdropData.totalToken} ${airdropData.ticker} into the airdrop smart contract and Your Airdrop will be created.
        Please make sure you have enough ${airdropData.networkToken} and ${airdropData.tokenName} to cover both transactions.
        `}
          />
        )}
      </section>
    </>
  );
};

export default DappAirdrop;
