import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// PAGES
import DappT1 from './DappT1';
import DappT2 from './DappT2';
import DappT3 from './DappT3';

// REDUX
import { getDappDetails, joinDappAirdrop } from '../../Redux/FirebaseAirdrop/firebaseAirdrop';
import { connectUserWallet } from '../../Redux/Web3Auth/web3Auth';
import { claimWeb3DappAirdrop } from '../../Redux/Web3Airdrop/web3Airdrop';

// UTILITIES
import { errorToast, successToast } from '../../utils';

const Dapp = () => {
  const [template, setTemplate] = useState('');
  const [dappData, setDappData] = useState(null);
  const [dappId, setDappid] = useState(null);
  const [referralID, setReferralID] = useState(null);
  const [referralLink, setReferralLink] = useState('Connect Your Wallet');
  const address = useSelector((state) => state.Web3Auth?.address) || '';
  const isConnected = useSelector((state) => state.Web3Auth?.isConnected);
  const chain = useSelector((state) => state.Web3Auth?.chainID);
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const template = id.slice(0, 2);
    const dappID = id.slice(2);
    setTemplate(template);
    setDappid(dappID);

    dispatch(getDappDetails(dappID)).then((res) => {
      if (res.error) {
        errorToast(res.payload);
      } else {
        setDappData(res.payload);
      }
    });
  }, [id]);

  useEffect(() => {
    const currentURL = window.location.href;

    const url = new URL(currentURL);

    if (url.searchParams.has('ref')) {
      const referrerValue = url.searchParams.get('ref');
      setReferralID(referrerValue);
    }
  }, []);

  const connectWallet = () => {
    dispatch(connectUserWallet()).then((res) => {
      if (res.error) {
        errorToast(res.payload);
      } else {
        successToast('Wallet connected');
        const URL = window.location.origin;
        const refLink = `${URL}/dapp/${dappData?.dappURL}?ref=${res.payload.address}`;
        setReferralLink(refLink);
      }
    });
  };

  const handleClaim = () => {
    if (!isConnected) {
      return errorToast('Please connect your wallet to claim');
    }
    if (chain !== dappData?.chainID) {
      return errorToast(`Please connect to ${dappData?.networkName} network`);
    }

    const data = {
      airdropCA: dappData.airdropCA,
      airdropIndexID: dappData.airdropIndexID,
      decimal: dappData.decimal,
      airdropAmount: dappData.airdropAmount,
      referrerAdd: referralID || '0x0000000000000000000000000000000000000000',
      referralAmount: dappData.referralAmount,
      claimFee: dappData?.claimFee || 0,
    };
    dispatch(claimWeb3DappAirdrop(data)).then((res) => {
      if (res.error) {
        errorToast(res?.payload);
      } else {
        const data = {
          id: dappId,
          airdropAmount: dappData.airdropAmount,
          referralAmount: dappData.referralAmount,
          referralID,
          address,
        };
        dispatch(joinDappAirdrop(data)).then((res) => {
          if (res.error) {
            errorToast(res?.payload);
          } else {
            successToast('Airdrop claimed successfully');
          }
        });
      }
    });
    return {};
  };

  return (
    <>
      {/* {dappData !== null && ( */}
      <>
        {template === 'T1' && (
          <DappT1
            dappData={dappData || null}
            connectWallet={connectWallet}
            address={address}
            referralLink={referralLink}
            handleClaim={handleClaim}
          />
        )}
        {template === 'T2' && (
          <DappT2
            dappData={dappData || null}
            connectWallet={connectWallet}
            address={address}
            referralLink={referralLink}
            handleClaim={handleClaim}
          />
        )}
        {template === 'T3' && (
          <DappT3
            dappData={dappData || null}
            connectWallet={connectWallet}
            address={address}
            referralLink={referralLink}
            handleClaim={handleClaim}
          />
        )}
      </>
      {/* )} */}
    </>
  );
};

export default Dapp;
