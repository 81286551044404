import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Loader } from 'rsuite';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { blue } from '@mui/material/colors';

// ICONS
import { AiOutlineCloseCircle } from 'react-icons/ai';

// REDUX
import { fetchUserData, isUsernameAvailable, updateUserName } from '../Redux/Auth/auth';

// UTILITIES
import { errorToast, isValidUsername, successToast } from '../utils';

const SetUserNamePopup = ({ setUEditActive, userID, email }) => {
  const [userName, setUserName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [userType, setUserType] = useState('');
  const [userNameError, setUserNameError] = useState(false);
  const [userNameErrorMSG, setUserNameErrorMSG] = useState('');
  const [userNamesuccessMSG, setUserNamesuccessMSG] = useState('');
  const [loading, setLoading] = useState(false);
  const [checkingUserName, setCheckingUserName] = useState(false);
  const [validUserName, setValidUserName] = useState(false);

  const dispatch = useDispatch();

  const handleUserName = (e) => {
    setUserNameError(false);
    setUserNameErrorMSG('');
    setUserNamesuccessMSG('');
    setValidUserName(false);

    setUserName(e.target.value);

    if (e.target.value.length < 3) {
      setUserNameErrorMSG('User-name Must Have more than 3 characters');
      return setUserNameError(true);
    }
    if (!isValidUsername(e.target.value)) {
      setUserNameErrorMSG('Your username can only contain lowercase letters, numbers and \'_\'');
      return setUserNameError(true);
    }

    // check username availability
    setCheckingUserName(true);
    dispatch(isUsernameAvailable(e.target.value)).then((res) => {
      if (res.error) {
        errorToast(`Something Went Wrong - ${res?.payload}`);
        setCheckingUserName(false);
        setValidUserName(false);
      } else if (res.payload === false) {
        setUserNamesuccessMSG('User-name is Available');
        setUserNameErrorMSG('');
        setUserNameError(false);
        setCheckingUserName(false);
        setValidUserName(true);
      } else {
        setUserNameErrorMSG('User-name is taken, try-again');
        setUserNameError(true);
        setCheckingUserName(false);
        setValidUserName(false);
      }
    });
    return true;
  };

  const handleUpdate = () => {
    setLoading(true);
    if (!validUserName) {
      setLoading(false);
      return errorToast('Your Username is not Valid');
    }
    if (!displayName?.trim()) {
      setLoading(false);
      return errorToast('Your Display is not Empty');
    }
    if (!userType) {
      setLoading(false);
      return errorToast('You have not selected a user type');
    }

    dispatch(updateUserName({
      email, userName, displayName, userID, userType,
    })).then((res) => {
      if (res.error) {
        errorToast(res.payload);
      } else {
        successToast('User-name Updated Successfully');
        setLoading(false);
        dispatch(fetchUserData(userID));
        setUEditActive(false);
      }
    });
    return {};
  };

  return (
    <section className="w-full animated fadeInUp h-full z-40 fixed bg-subtleBg bg-opacity-80 left-0 top-0 bottom-0 flex justify-center items-center">
      <div className="bg-mainBg relative border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col text-content shadow-md shadow-accent-1">
        <AiOutlineCloseCircle className="absolute right-5 hover:text-red-500 text-xl" onClick={() => setUEditActive(false)} />
        <h2 className=" font-extrabold leading-3 text-3xl mb-10 mt-5 text-center">Set Your User-Name</h2>
        <div className=" font-mono flex justify-center items-center flex-col gap-3 text-xs md:text-base">
          <div className="flex w-full justify-center flex-col items-start ml-[10%]">
            <input
              name=""
              className={`auth-input ${userNameError && 'border border-red-500'} placeholder:text-xs border border-accent-1`}
              type="text"
              placeholder="Enter User-name"
              value={userName}
              onChange={(e) => handleUserName(e)}
              required
            />
            {checkingUserName ? (
              <>
                <Loader size="xs" content="checking..." speed="fast" className="text-link font-bold mt-1" />
              </>
            ) : (
              <>
                {userNameError
                  ? (
                    <span className="text-xs md:text-sm font-light text-red-500">
                      {userNameErrorMSG}
                    </span>
                  ) : (
                    <span className="text-xs md:text-sm font-light text-green-500">
                      {userNamesuccessMSG}
                    </span>
                  )}

              </>
            )}
            {/* </span> */}
          </div>
          <input
            className="auth-input placeholder:text-xs border border-accent-1"
            type="text"
            value={displayName}
            placeholder="Enter Prefered Display name"
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <div className="w-full ml-[10%]">
            <FormControl className="w-[90%]">
              <FormLabel id="user type">
                <span className="text-content font-light">Are You a :-</span>
              </FormLabel>
              <RadioGroup
                aria-labelledby="user type"
                name="userType"
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
              >
                <div className="flex flex-row shrink-0 gap-2 w-full">
                  <span className="flex flex-col  border border-accent-1 p-1 text-xs rounded-md text-subText w-full">
                    <FormControlLabel value="Project Owner" control={<Radio sx={{ color: blue[800], '&.Mui-checked': { color: blue[600] } }} />} label="Project Owner" className="" />
                    I own/manage a crypto project
                  </span>
                  <span className="flex flex-col  border border-accent-1 p-1 text-xs rounded-md text-subText w-full">
                    <FormControlLabel value="Airdrop Hunter" control={<Radio sx={{ color: blue[800], '&.Mui-checked': { color: blue[600] } }} />} label="Airdrop Hunter" />
                    I&apos;m an airdrop/crypto enthusiast
                  </span>
                </div>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="">
            <button
              type="button"
              className="self-center bg-mainHeading text-mainBg px-4 py-2 rounded-xl hover:bg-mainHeading/90 text-lg font-bold mb-2 flex justify-center items-center"
              onClick={() => handleUpdate()}
            >
              {loading ? (
                <>
                  <Loader size="sm" content="Updating..." speed="fast" />
                </>
              ) : 'UPDATE'}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

SetUserNamePopup.propTypes = {
  setUEditActive: PropTypes.func.isRequired,
  userID: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default SetUserNamePopup;
