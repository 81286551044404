import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  createUserWithEmailAndPassword, getAuth, sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword, signOut, updateProfile,
} from 'firebase/auth';
import {
  collection, doc, getDoc, getDocs, setDoc, updateDoc,
} from 'firebase/firestore';
import { auth, db } from '../../firebase';
// fetchedUser
const initialState = {
  fetchedUser: false,
  loading: false,
  isLoggedIn: false,
  isAuthCompleted: false,
  joinedAirdrops: [],
  createdAirdrops: [],
};

// Action-types
const CREATE_ACCOUNT = 'Auth/auth/CREATE_ACCOUNT';
const RESEND_EMAIL_VERIFICATION = 'Auth/auth/RESEND_EMAIL_VERIFICATION';
const HANDLE_REFERRAL = 'Auth/auth/HANDLE_REFERRAL';
const SIGN_IN_USER = 'Auth/auth/SIGN_IN_USER';
const SIGN_OUT_USER = 'Auth/auth/SIGN_OUT_USER';
const RESET_USER_PASSWORD = 'Auth/auth/RESET_USER_PASSWORD';
const UPDATE_USER_PROFILE = 'Auth/auth/UPDATE_USER_PROFILE';
const UPDATE_USER_NAME = 'Auth/auth/UPDATE_USER_NAME';
const FETCH_USER_DATA = 'Auth/auth/FETCH_USER_DATA';
const UPDATE_PROJECT_STAT = 'Auth/auth/UPDATE_PROJECT_STAT';
const CHECK_USERNAME_AVAILABILITY = 'Auth/auth/CHECK_USERNAME_AVAILABILITY';
const AUTH = 'Auth/auth/AUTH';
const GET_USER_LOCATION_INFO = 'Auth/auth/GET_USER_LOCATION_INFO';

// Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${CREATE_ACCOUNT}/pending`:
      return { ...state, loading: true };
    case `${CREATE_ACCOUNT}/fulfilled`:
      return { ...state, ...action.payload, loading: false };
    case `${CREATE_ACCOUNT}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${RESEND_EMAIL_VERIFICATION}/pending`:
      return { ...state, loading: true };
    case `${RESEND_EMAIL_VERIFICATION}/fulfilled`:
      return { ...state, ...action.payload, loading: false };
    case `${RESEND_EMAIL_VERIFICATION}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${SIGN_OUT_USER}/pending`:
      return { ...state, loading: true };
    case `${SIGN_OUT_USER}/fulfilled`:
      return { ...action.payload, loading: false };
    case `${SIGN_OUT_USER}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${UPDATE_USER_PROFILE}/pending`:
      return { ...state, loading: true };
    case `${UPDATE_USER_PROFILE}/fulfilled`:
      return { ...state, ...action.payload, loading: false };
    case `${UPDATE_USER_PROFILE}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${FETCH_USER_DATA}/pending`:
      return { ...state, loading: true };
    case `${FETCH_USER_DATA}/fulfilled`:
      return {
        ...state, ...action.payload, loading: false, fetchedUser: true,
      };
    case `${FETCH_USER_DATA}/rejected`:
      return {
        ...state, loading: false, fetchedUser: true, error: action.error.message,
      };
    case AUTH:
      return { ...state, loading: false, ...action.authUser };
    default:
      return state;
  }
}

// Action Creators
// Create account with email and password
export const createAccount = createAsyncThunk(CREATE_ACCOUNT, async (data, { rejectWithValue }) => {
  const {
    email, password, displayName, userName, userType, referrer, locationData,
  } = data;

  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(user, { displayName });
    // await sendEmailVerification(user);

    const userDocRef = doc(collection(db, 'users'), user?.uid);
    await setDoc(userDocRef,
      {
        createdAirdrops: [],
        joinedAirdrops: [],
        userName,
        displayName,
        email,
        userType,
        locationData,
        referrer: referrer || 'No Referrer',
        refCount: 0,
        points: 0,
      },
      { merge: true });

    return { name: user.displayName, email: user.email };
  } catch (error) {
    return rejectWithValue(error.code);
  }
});

// Check if User-name is available
export const isUsernameAvailable = createAsyncThunk(CHECK_USERNAME_AVAILABILITY,
  async (userName, { rejectWithValue }) => {
    try {
      const userDocRef = collection(db, 'users');
      const querySnapshot = await getDocs(userDocRef);

      // Check if the username already exists in the collection
      const usernameExists = querySnapshot.docs.some((doc) => doc.data().userName === userName);

      return usernameExists;
    } catch (error) {
      return rejectWithValue(error.code);
    }
  });

// Resend unverified user's email verififcation link
export const resendVerificatonEmail = createAsyncThunk(RESEND_EMAIL_VERIFICATION,
  async (_, { rejectWithValue }) => {
    try {
      const auth = getAuth();
      await sendEmailVerification(auth.currentUser);

      return { uId: auth.currentUser.uid, email: auth.currentUser.email };
    } catch (error) {
      return rejectWithValue(error.code);
    }
  });

// Handle New-User Referral
export const handleReferral = createAsyncThunk(HANDLE_REFERRAL,
  async (userName, { rejectWithValue }) => {
    try {
      const userDocRef = collection(db, 'users');
      const querySnapshot = await getDocs(userDocRef);

      const matchingDoc = querySnapshot.docs.find((doc) => doc.data().userName === userName);
      if (matchingDoc) {
        const matchingDocRef = matchingDoc?.ref;
        const currentRefCount = matchingDoc.data()?.refCount || 0;
        const currentPoints = matchingDoc.data()?.points || 0;
        const newRefCount = currentRefCount + 1;
        const newPoints = currentPoints + 1.5;

        await updateDoc(matchingDocRef, { refCount: newRefCount, points: newPoints });
        return true;
      }
      return false;
    } catch (error) {
      return rejectWithValue(error.code);
    }
  });

// Sign in user with email and password
export const logInUser = createAsyncThunk(SIGN_IN_USER, async (data, { rejectWithValue }) => {
  const { email, password } = data;

  try {
    const auth = getAuth();
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return { displayName: user.displayName, email: user.email };
  } catch (error) {
    return rejectWithValue(error.code);
  }
});

// Check users auth status
export const checkAuthStatus = (authUser) => ({ type: AUTH, authUser });

// Sign out user
export const logOutUser = createAsyncThunk(SIGN_OUT_USER, async (_, { rejectWithValue }) => {
  try {
    const auth = getAuth();
    await signOut(auth);

    return { isLoggedIn: false };
  } catch (error) {
    return rejectWithValue(error.code);
  }
});

// Reset users password
export const resetUserPassword = createAsyncThunk(RESET_USER_PASSWORD,
  async (email, { rejectWithValue }) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return {};
    } catch (error) {
      return rejectWithValue(error.code);
    }
  });

// Update User details
export const updateUserProfile = createAsyncThunk(UPDATE_USER_PROFILE,
  async (data, { rejectWithValue }) => {
    const { userID } = data;

    try {
      const userDocRef = doc(collection(db, 'users'), userID);
      const snapshot = await getDoc(userDocRef);
      if (snapshot.exists()) {
        let existingData = snapshot.data();
        if (data?.BSCAddress && data?.BSCAddress !== existingData?.BSCAddress) {
          existingData = { ...existingData, bnbDomainName: '' };
        }
        if (data?.ethereumAddress && data?.ethereumAddress !== existingData?.ethereumAddress) {
          existingData = { ...existingData, ethDomainName: '' };
        }

        await updateDoc(userDocRef, { ...existingData, ...data });
      } else {
        await setDoc(userDocRef,
          { ...data, createdAirdrops: [], joinedAirdrops: [] },
          { merge: true });
      }
      // Retrieve the updated document data
      const newSnapshot = await getDoc(userDocRef);
      const updatedData = newSnapshot.data();
      return updatedData;
    } catch (error) {
      return rejectWithValue(error.code);
    }
  });

// Update User details
export const updateUserName = createAsyncThunk(UPDATE_USER_NAME,
  async (data, { rejectWithValue }) => {
    const {
      email, userName, displayName, userID, userType,
    } = data;

    try {
      const userDocRef = doc(collection(db, 'users'), userID);

      await setDoc(userDocRef,
        {
          userName,
          displayName,
          email,
          userType,
          refCount: 0,
          points: 0,
        },
        { merge: true });

      return {};
    } catch (error) {
      return rejectWithValue(error.code);
    }
  });

// Fetch Auth user additional data
export const fetchUserData = createAsyncThunk(FETCH_USER_DATA,
  async (userID, { rejectWithValue }) => {
    try {
      const userDocRef = doc(collection(db, 'users'), userID);
      const snapshot = await getDoc(userDocRef);
      if (snapshot.exists()) {
        const updatedData = snapshot.data();
        const { timeStamp, ...newData } = updatedData;
        return newData;
      }
      return {};
    } catch (error) {
      return rejectWithValue(error.code);
    }
  });

// UPDATE PROJECT STATS
export const updateProjectStat = createAsyncThunk(UPDATE_PROJECT_STAT,
  async (key, { rejectWithValue }) => {
    try {
      const docID = 'sHUkvA63bT1dpTCwon1m';
      const docRef = doc(collection(db, 'projectStat'), docID);
      const snapshot = await getDoc(docRef);

      if (snapshot.exists()) {
        const currentData = snapshot.data();

        // Increment the specified key by 1
        currentData[key] = (currentData[key] || 0) + 1;

        // Update the Firestore document
        await updateDoc(docRef, currentData);
      }

      return {};
    } catch (error) {
      return rejectWithValue(error.code);
    }
  });

// GET USER LOCATION INFO - IP - COUNTRY -
export const getLocationInfo = createAsyncThunk(GET_USER_LOCATION_INFO,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`,
      );

      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.code);
    }
  });
