import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// METERIAL UI
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// REDUX
import { getTokenPrices } from '../Redux/TokenData/tokenData';

// ASSETS
import { BSCLogo, ETHLogo, POLYGONLogo } from '../assets/images';

const CreateAirdrop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tokenPrices = useSelector((state) => state.tokenData?.tokenPrices);
  // Tooltip state for social airdrop
  const [SF1, setSF1] = useState(false);
  const [SF2, setSF2] = useState(false);
  const [SF3, setSF3] = useState(false);
  const [SF4, setSF4] = useState(false);
  // Tooltip state for Dapp airdrop
  const [DF1, setDF1] = useState(false);
  const [DF2, setDF2] = useState(false);
  const [DF3, setDF3] = useState(false);
  const [DF4, setDF4] = useState(false);

  useEffect(() => {
    if (!tokenPrices?.ethereum) {
      const ids = 'bitcoin,ethereum,binancecoin,matic-network';
      dispatch(getTokenPrices({ ids }));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>JetPad - Create-Airdrop</title>
        <meta
          name="description"
          content="Create Airdrop for your project"
        />
        <meta property="og:title" content="JetPad - Create-Airdrop" />
        <meta
          property="og:description"
          content="Create Airdrop for your project"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      <section className="flex flex-col justify-start bg-mainBg text-content items-center h-full">
        <div className=" my-8 mx-8 w-full flex flex-col  justify-center items-center">
          <h2 className=" font-extrabold text-3xl mb-10 mt-5 text-center text-content">Create New Airdrop</h2>
          <div className="px-8 gap-4 lg:gap-14 w-full grid grid-cols-1 md:grid-cols-2 place-content-center place-items-center">
            <div className="flex flex-col items-center bg-subtleBg border-t-8 border-accent-1 rounded-md  w-[95%] md:w-[80%] shadow-xl shadow-content">
              <h3 className="font-mono text-2xl font-extrabold text-center text-[#00d1ae] ">Social Engagement Airdrop</h3>
              <div className="w-full flex flex-col items-center my-4">
                <p>
                  <span className=" text-xl text-subText line-through">$150</span>
                  <span className=" font-bold text-6xl">$75</span>
                </p>
                <p className=" text-sm text-subText ">
                  {`
                    ${(75 / tokenPrices?.binancecoin?.usd || 0).toFixed(2)}BNB / 
                    ${(75 / tokenPrices?.ethereum?.usd || 0).toFixed(2)}ETH / 
                    ${(75 / tokenPrices['matic-network']?.usd || 0).toFixed(0)}MATIC
                  `}
                </p>
              </div>
              <button
                type="button"
                className="self-center bg-mainHeading text-mainBg hover:bg-mainHeading/50 px-10 py-2 rounded-xl  text-lg  font-bold mb-2"
                onClick={() => navigate('/create-airdrop/social')}
              >
                CREATE AIRDROP
              </button>
              <p className="w-full px-4 py-6 bg-accent-1 text-center my-6">
                Leave behind the outdated Telegram bot, Use JetPad Today!!!.
              </p>
              <div className=" self-start pl-4">
                <span className=" font-bold">
                  You Get Complete Access to:
                </span>
                <ul>
                  <li className="flex">
                    <span className="text-[#21BF96] text-lg pr-2">&#10003;</span>
                    Create Token Airdrop
                    <ClickToolTip
                      openState={SF1}
                      setOpenState={setSF1}
                      title="Enable users to join your Telegram group and channel, follow your Twitter page, and retweet your pinned post. Our robust system automatically verifies these actions, ensuring that only genuine participants gain access to your airdrop."
                    />
                  </li>
                  <li className="flex">
                    <span className="text-[#21BF96] text-lg pr-2">&#10003;</span>
                    Track and Manage participants
                    <ClickToolTip
                      openState={SF2}
                      setOpenState={setSF2}
                      title="Get access to Admin Dashboard to track and manage airdrop participations and referrals."
                    />
                  </li>
                  <li className="flex">
                    <span className="text-[#21BF96] text-lg pr-2">&#10003;</span>
                    Bulk Email Sender
                    <ClickToolTip
                      openState={SF3}
                      setOpenState={setSF3}
                      title="Send important updates directly to paticipants emails in few clicks."
                    />
                  </li>
                  <li className="flex">
                    <span className="text-[#21BF96] text-lg pr-2">&#10003;</span>
                    Free on-chain Distribution
                    <ClickToolTip
                      openState={SF4}
                      setOpenState={setSF4}
                      title="Distribute your airdrop token directly to all participants wallet with our multi-token sender for free. Get token to airdrops wallet in 3 simple clicks."
                    />
                  </li>
                </ul>
              </div>
              <div className=" my-4 w-full flex flex-col items-center gap-3">
                <span className=" px-2 font-bold text-center">Supported Chains</span>
                <p className="flex gap-3 text-sm text-subText">
                  <span className="flex gap-1">
                    <img src={BSCLogo} alt="network logo" className="  w-auto h-[16px]" />
                    BNB Chain
                  </span>
                  <span className="flex gap-1">
                    <img src={ETHLogo} alt="network logo" className="  w-auto h-[16px]" />
                    Ethereum
                  </span>
                  <span className="flex gap-1">
                    <img src={POLYGONLogo} alt="network logo" className="  w-auto h-[16px]" />
                    Polygon
                  </span>
                </p>
              </div>
              <br />
              <br />
              <button
                type="button"
                className="self-center border border-mainHeading text-mainHeading hover:bg-mainHeading/50 px-10 py-2 rounded-xl  text-lg  font-bold mb-2"
                onClick={() => navigate('/create-airdrop/social')}
              >
                CREATE AIRDROP NOW!!!
              </button>
            </div>
            <div className="flex flex-col items-center bg-subtleBg border-t-8 border-accent-2 rounded-md  w-[95%] md:w-[80%] shadow-xl shadow-content h-full">
              <h3 className="font-mono text-2xl font-extrabold text-center text-[#e248f0] ">DAppLaunch Pro</h3>
              <div className="w-full flex flex-col items-center my-4">
                <p>
                  <span className=" text-xl text-subText line-through">$180</span>
                  <span className=" font-bold text-6xl">$99</span>
                </p>
                <p className=" text-sm text-subText ">
                  {`
                    ${(99 / tokenPrices?.binancecoin?.usd || 0).toFixed(2)}BNB / 
                    ${(99 / tokenPrices?.ethereum?.usd || 0).toFixed(2)}ETH / 
                    ${(99 / tokenPrices['matic-network']?.usd || 0).toFixed(0)}MATIC
                  `}
                </p>
              </div>
              <button
                type="button"
                className="self-center bg-mainHeading text-mainBg hover:bg-mainHeading/50 px-10 py-2 rounded-xl  text-lg  font-bold mb-2"
                onClick={() => navigate('/create-airdrop/dapp')}
              >
                LAUNCH DApp AIRDROP
              </button>
              <p className="w-full px-4 py-6 bg-accent-2 text-center my-6">
                Launch Airdrop Claim Dapp with Ready-Made Templates!!!.
              </p>
              <div className=" self-start pl-4">
                <span className=" font-bold">
                  You Get Complete Access to:
                </span>
                <ul>
                  <li className="flex">
                    <span className="text-[#21BF96] text-lg pr-2">&#10003;</span>
                    DApp Building - With just texts
                    <ClickToolTip
                      openState={DF1}
                      setOpenState={setDF1}
                      title="No complexities and technical hurdles, launch your airdrop DApp with our ready made templates - No Coding!"
                    />
                  </li>
                  <li className="flex">
                    <span className="text-[#21BF96] text-lg pr-2">&#10003;</span>
                    Deploy DApp to the blockchain instantly
                    <ClickToolTip
                      openState={DF2}
                      setOpenState={setDF2}
                      title="Once Your Airdrop DApp is live, Users can effortlessly claim and interact with your token, enhancing engagement and adoption.."
                    />
                  </li>
                  <li className="flex">
                    <span className="text-[#21BF96] text-lg pr-2">&#10003;</span>
                    Fully Functional Referral feature
                    <ClickToolTip
                      openState={DF3}
                      setOpenState={setDF3}
                      title="Each user get a unique referral link - users get pre-set referral reward for each successful claim of their referee"
                    />
                  </li>
                  <li className="flex">
                    <span className="text-[#21BF96] text-lg pr-2">&#10003;</span>
                    Double Claim Protection
                    <ClickToolTip
                      openState={DF4}
                      setOpenState={setDF4}
                      title="JetPad smart contract ensures that each user only claims the airdrop once. No double or multiple claims"
                    />
                  </li>
                </ul>
              </div>
              <div className=" my-4 w-full flex flex-col items-center gap-3">
                <span className=" px-2 font-bold text-center">Supported Chains</span>
                <p className="flex gap-3 text-sm text-subText">
                  <span className="flex gap-1">
                    <img src={BSCLogo} alt="network logo" className="  w-auto h-[16px]" />
                    BNB Chain
                  </span>
                  <span className="flex gap-1">
                    <img src={ETHLogo} alt="network logo" className="  w-auto h-[16px]" />
                    Ethereum
                  </span>
                  <span className="flex gap-1">
                    <img src={POLYGONLogo} alt="network logo" className="  w-auto h-[16px]" />
                    Polygon
                  </span>
                </p>
              </div>
              <br />
              <br />
              <button
                type="button"
                className="self-center border border-mainHeading text-mainHeading hover:bg-mainHeading/50 px-10 py-2 rounded-xl  text-lg  font-bold mb-2"
                onClick={() => navigate('/create-airdrop/dapp')}
              >
                CREATE AIRDROP NOW!!!
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateAirdrop;

const ClickToolTip = ({
  openState, setOpenState, title,
}) => (
  <>
    <Tooltip
      title={title}
      placement="top"
      TransitionComponent={Zoom}
      arrow
    >
      <span className=" self-center hidden lg:inline-block text-center mx-1 h-4 w-4 rounded-full bg-gray-400 text-white overflow-hidden">i</span>
    </Tooltip>
    <ClickAwayListener onClickAway={() => setOpenState(false)}>
      <div className="flex lg:hidden">
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setOpenState(false)}
          open={openState}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={title}
          placement="top"
          TransitionComponent={Zoom}
          arrow
        >
          <button
            type="button"
            onClick={() => setOpenState(true)}
            className=" self-center inline-block text-center mx-1 h-4 w-4 rounded-full bg-gray-400 text-white overflow-hidden"
          >
            i
          </button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  </>
);

ClickToolTip.propTypes = {
  openState: PropTypes.bool.isRequired,
  setOpenState: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
