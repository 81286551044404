import React, { useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { widget } from '../lib/tradingview/charting_library';
import Datafeed from '../lib/tradingview/custom_datafeeds/datafeed_custom';

const TVChartContainer = () => {
  const chartContainerRef = useRef();
  const { network, address } = useParams();

  let tvWidget = null;
  useEffect(() => {
    const widgetOptions = {
      symbol: JSON.stringify({ network, address }),
      datafeed: Datafeed,
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      interval: ['5', '60'],
      timezone: 'Africa/Lagos',

      locale: 'en',
      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
        'symbol_search_hot_key',
        'compare_symbol',
      ],
      enabled_features: [],
      charts_storage_url: 'https://saveload.tradingview.com',
      charts_storage_api_version: '1.1',

      client_id: 'tradingview.com',
      user_id: 'public_user_id',
      fullscreen: false,
      autosize: true,
      studies_overrides: {},
      supports_marks: true,
      supports_timescale_marks: true,
      theme: 'dark',

      overrides: {
        'mainSeriesProperties.statusViewStyle.showInterval': true,
        'mainSeriesProperties.statusViewStyle.symbolTextSource': 'ticker',
      },
    };
    // eslint-disable-next-line new-cap
    tvWidget = new widget(widgetOptions);

    const currentTime = Date.now();
    const twentyFourHoursAgo = currentTime - (24 * 60 * 60 * 1000);

    tvWidget.onChartReady(() => {
      tvWidget
        .activeChart()
        .setVisibleRange(
          { from: twentyFourHoursAgo, to: currentTime },
          { percentRightMargin: 2 },
        );
    });

    return () => {
      tvWidget.remove();
    };
  }, [network, address]);

  return (
    <div
      ref={chartContainerRef}
      style={{ height: '700px', backgroundColor: 'black' }}
    />
  );
};

export default TVChartContainer;
