import axios from "axios";
import { convertOHLCVFormat, extractHexPart } from "../../../utils";

const GCTBASEURL = 'https://api.geckoterminal.com/api/v2/';

export const getOhlcvData = async ({ network, poolAddress, limit, beforeTimestamp }) => {
  let timeframe = 'minute';
  let aggregate = 5;

  const res = await axios.get(`
  ${GCTBASEURL}networks/${network}/pools/${poolAddress}/ohlcv/${timeframe}?aggregate=${aggregate}&before_timestamp=${beforeTimestamp}&limit=${limit}
  `, {
    headers: {
      Accept: 'application/json;version=20230302',
    },
  });
  return convertOHLCVFormat(res.data?.data?.attributes?.ohlcv_list);
}

export const getTokenByAddress = async ({ network, address }) => {
  const response = await axios.get(`${GCTBASEURL}networks/${network}/tokens/${address}`, {
    headers: {
      Accept: 'application/json;version=20230302',
    },
  });
  
  let poolAddress = response.data?.data?.relationships?.top_pools?.data[0].id;
  poolAddress = extractHexPart(poolAddress);
  const name = response?.data?.data?.attributes?.name
  const symbol = response?.data?.data?.attributes?.symbol

  const data = { poolAddress, name, symbol };
  return data;
}
