import React from 'react';
import { PropTypes } from 'prop-types';
import { Loader } from 'rsuite';

// ICONS
import { AiOutlineCloseCircle } from 'react-icons/ai';

const ConfirmPopup = ({
  handleClick, setActive, title, message, creatingMSG,
}) => (
  <section className="w-full animated fadeInUp h-full z-40 fixed bg-mainBg bg-opacity-80 top-0 bottom-0 left-0 flex justify-center items-center">
    <div className="bg-subtleBg relative border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col text-content shadow-md shadow-accent-1">
      <AiOutlineCloseCircle className="absolute right-5 hover:text-red-500 text-xl" onClick={() => setActive(false)} />
      <h2 className=" font-extrabold leading-3 text-3xl mb-10 mt-5 text-center">{title}</h2>
      <div className=" text-subText flex justify-center items-center flex-col gap-3 text-xs md:text-base">
        <p className=" text-center">
          {message}
        </p>
        <div className="w-full flex justify-center items-center">
          <button
            type="button"
            className="bg-mainHeading text-mainBg hover:bg-mainHeading/50 text-lg px-2 py-1 rounded-lg font-bold w-[80%]"
            onClick={handleClick}
          >
            {
            creatingMSG === '' ? 'Confirm' : (
              <>
                <Loader size="sm" content={creatingMSG} speed="fast" inverse />
              </>
            )
          }
          </button>
        </div>
      </div>
    </div>
  </section>
);

ConfirmPopup.propTypes = {
  setActive: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  creatingMSG: PropTypes.string.isRequired,
};

export default ConfirmPopup;
