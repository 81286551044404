import { getTokenByAddress } from "./helpers";

export const resolveSymbol = async (
  symbolName,
  onSymbolResolvedCallback,
  onResolveErrorCallback,
  extension
) => {
  //get token symbol from query or hardcode it
  // console.log(`[resolveSymbol]: Method called!! ${symbolName}`);
  const data = JSON.parse(symbolName)
  const { address, network } = data;
  
  const response = await getTokenByAddress({ address, network });
  const { poolAddress, name, symbol } = response;

  // {network, poolAddress, timeframe, aggregate, limit}
  if (!poolAddress) {
    onResolveErrorCallback();
  } else {
    const symbolInfo = {
      ticker: symbol,
      name: `${name}/USDT`,
      exchange: network,
      network: network,
      poolAddress: poolAddress,
      session: "24x7",
      timezone: "Africa/Lagos",
      minmov: 1,
      pricescale: 1000,
      has_intraday: true,
      intraday_multipliers: ["1", "5", "15", "30", "60"],
      has_empty_bars: false,
      has_weekly_and_monthly: false,
      supported_resolutions: ["1", "5", "15", "30", "60", "1D", "1W", "1M"],
      supported_intervals: ["1", "5", "15", "30", "60", "1D", "1W", "1M"],
      // volume_precision: 1,
      // data_status: "streaming",
      countBack: 30,
      volume_precision: 2,
      visible_plots_set: 'ohlcv',
    };
    onSymbolResolvedCallback(symbolInfo);
  }
};
