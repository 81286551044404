import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  // startAt,
  where,
} from 'firebase/firestore';
import { db } from '../../firebase';

// UTILITIES
import { formatTimeStamp, updateReferral, updateUserAirdropRef } from '../../utils';

// BASE URL
const BASEURL = process.env.REACT_APP_MORALIS_API_URL;

const initialState = {
  loading: false,
  isLoggedIn: false,
  airdrops: [],
  featuredAirdrop: [],
  totalAirdrops: 0,
  lastVisibleID: null,
};

// Action-types
const CREATE_AIRDROP = 'Firebase/firebase/CREATE_AIRDROP';
const CREATE_DAPP_AIRDROP = 'Firebase/firebase/CREATE_DAPP_AIRDROP';
const GET_ALL_AIRDROPS = 'Firebase/firebase/GET_ALL_AIRDROPS';
const GET_NEXT_AIRDROPS = 'Firebase/firebase/GET_NEXT_AIRDROPS';
const GET_AIRDROP_DETAILS = 'Firebase/firebase/GET_AIRDROP_DETAILS';
const JOIN_AIRDROP = 'Firebase/firebase/JOIN_AIRDROP';
const GET_FEATURED_AIRDROP = 'Firebase/firebase/GET_FEATURED_AIRDROP';
const CHECK_FOLLOW_STATUS = 'Firebase/firebase/CHECK_FOLLOW_STATUS';
const CHECK_RETWEET_STATUS = 'Firebase/firebase/CHECK_RETWEET_STATUS';
const CHECK_TELEGRAM_MEMBERSHIP = 'Firebase/firebase/CHECK_TELEGRAM_MEMBERSHIP';
const GET_DAPP_DETAILS = 'Firebase/firebase/GET_DAPP_DETAILS';
const JOIN_DAPP_AIRDROP = 'Firebase/firebase/JOIN_DAPP_AIRDROP';
const UPDATE_AIRDROP_PROPS = 'Firebase/firebase/UPDATE_AIRDROP_PROPS';

// Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${CREATE_AIRDROP}/pending`:
      return { ...state, loading: true };
    case `${CREATE_AIRDROP}/fulfilled`:
      return { ...state, ...action.payload, loading: false };
    case `${CREATE_AIRDROP}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${GET_ALL_AIRDROPS}/pending`:
      return { ...state, loading: true };
    case `${GET_ALL_AIRDROPS}/fulfilled`:
      return {
        ...state,
        airdrops: [...action.payload.airdrops],
        totalAirdrops: action.payload.count,
        lastVisibleID: action.payload.lastVisibleID,
        loading: false,
      };
    case `${GET_ALL_AIRDROPS}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${GET_NEXT_AIRDROPS}/pending`:
      return { ...state, loading: true };
    case `${GET_NEXT_AIRDROPS}/fulfilled`:
      return {
        ...state,
        airdrops: [...state.airdrops, ...action.payload.airdrops],
        lastVisibleID: action.payload.lastVisibleID,
        loading: false,
      };
    case `${GET_NEXT_AIRDROPS}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${GET_AIRDROP_DETAILS}/pending`:
      return { ...state, loading: true };
    case `${GET_AIRDROP_DETAILS}/fulfilled`:
      return { ...state, ...action.payload, loading: false };
    case `${GET_AIRDROP_DETAILS}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    case `${GET_FEATURED_AIRDROP}/pending`:
      return { ...state, loading: true };
    case `${GET_FEATURED_AIRDROP}/fulfilled`:
      return { ...state, featuredAirdrop: [...action.payload], loading: false };
    case `${GET_FEATURED_AIRDROP}/rejected`:
      return { ...state, loading: false, error: action.error.message };
    default:
      return state;
  }
}

// Action Creators
// CREATE AIRDROP
export const createSocialAirdrop = createAsyncThunk(CREATE_AIRDROP,
  async (data, { rejectWithValue }) => {
    try {
      const docRef = await addDoc(collection(db, 'Airdrops'), {
        ...data,
        timeStamp: serverTimestamp(),
        participants: [],
        noOfPart: 0,
        totalClaimed: 0,
        distributed: false,
      });

      const userDocRef = doc(collection(db, 'users'), data.userID);
      const snapshot = await getDoc(userDocRef);

      const updatedData = snapshot.data();
      const {
        projectName, networkName, startDate, endDate, contractAddress,
        tokenName, ticker, airdropRound, totalToken, logoLink,
      } = data;
      const newAirdropData = {
        airdropType: 'Social Airdrop',
        airdropID: docRef.id,
        timeStamp: JSON.stringify(new Date()),
        projectName,
        networkName,
        startDate,
        endDate,
        contractAddress,
        tokenName,
        ticker,
        airdropRound,
        totalToken,
        logoLink,
      };

      const createdAirdrops = [...updatedData.createdAirdrops, { ...newAirdropData }];
      await updateDoc(userDocRef, { createdAirdrops });
      return { airID: docRef.id };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// CREATE AIRDROP
export const createDappAirdrop = createAsyncThunk(CREATE_DAPP_AIRDROP,
  async (data, { rejectWithValue }) => {
    try {
      const docRef = await addDoc(collection(db, 'DappAirdrops'), {
        ...data,
        timeStamp: serverTimestamp(),
        participants: [],
        noOfPart: 0,
        totalClaimed: 0,
      });

      const newDocId = docRef.id;
      const dappURL = `${data.template}${newDocId}`;

      await updateDoc(docRef, { dappURL });

      const userDocRef = doc(collection(db, 'users'), data.userID);
      const snapshot = await getDoc(userDocRef);

      const updatedData = snapshot.data();
      const {
        projectName, networkName, startDate, endDate, contractAddress, airdropCA,
        tokenName, ticker, airdropRound, totalToken, logoLink, airdropIndexID, networkID,
      } = data;
      const newAirdropData = {
        airdropType: 'Dapp Airdrop',
        airdropID: docRef.id,
        timeStamp: JSON.stringify(new Date()),
        projectName,
        networkName,
        networkID,
        startDate,
        endDate,
        contractAddress,
        tokenName,
        ticker,
        airdropRound,
        totalToken,
        logoLink,
        dappURL,
        airdropIndexID,
        airdropCA,
      };

      const createdAirdrops = [...updatedData.createdAirdrops, { ...newAirdropData }];
      await updateDoc(userDocRef, { createdAirdrops });

      return { airID: newDocId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// FETCH AIRDROPS
export const getAllAirdrops = createAsyncThunk(GET_ALL_AIRDROPS,
  async (_, { rejectWithValue }) => {
    try {
      const airdropRef = collection(db, 'Airdrops');
      const docSnapshot = await getDocs(airdropRef);
      const count = docSnapshot.size;

      const first = query(airdropRef, orderBy('timeStamp', 'desc'), limit(8));
      const docQuerrySnapshot = await getDocs(first);
      const data = docQuerrySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      const lastVisibleID = data[data.length - 1].id;
      const airdrops = await formatTimeStamp(data);

      return { airdrops, count, lastVisibleID };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// FETCH AIRDROPS
export const getNextAirdrops = createAsyncThunk(GET_NEXT_AIRDROPS,
  async (lastID, { rejectWithValue }) => {
    try {
      const lastDocRef = doc(collection(db, 'Airdrops'), lastID);
      const snapshot = await getDoc(lastDocRef);

      const airdropRef = collection(db, 'Airdrops');
      const nextAirdropQuerry = query(
        airdropRef,
        orderBy('timeStamp', 'desc'),
        startAfter(snapshot),
        limit(2),
      );
      const docSnapshot = await getDocs(nextAirdropQuerry);
      const data = docSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      const lastVisibleID = data[data.length - 1].id;
      const airdrops = await formatTimeStamp(data);

      return { airdrops, lastVisibleID };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// GET AIRDROP DETAILS
export const getAirdropDetails = createAsyncThunk(GET_AIRDROP_DETAILS,
  async (id, { rejectWithValue }) => {
    try {
      const docRef = doc(collection(db, 'Airdrops'), id);
      const snapshot = await getDoc(docRef);
      if (snapshot.exists()) {
        const data = snapshot.data();
        const { timeStamp, ...newData } = data;
        return newData;
      }
      // return rejectWithValue('Airdrop Not Found');
      return {};
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// JOIN AIRDROP
export const joinAirdrop = createAsyncThunk(JOIN_AIRDROP, async (data, { rejectWithValue }) => {
  const {
    id, newParticipant, referralID, projectName, networkName, logoLink, telegramGroup,
    endDate, contractAddress, airdropAmount, referralAmount, userID, ticker, estLaunchPrice,
  } = data;

  try {
    const docRef = doc(collection(db, 'Airdrops'), id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      const data = snapshot.data();
      let newData = {
        ...data,
        participants: [...data.participants, newParticipant],
        noOfPart: data.noOfPart += 1,
        totalClaimed: data.totalClaimed += Number(airdropAmount),
      };

      if (referralID) {
        newData.participants = await updateReferral(
          newData.participants, referralID, referralAmount,
        );
        newData = {
          ...newData, totalClaimed: newData.totalClaimed += Number(referralAmount),
        };
      }
      await setDoc(docRef, newData);

      // Update users joined airdrop array
      const userDocRef = doc(collection(db, 'users'), userID);
      const userSnapshot = await getDoc(userDocRef);

      const updatedData = userSnapshot.data();

      const newJoinedAirdropData = {
        airdropType: 'Social Airdrop',
        airdropID: id,
        timeStamp: JSON.stringify(new Date()),
        refCount: 0,
        refBalance: 0,
        projectName,
        networkName,
        logoLink,
        endDate,
        contractAddress,
        airdropAmount,
        ticker,
        telegramGroup,
        estLaunchPrice,
      };
      const joinedAirdrops = [...updatedData.joinedAirdrops, { ...newJoinedAirdropData }];
      await updateDoc(userDocRef, { joinedAirdrops });

      if (referralID) {
        const refUserDocRef = doc(collection(db, 'users'), referralID);
        const refUserSnapshot = await getDoc(refUserDocRef);

        if (refUserSnapshot.exists()) {
          const refUpdatedData = refUserSnapshot.data();

          const joinedAirdrops = await updateUserAirdropRef(
            id, referralAmount, refUpdatedData.joinedAirdrops,
          );

          await updateDoc(refUserDocRef, { joinedAirdrops });
        }
      }

      return { };
    }
    return rejectWithValue('Airdrop Not Found');
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// GET FEATURED AIRDROP
export const getFeatured = createAsyncThunk(GET_FEATURED_AIRDROP,
  async (_, { rejectWithValue }) => {
    try {
      const airdropRef = collection(db, 'Airdrops');

      const queryRef = query(
        airdropRef,
        where('featured', '==', true),
        orderBy('featured', 'desc'),
      );
      const docSnapshot = await getDocs(queryRef);

      if (!docSnapshot.empty) {
        const data = docSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const featuredAirdrops = await formatTimeStamp(data);

        return featuredAirdrops;
      }
      return [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// CHECK USER FOLLOW STATUS
export const checkFollowStatus = createAsyncThunk(CHECK_FOLLOW_STATUS,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASEURL}check-follow`, {
        params: data,
      });

      if (response.error) {
        return rejectWithValue('Failed to verify');
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// CHECK RETWEET STATUS
export const checkRetweetStatus = createAsyncThunk(CHECK_RETWEET_STATUS,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASEURL}check-retweet`, {
        params: data,
      });

      if (response.error) {
        return rejectWithValue('Failed to verify');
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// CHECK TELEGRAM GROUP MEMBERSHIP
export const checkTelegramMemebership = createAsyncThunk(CHECK_TELEGRAM_MEMBERSHIP,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASEURL}check-membership`, {
        params: data,
      });

      if (response.error) {
        return rejectWithValue('Failed to verify');
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.err.message);
    }
  });

// GET DAPP AIRDROP DETAILS
export const getDappDetails = createAsyncThunk(GET_DAPP_DETAILS,
  async (id, { rejectWithValue }) => {
    try {
      const docRef = doc(collection(db, 'DappAirdrops'), id);
      const snapshot = await getDoc(docRef);
      if (snapshot.exists()) {
        const data = snapshot.data();
        delete data.timeStamp;
        return data;
      }
      return rejectWithValue('Dapp Airdrop Not Found');
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// JOIN DAPP AIRDROP
export const joinDappAirdrop = createAsyncThunk(JOIN_DAPP_AIRDROP,
  async (data, { rejectWithValue }) => {
    const {
      id, address, airdropAmount, referralID, referralAmount,
    } = data;

    try {
      const docRef = doc(collection(db, 'DappAirdrops'), id);
      const snapshot = await getDoc(docRef);

      if (snapshot.exists()) {
        const data = snapshot.data();
        let newData = {
          ...data,
          participants: [...data.participants, { address }],
          noOfPart: data.noOfPart += 1,
          totalClaimed: data.totalClaimed += Number(airdropAmount),
        };

        if (referralID) {
          newData = {
            ...newData, totalClaimed: newData.totalClaimed += Number(referralAmount),
          };
        }
        await setDoc(docRef, newData);

        return 'Airdrop data updated successfully';
      }
      return rejectWithValue('Airdrop Not Found');
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

// UPDATE AIRDROP DISTRIBUTION STATUS
export const updateAirdropProps = createAsyncThunk(UPDATE_AIRDROP_PROPS,
  async (data, { rejectWithValue }) => {
    const { id, distributed, explorerLink } = data;

    try {
      const docRef = doc(collection(db, 'Airdrops'), id);
      const snapshot = await getDoc(docRef);

      if (snapshot.exists()) {
        const data = snapshot.data();
        const newData = { ...data, distributed, explorerLink };

        await setDoc(docRef, newData);

        return 'Airdrop data updated successfully';
      }
      return rejectWithValue('Airdrop Not Found');
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
