import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Helmet } from 'react-helmet';

// REDUX
import { getAllAirdrops, getFeatured, getNextAirdrops } from '../Redux/FirebaseAirdrop/firebaseAirdrop';

// COMPONENTS
import {
  AirdropCard, FeaturedAirdropCard, AirdropPopup, AirdropSkeleton,
} from '../components';

// UTILITIES
import { openInNewTab } from '../utils';

const Airdrops = () => {
  const [acitve, setActive] = useState(false);
  const [acitveAirdrop, setActiveAirdrop] = useState({});
  const [hasMore, setHasMore] = useState(true);

  const airdropList = useSelector((state) => state.firbaseAirdrop?.airdrops);
  const lastVisibleID = useSelector((state) => state.firbaseAirdrop?.lastVisibleID);
  const totalAirdrops = useSelector((state) => state.firbaseAirdrop?.totalAirdrops);
  const featuredAirdrop = useSelector((state) => state.firbaseAirdrop?.featuredAirdrop);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchMoreData = () => {
    setTimeout(() => {
      if (airdropList.length >= totalAirdrops) {
        setHasMore(false);
      } else {
        dispatch(getNextAirdrops(lastVisibleID));
      }
    }, 1000);
  };

  useEffect(() => {
    if (airdropList.length === 0) {
      dispatch(getAllAirdrops());
    }
    if (featuredAirdrop.length === 0) {
      dispatch(getFeatured());
    }
  }, []);

  const showAirdropDetails = (id) => {
    const actAirdrop = airdropList.filter((airdrop) => airdrop.id === id);
    setActiveAirdrop(actAirdrop[0]);
    setActive(true);
  };

  return (
    <>
      <Helmet>
        <title>JetPad - Airdrops</title>
        <meta
          name="description"
          content="All airdrops on jetpad"
        />
        <meta property="og:title" content="JetPad - Airdrops" />
        <meta
          property="og:description"
          content="All airdrops on jetpad"
        />
        <meta property="og:image" content="https://pbs.twimg.com/media/GFzaA9-WUAAjkpk?format=jpg&name=large" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      <section className="relative flex justify-center bg-mainBg text-content items-center">
        <div className=" my-8 mx-4 w-full flex flex-col gap-10 justify-center items-center">
          <div className="flex flex-col w-full gap-5 items-center">
            <div className="flex justify-center w-full md:w-[60%] lg:w-[55%] bg-subtleBg border border-accent-1 shadow-md shadow-accent-1 px-5 pt-5 rounded-lg font-extrabold text-2xl lg:text-3xl">
              <ul className="w-full  flex justify-evenly items-center text-lg font-semibold">
                <li className=" cursor-pointer hover:text-mainHeading">
                  <button
                    type="button"
                    className=" cursor-pointer hover:text-mainHeading text-sm md:text-base border-b border-b-mainHeading"
                    onClick={() => openInNewTab('https://docs.jetpad.finance/important/how-to-join-airdrop')}
                  >
                    How To Join
                  </button>
                </li>
                <li className=" cursor-pointer hover:text-mainHeading">
                  <button
                    type="button"
                    className=" cursor-pointer hover:text-mainHeading text-sm md:text-base border-b border-b-mainHeading "
                    onClick={() => navigate('/my-airdrops')}
                  >
                    My Airdrops
                  </button>
                </li>
                <li className=" cursor-pointer hover:text-mainHeading">
                  <button
                    type="button"
                    className=" cursor-pointer hover:text-mainHeading text-sm md:text-base border-b border-b-mainHeading"
                    onClick={() => navigate('/create-airdrop')}
                  >
                    Create Airdrop
                  </button>
                </li>
              </ul>
            </div>
            <div className="flex justify-center items-center w-full">
              <input
                type="text"
                placeholder="Search Airdrop"
                className="w-[50%] border-accent-1 border-2 text-center rounded-xl py-1 hover:border-accent-2"
                readOnly
              />
            </div>
            <div className="flex flex-col gap-6 w-[100%] md:w-[80%] px-2 py-3 rounded-lg min-h-screen bg-subtleBg border border-accent-1 shadow-md shadow-accent-1">
              <div>
                <InfiniteScroll
                  dataLength={airdropList.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<AirdropSkeleton count={4} />}
                  className="w-full flex flex-col lg:grid lg:grid-cols-2 gap-4 lg:gap-4 pt-5"
                >
                  {airdropList && featuredAirdrop.length !== 0
                && airdropList.map((airdrop, index) => ([
                  <AirdropCard
                    key={airdrop?.id}
                    airdrop={airdrop}
                    showAirdropDetails={showAirdropDetails}
                  />,
                  index !== 0 && (index + 1) % 4 === 0 && (
                  <div className="flex justify-center items-center col-span-2 my-3" key={`${airdrop?.id}-index`}>
                    <FeaturedAirdropCard
                      showAirdropDetails={showAirdropDetails}
                      FA={featuredAirdrop[Math.floor(Math.random() * featuredAirdrop.length)]}
                    />
                  </div>
                  ),
                ]))}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </section>
      {acitve && <AirdropPopup setActive={setActive} acitveAirdrop={acitveAirdrop} />}

    </>
  );
};

export default Airdrops;
