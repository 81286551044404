import React, { useState, useEffect } from 'react';
import { CiTwitter } from 'react-icons/ci';
import { TbBrandTelegram } from 'react-icons/tb';

// COMPONENTS
import DappTimmer from '../../components/DappTemplates/DappTimmer';

// ASSETS
import {
  pLogoc,
  binanceLogo,
  pancakeLogo,
  pLogoLight,
  blob1,
  blob2,
} from '../../assets/images';

const DappT2 = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const targetDate = '2023-10-05T00:00:00';

  return (
    <>
      <header
        className={`${
          scrolled
            ? 'bg-white fixed top-0 left-0 w-full transform translate-y-0 transition-transform duration-300 ease-in-out z-10'
            : 'bg-gradient-to-r from-[#ffa31d] to-[#ef5454]'
        }`}
      >
        <nav className="flex items-center justify-between w-full px-3 py-4 lg:px-6">
          <img
            src={scrolled ? pLogoc : pLogoLight}
            alt="project logo"
            className={`${
              scrolled ? 'h-[41px] w-[180px]' : 'h-[43px] w-[190px]'
            }`}
          />
          <div>
            <button
              type="button"
              className=" px-5 py-2 rounded-full text-white bg-gradient-to-r from-[#293488] to-[#d102b6] hover:bg-gradient-to-r hover:from-[#d102b6] hover:to-[#fa3866]"
            >
              Connect Wallet
            </button>
          </div>
        </nav>
      </header>
      <section className="dapp-hero-t2 relative min-h-screen flex flex-col justify-center items-center px-[10%] md:px-[16%] lg:px-[10%]">
        <img
          src={blob1}
          alt="Background blob"
          className="image-bg absolute h-[30rem] w-[20rem] top-0 left-9 z-[3]"
        />
        <img
          src={blob2}
          alt="Background blob"
          className="image-bg absolute h-[30rem] w-[20rem] z-[3]"
        />
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-20 lg:gap-26 w-full mt-[15%] lg:mt-[10%] z-[8]">
          <div className="flex flex-col gap-2 font-barlow">
            <h1 className=" font-normal text-[23px] leading-[38px] md:text-[40px] md:leading-[52px]">
              Powering Data for the new equity blockchain
            </h1>
            <p className="border-l-2 border-l-[#8488c0] pl-4 text-[14px] md:text-[16px] md:w-[70%] leading-[30px] my-2">
              Our company specializes on Blockchain Technologies, Smart
              Contracts, Initial Coin Offering (ICO) and Digital Currencies
              Investments
            </p>
            <div className="flex items-center justify-between w-full md:w-[70%]">
              <button
                type="button"
                className=" font-thin px-5 py-1 bg-[#c793d8] rounded-2xl hover:bg-white hover:text-black transition duration-200 ease-in-out"
              >
                WEBSITE
              </button>
              <button
                type="button"
                className=" font-thin px-5 py-1 border border-[#f6ba2b] bg-transparent rounded-2xl hover:bg-white hover:text-black transition duration-200 ease-in-out"
              >
                White Paper
              </button>
            </div>
            <ul className="flex gap-3 items-center mt-4">
              <li>
                <CiTwitter />
              </li>
              <li>
                <TbBrandTelegram />
              </li>
              <li>
                <TbBrandTelegram />
              </li>
            </ul>
          </div>
          <div className="bg-[#F0621F] flex flex-col gap-2 items-center rounded-lg p-4 font-barlow lg:w-[480px]">
            <div className="w-full">
              <DappTimmer targetDate={targetDate} />
            </div>
            <button
              type="button"
              className=" px-7 py-2 rounded-full text-white bg-gradient-to-r from-[#fa3866] to-[#d102b6] hover:bg-gradient-to-r hover:from-[#d102b6] hover:to-[#fa3866]"
            >
              Claim Airdrop Now
            </button>
            <div className=" my-4 flex justify-between w-full items-center">
              <p className="flex flex-col justify-center items-start">
                <span className="text-[25px]">450MRT</span>
                <span className="text-[16px] font-medium text-[#192265]">
                  Airdrop Amount
                </span>
              </p>
              <p className="flex flex-col justify-center items-end">
                <span className="text-[25px]">$0.56</span>
                <span className="text-[16px] font-medium text-[#192265]">
                  Est. Launch Price
                </span>
              </p>
            </div>
            <div className="w-full flex flex-col gap-1">
              <span className="text-[16px] font-medium text-[#192265]">
                2,594 MRT Claimed
              </span>
              <div className="w-full h-[15px] rounded-full bg-[#192265]">
                <div className="w-[50%] h-[15px] rounded-full bg-[#ffc935]" />
              </div>
            </div>
            <div className="flex flex-col gap-1 items-start w-full my-4">
              <span className="text-[25px]">Referal link</span>
              <span className="text-[16px] font-medium text-[#192265]">
                Refere and get 250MRT for each referal
              </span>
              <input
                type="text"
                readOnly
                value="http://airdroplink.com"
                className="w-full rounded-lg text-[#3453a1] px-1 py-2 hover:bg-slate-400 cursor-pointer"
              />
            </div>
            <div className="w-full my-1 flex justify-center items-center">
              <span className="text-[25px] text-white">
                Network: POLYGON MATIC
                {' '}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full mt-8 mb-4">
          <ul className="grid grid-cols-3 w-full md:grid-cols-5 place-items-center place-content-center partner-logo-container">
            <li className="w-full h-auto">
              <img
                src={binanceLogo}
                alt="partner logo"
                className="w-full h-auto md:w-4/5 lg:w-4/5 partner-logo"
              />
            </li>
            <li className="w-full h-auto">
              <img
                src={pancakeLogo}
                alt="partner logo"
                className="w-full h-auto md:w-4/5 lg:w-4/5 partner-logo"
              />
            </li>
            <li className="w-full h-auto">
              <img
                src={binanceLogo}
                alt="partner logo"
                className="w-full h-auto md:w-4/5 lg:w-4/5 partner-logo"
              />
            </li>
            <li className="w-full h-auto">
              <img
                src={pancakeLogo}
                alt="partner logo"
                className="w-full h-auto md:w-4/5 lg:w-4/5 partner-logo"
              />
            </li>
            <li className="w-full h-auto">
              <img
                src={binanceLogo}
                alt="partner logo"
                className="w-full h-auto md:w-4/5 lg:w-4/5 partner-logo"
              />
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default DappT2;
