import React from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';

// ICONS
import { AiOutlineClose } from 'react-icons/ai';

// UTILITIES
import { openInNewTab, checkActive } from '../utils';

const AirdropPopup = ({ setActive, acitveAirdrop }) => {
  const {
    airdropDetails,
    airdropAmount,
    ticker,
    referralAmount,
    startDate,
    endDate,
    projectName,
    telegramGroup,
    twitterPageLink,
    projectWebsite,
    projectDetails,
    id,
    whitepaper,
    noOfPart,
  } = acitveAirdrop;

  const navigate = useNavigate();
  return (
    <section className="w-full animated fadeInUp transition-all duration-300 ease-in-out pt-6 z-40 fixed bg-subtleBg bg-opacity-80 left-0 right-0 top-0 bottom-0 justify-center items-center h-full overflow-y-auto overflow-x-hidden">
      <div className=" relative grid grid-cols-1 place-content-start place-items-center min-[0px]:m-0 min-[0px]:h-full">
        <div className=" relative bg-mainBg border border-accent-1 rounded-lg w-[95%] md:w-[70%] p-6 flex flex-col text-content shadow-xl shadow-accent-1">
          <AiOutlineClose className="absolute right-5 text-red-500 font-extrabold text-xl cursor-pointer hover:text-red-600" onClick={() => setActive(false)} />
          <h3 className=" font-extrabold leading-3 text-3xl mb-10 mt-5 text-center">Airdrop Details</h3>
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 bg-subtleBg border border-accent-1 shadow-md shadow-accent-1 rounded-lg">
            <div className="border border-accent-1 rounded p-3 flex flex-col gap-2 justify-center items-center">
              <p className="flex flex-col gap-1 justify-center items-center">
                <span className=" font-mono font-bold text-xl">Amount</span>
                <span className=" font-thin ">
                  {airdropAmount}
                  {ticker}
                </span>
              </p>
              <p className="flex flex-col gap-1 justify-center items-center">
                <span className=" font-mono font-bold text-xl">Referral</span>
                <span className=" font-light text-subText ">
                  {referralAmount}
                  {ticker}
                </span>
              </p>
            </div>
            <div className="border border-accent-1 rounded p-3 flex flex-col gap-2 justify-center items-center">
              <p className="flex flex-col gap-1 justify-center items-center">
                <span className=" font-mono font-bold text-xl">Start Date</span>
                <span className=" font-light text-subText ">{startDate}</span>
              </p>
              <p className="flex flex-col gap-1 justify-center items-center">
                <span className=" font-mono font-bold text-xl">End Date</span>
                <span className=" font-light text-subText ">{endDate}</span>
              </p>
            </div>
            <div className="border border-accent-1 rounded p-3 flex flex-col gap-2 justify-center items-center md:col-span-2 lg:col-span-1">
              <p className="flex flex-col gap-1 justify-center items-center">
                <span className=" font-mono font-bold text-xl">No. Part.</span>
                <span className=" font-light text-subText ">{noOfPart}</span>
              </p>
            </div>
          </div>
          <div>
            <h4 className=" font-extrabold leading-3 text-2xl mb-10 mt-5 text-center underline underline-offset-4">Details</h4>
            <p className=" font-normal text-subText text-center">{airdropDetails}</p>
          </div>
          <div className="mt-5">
            <h4 className=" font-extrabold leading-3 text-3xl mb-5 text-center underline underline-offset-4">Project Details</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 font-extralight">
              <span>
                Name:&nbsp;
                {projectName}
              </span>
              <span>
                Telegram:&nbsp;
                <button
                  type="button"
                  onClick={() => openInNewTab(telegramGroup)}
                  className="font-light text-link hover:text-blue-600 after:content-['_↗']"
                >
                  Telegram Group
                </button>
              </span>
              <span>
                Website:&nbsp;
                <button
                  type="button"
                  onClick={() => openInNewTab(projectWebsite)}
                  className="font-light text-link hover:text-blue-600 after:content-['_↗']"
                >
                  Official Website
                </button>
              </span>
              <span>
                Twitter:&nbsp;
                <button
                  type="button"
                  onClick={() => openInNewTab(twitterPageLink)}
                  className="font-light text-link hover:text-blue-600 after:content-['_↗']"
                >
                  Official Page
                </button>
              </span>
              <span>
                White paper:&nbsp;
                <button
                  type="button"
                  onClick={() => openInNewTab(whitepaper)}
                  className="font-light text-link hover:text-blue-600 after:content-['_↗']"
                >
                  Download
                </button>
              </span>
            </div>
          </div>
          <div className="mt-5">
            <h4 className=" font-extrabold leading-3 text-3xl mb-5 text-center underline underline-offset-4">About</h4>
            <p className=" font-mono text-subText text-center">{projectDetails}</p>
          </div>
          <div className="flex w-full justify-center items-center mt-3">
            <span className=" font-bold italic text-red-500">
              {checkActive(startDate || 0, endDate || 0) && 'Airdrop Have Not Started Or It Have Ended!!!'}
            </span>
          </div>
          <button
            type="button"
            className=" disabled:bg-slate-500 bg-mainHeading text-mainBg hover:bg-mainHeading/50 font-bold
            my-3 py-1 px-8 rounded-lg text-sm w-[60%] self-center"
            onClick={() => navigate(`/airdrop/${id}`)}
            disabled={checkActive(startDate, endDate)}
          >
            Join Now
          </button>
        </div>
      </div>
    </section>
  );
};

AirdropPopup.propTypes = {
  setActive: PropTypes.func.isRequired,
  acitveAirdrop: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default AirdropPopup;
