import React from 'react';
import { Badge } from 'rsuite';

// ICONS
import { GiEternalLove } from 'react-icons/gi';
import { PiCoffeeFill } from 'react-icons/pi';
import { FaXTwitter, FaLinkedinIn } from 'react-icons/fa6';
import { FaTelegramPlane } from 'react-icons/fa';

// ASSETS
import { brandMark } from '../assets/images';

const Footer = () => (
  <footer className="flex flex-col justify-center bg-[#122b44] items-center">
    <div className="grid grid-cols-2 my-4 px-4 md:px-[5%] lg:px-[10%] w-full">
      <a href="/" className="flex items-center gap-1 font-extrabold leading-10 text-4xl mr-1 hover:no-underline hover:text-white">
        <img src={brandMark} alt="main-logo" className="h-[38px] w-[38px]" />
        {/* <span className="hidden md:block">JETPAD</span> */}
        <Badge color="red" content="Beta">
          <span className=" text-2xl md:text-4xl text-white">JETPAD</span>
        </Badge>
      </a>
    </div>
    <div className="mb-4 px-4 md:px-[5%] lg:px-[10%] w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <div>
        <h4 className=" text-mainHeading font-bold">ECOSYSTEM</h4>
        <ul className="text-[#F4EEFF]">
          <li>
            <a
              href="/airdrops"
              rel="noopener noreferrer"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Airdrops
            </a>
          </li>
          <li>
            <a
              href="/create-airdrop/social"
              rel="noopener noreferrer"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Create Social Airdrop
            </a>
          </li>
          <li>
            <a
              href="/create-airdrop/dapp"
              rel="noopener noreferrer"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Create Dapp Airdrop
            </a>
          </li>
          <li>
            <a
              href="/portfolio"
              rel="noopener noreferrer"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Portfolio
            </a>
          </li>
          <li>
            <a
              href="/tokens/eth/0x14fee680690900ba0cccfc76ad70fd1b95d10e16"
              rel="noopener noreferrer"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Chart
            </a>
          </li>
          <li>
            <a
              href="/multisender"
              rel="noopener noreferrer"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              MultiSender
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h4 className=" text-mainHeading font-bold">COMPANY</h4>
        <ul className="text-[#F4EEFF]">
          <li>
            <a
              href="https://docs.jetpad.finance/"
              rel="noopener noreferrer"
              target="_blank"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              About Us
            </a>
          </li>
          <li>
            <a
              href="https://docs.jetpad.finance/terms-and-conditions"
              rel="noopener noreferrer"
              target="_blank"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Terms of Service
            </a>
          </li>
          <li>
            <a
              href="https://docs.jetpad.finance/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="https://docs.jetpad.finance/advertise-with-us"
              rel="noopener noreferrer"
              target="_blank"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Advertise With Us
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h4 className=" text-mainHeading font-bold">HOW TO</h4>
        <ul className="text-[#F4EEFF]">
          <li>
            <a
              href="https://docs.jetpad.finance/important/how-to-join-airdrop"
              rel="noopener noreferrer"
              target="_blank"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Join Airdrop
            </a>
          </li>
          <li>
            <a
              href="https://docs.jetpad.finance/airdrops/create-social-airdrop"
              rel="noopener noreferrer"
              target="_blank"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Create Social Airdrop
            </a>
          </li>
          <li>
            <a
              href="https://docs.jetpad.finance/airdrops/create-dapp-airdrop"
              rel="noopener noreferrer"
              target="_blank"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Create Dapp Airdrop
            </a>
          </li>
          <li>
            <a
              href="https://docs.jetpad.finance/airdrops/distribute-airdrop"
              rel="noopener noreferrer"
              target="_blank"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Distribute Airdrop
            </a>
          </li>
          <li>
            <a
              href="https://docs.jetpad.finance/multi-sender/how-to-use-multi-sender"
              rel="noopener noreferrer"
              target="_blank"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Use Multi-sender
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h4 className=" text-mainHeading font-bold">SUPPORT</h4>
        <ul className="text-[#F4EEFF]">
          <li>
            <a
              href="https://docs.jetpad.finance/contact-us"
              rel="noopener noreferrer"
              target="_blank"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Contact Us
            </a>
          </li>
          <li>
            <a
              href="https://docs.jetpad.finance/"
              rel="noopener noreferrer"
              target="_blank"
              className="hover:text-link hover:underline text-sm md:text-base mt-3"
            >
              Documentation
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className=" mt-2 border-t border-[#F4EEFF] p-2 px-4 md:px-[5%] lg:px-[10%] w-full flex flex-col justify-center items-center">
      <span className="text-sm md:text-base text-[#F4EEFF] flex items-center gap-1 self-center md:self-start">
        Made with
        <GiEternalLove className="text-red-600" />
        and
        <PiCoffeeFill />
      </span>
      <div className="flex gap-3 ">
        <a
          href="https://twitter.com/JetPad_Finance"
          rel="noopener noreferrer"
          target="_blank"
          className="mt-3  border border-[#F4EEFF] rounded-md p-1"
        >
          <span className="sr-only">Twitter</span>
          <FaXTwitter className="text-[#F4EEFF] h-5 aspect-auto hover:text-link " />
        </a>
        <a
          href="https://www.linkedin.com/company/101371726"
          rel="noopener noreferrer"
          target="_blank"
          className="mt-3 border border-[#F4EEFF] rounded-md p-1"
        >
          <span className="sr-only">Linkedin</span>
          <FaLinkedinIn className="text-[#F4EEFF] h-5 aspect-auto hover:text-link " />
        </a>
        <a
          href="https://t.me/JetPad_Finance"
          rel="noopener noreferrer"
          target="_blank"
          className="mt-3 border border-[#F4EEFF] rounded-md p-1 "
        >
          <span className="sr-only">Telegram</span>
          <FaTelegramPlane className="text-[#F4EEFF] h-5 aspect-auto hover:text-link " />
        </a>
        <a
          href="https://t.me/JetPad_Finance_Ann"
          rel="noopener noreferrer"
          target="_blank"
          className="mt-3 border border-[#F4EEFF] rounded-md p-1 "
        >
          <span className="sr-only">Telegram</span>
          <FaTelegramPlane className="text-[#F4EEFF] h-5 aspect-auto hover:text-link " />
        </a>
      </div>
    </div>
    <div className="h-[56px] border-t w-full border-t-[#122b44] md:hidden" />
  </footer>
);

export default Footer;
