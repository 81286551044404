import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { Badge } from 'rsuite';

// ICONS
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

// UTILITIES
import { openInNewTab } from '../utils';

// CONSTANTS
import { menus } from '../constants';

const Sidebar = ({ open, setOpen, setDappPath }) => {
  const [curSubMenu, setCurSubMenu] = useState('');
  const [curActive, setCurActive] = useState('Home');
  const email = useSelector((state) => state.Auth?.email);
  const isLoggedIn = useSelector((state) => state.Auth?.isLoggedIn);

  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = (menu) => {
    if (menu.social || menu?.doc) {
      return openInNewTab(menu.path);
    }

    if (menu.path !== '') {
      navigate(menu.path);
      setCurActive(menu.title);
    } else if (curSubMenu !== menu.title && !open) {
      setOpen(true);
      setCurSubMenu(menu?.title);
    } else if (curSubMenu !== menu.title) {
      setCurSubMenu(menu?.title);
    } else {
      setCurSubMenu('');
    }

    return {};
  };

  const handleSubMenuClick = (menu, index) => {
    if (menu.child[index].doc) {
      return openInNewTab(menu.child[index].path);
    }
    navigate(menu.child[index].path);
    setCurSubMenu('');
    setCurActive(menu.title);

    return {};
  };

  const checkPathName = (path) => {
    if (path === '/dapp-demo') {
      return true;
    }
    const pattern = /^\/dapp\/\w+$/;

    return pattern.test(path);
  };

  useEffect(() => {
    setDappPath(checkPathName(location.pathname));
  }, [location.pathname]);

  // ADD TRENDING COIN TO MENU LIST

  return (
    <aside className="hidden md:flex md:flex-col z-30 fixed bottom-0 top-[4.4rem] bg-subtleBg text-content overflow-y-auto overflow-x-hidden  border-r border-accent-1">
      <div
        className={` ${
          open ? ' w-48 ' : ' w-16 '
        } bg-subtleBg text-content    pt-2 relative duration-500`}
      >
        <button
          type="button"
          onClick={() => {
            setOpen(!open);
          }}
          className=""
        >
          <span className="sr-only">Arrow</span>
          <IoIosArrowForward
            className={`absolute cursor-pointer right-1 top-1 w-7 h-7 transform scale-x-[-1]
            border-2 border-darkerHeading bg-content hover:bg-content/70 text-mainBg rounded-full text-bold  ${!open && 'rotate-180 transition-all duration-400'}`}
          />
        </button>

        <ul className="pt-4 pr-2">
          {menus?.map((menu) => (
            <li
              key={menu.title}
              className={`flex flex-col cursor-pointer  text-content/90  justify-center gap-x-4  
              ${menu.gap ? 'mt-9' : 'mt-1'} ${!isLoggedIn && menu.path === '/profile' ? 'hidden' : 'flex'}`}
            >
              <button
                type="button"
                className={` ${curActive === menu.title && ' bg-mainHeading text-white'} flex items-center gap-2 hover:bg-mainHeading hover:text-white p-2 rounded-r-full relative `}
                onClick={() => handleMenuClick(menu)}
              >
                <div className=" flex justify-center items-center shrink-0">
                  {menu?.title === 'Web3-Name' ? (
                    <>
                      {React.createElement('img', { src: menu.icon, className: 'h-[18px] w-[18px]' })}
                    </>
                  ) : (
                    <>
                      {React.createElement(menu.icon, { className: 'h-[18px] w-[18px]' })}
                    </>
                  )}
                </div>
                <Badge content={menu.title === 'Airdrop' ? 'hot' : false}>
                  <span className={`${!open && 'hidden'} origin-left transition-all duration-500`}>
                    {menu.title}
                  </span>
                </Badge>
                {menu?.child && open && <IoIosArrowDown className={`h-[20px] w-[20px] absolute right-2 transition-all duration-300 text-white ${curSubMenu === menu.title && 'rotate-180'}`} />}
              </button>
              <div className={`${!open && 'hidden'} flex flex-col items-start mt-2 gap-1 ml-11  duration-500`}>
                {menu?.child && (
                  menu?.child.map((subMenu, index) => (
                    <button
                      type="button"
                      key={subMenu?.title}
                      className={` ${curSubMenu !== menu.title && 'hidden'} hover:bg-mainHeading p-1 rounded-md text-content/80 duration-300 w-full text-start`}
                      onClick={() => handleSubMenuClick(menu, index)}
                    >
                      {subMenu?.title}
                    </button>
                  ))
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex mx-2 rounded-md  mt-[40%] justify-center items-center">
        {isLoggedIn ? (
          <>
            <h4 className={`h-[34px] w-[34px] ${!open ? 'rounded-lg' : 'rounded-l-lg'} bg-darkerHeading font-black text-3xl text-mainBg text-center`}>
              {email?.slice(0, 1)?.toUpperCase() || 'O'}
            </h4>
            <span className={` ${!open && 'hidden'} duration-500 italic text-sm text-white font-bold overflow-clip px-1 bg-mainHeading h-[34px] rounded-r-lg flex items-center`}>
              {`${email?.slice(0, 5) || 'LOAD'}...${email?.slice((email?.length - 10)) || 'ING'}`}
            </span>
          </>
        ) : (
          <button
            type="button"
            className={`${!open && 'hidden'} self-center border border-mainHeading text-mainHeading hover:bg-mainHeading/80 px-10 py-2 rounded-xl  text-lg font-bold mb-2`}
            onClick={() => navigate('/signin')}
          >
            Login
          </button>
        )}
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  setOpen: PropTypes.func.isRequired,
  setDappPath: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Sidebar;
