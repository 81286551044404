import { getOhlcvData } from "./helpers";

export const getBars = async (
  symbolInfo,
  resolution,
  periodParams, //compulsorily needed
  onHistoryCallback,
  onErrorCallback
) => { 
  try {
    // console.log(`[resolveSymbol]: Method called!!`);
    // const fromTime = new Date(periodParams.from * 1000).toISOString();
    // const toTime = new Date(periodParams.to * 1000).toISOString();
    // const requiredBars = 302;

    const { network, poolAddress } = symbolInfo;
    const limit = periodParams?.firstDataRequest ? 1000 : (periodParams?.countBack > 1000 ? 1000 : periodParams?.countBack);
    const beforeTimestamp = periodParams?.from
    const bars = await getOhlcvData({ network, poolAddress, limit, beforeTimestamp });

    if (bars.length === 0) {
      onHistoryCallback([], { noData: true });
    } else {
      onHistoryCallback(bars, { noData: false });
    }
  } catch (err) {
    // console.error(err);
    onErrorCallback(err);
  }
};

export const subscribeBars = (
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback
) => {
  // Implement your subscription logic here
};

export const unsubscribeBars = (subscriberUID) => {
  // Implement your unsubscription logic here
};