import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

// CONSTANTS
import { supportedNetworks } from '../constants';

// Assets
import {
  clickGIf, brandMark,
} from '../assets/images';

// Redux
import {
  fetchLatestPools,
  fetchTrendingTokens,
  getTokenById,
  getTokenPrices,
} from '../Redux/TokenData/tokenData';

import {
  convertBTCtoUSD,
  errorToast,
  getNetworkId,
  handleCopy,
  successToast,
} from '../utils';

const ChartTable = () => {
  const [activeList, setActiveList] = useState('trending');
  const trendingList = useSelector((state) => state.tokenData?.trendingTokens);
  const latestList = useSelector((state) => state.tokenData?.latestPools);
  const tokenPrices = useSelector((state) => state.tokenData?.tokenPrices);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1280);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTokenData = (id) => {
    // set a loading spinner
    dispatch(getTokenById(id)).then((res) => {
      if (res.error) {
        errorToast('Something Went Wrong!');
      } else {
        const netID = getNetworkId(
          supportedNetworks,
          res.payload?.asset_platform_id,
        );

        if (netID !== '') {
          const path = `/tokens/${netID}/${res.payload?.contract_address}`;
          navigate(path);
        } else {
          errorToast('Token Network Not Supported Yet!');
        }
      }
    });

    // stop the loading spinner
  };

  const numberArray = Array.from({ length: 7 }, (_, index) => index + 1);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  // Use map to iterate through the array
  const tableLoadingSK = numberArray.map((item, index) => (
    <tr key={item}>
      <td className="flex items-center gap-2 w-full h-full">
        {`${index + 1}.`}
        <img
          src={brandMark}
          alt="placeholder"
          className="h-[20px] w-[20px] rounded-full"
        />
      </td>
      <td className="hover:text-black">
        <Skeleton />
        <span className="sr-only">Skeleton</span>
      </td>
      <td>
        <Skeleton />
        <span className="sr-only">Skeleton</span>
      </td>
      <td className="text-[#0ecb81]">
        <Skeleton />
        <span className="sr-only">Skeleton</span>
      </td>
    </tr>
  ));

  useEffect(() => {
    if (trendingList?.length === 0) {
      dispatch(fetchTrendingTokens());
      const ids = 'bitcoin,ethereum,binancecoin,matic-network';
      dispatch(getTokenPrices({ ids }));
    }

    if (latestList?.length === 0) {
      const networks = ['eth', 'bsc', 'polygon_pos', 'solana'];
      const network = networks[Math.floor(Math.random() * networks.length)];
      dispatch(fetchLatestPools({ network }));
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>

      {
      isMobile ? (
        <div className=" flex flex-col gap-2 bg-subtleBg">
          <div className=" bg-opacity-5 border border-accent-1 shadow-md shadow-accent-1 rounded-lg py-3 md:px-1 lg:px-3 w-full">
            <div className="w-full flex justify-between mb-2 font-semibold text-lg px-1">
              <button
                type="button"
                className={`flex items-center gap-1 border-[#0056CC] ${
                  activeList === 'trending' ? 'bg-[#006cff]' : 'bg-[#a9c4eb]'
                } bg-opacity-40 border px-2 py-1 rounded-lg hover:bg-[#114fa5]`}
                onClick={() => setActiveList('trending')}
              >
                <div className="red-dot" />
                Trending Coins
              </button>
              <button
                type="button"
                className={`flex items-center gap-1 border-[#0056CC] ${
                  activeList === 'latest' ? 'bg-[#006cff]' : 'bg-[#a9c4eb]'
                } bg-opacity-40 border px-2 py-1 rounded-lg hover:bg-[#114fa5]`}
                onClick={() => setActiveList('latest')}
              >
                <div className="green-dot" />
                Latest Pools
              </button>
            </div>
            <div className=" p-3 rounded-lg w-full">
              <SkeletonTheme
                baseColor="#5294e0"
                highlightColor="#96c7ff"
                borderRadius="0.5rem"
                duration={4}
              >
                <table className="w-full ">
                  {activeList === 'trending' ? (
                    <>
                      <thead className="border bg-mainHeading border-emerald-400">
                        <tr>
                          <th>NO.</th>
                          <th>NAME</th>
                          <th>PRICE</th>
                          <th>RANK</th>
                        </tr>
                      </thead>
                      <tbody>
                        {trendingList.length !== 0
                      && tokenPrices?.bitcoin?.usd !== 0
                          ? trendingList?.slice(0, 7)?.map((coin, index) => (
                            <tr key={coin.item?.coin_id}>
                              <td className="flex items-center gap-2 w-full h-full">
                                {`${index + 1}.`}
                                <img
                                  src={coin.item?.small}
                                  alt={coin.item?.id}
                                  className="h-[20px] w-[20px] rounded-full"
                                />
                              </td>
                              <td className="hover:text-black">
                                <button
                                  type="button"
                                  onClick={() => getTokenData(coin.item?.id)}
                                  className="w-full h-full text-start"
                                >
                                  {coin.item?.name}
                                  <img
                                    src={clickGIf}
                                    alt="click"
                                    className="h-[18px] w-[18px] inline-flex"
                                  />
                                </button>
                              </td>
                              <td>
                                {`$${convertBTCtoUSD(
                                  coin.item?.price_btc,
                                  tokenPrices?.bitcoin?.usd,
                                ).toFixed(2)}`}
                              </td>
                              <td className="text-[#0ecb81]">{`#${coin.item?.market_cap_rank}`}</td>
                            </tr>
                          ))
                          : tableLoadingSK}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <thead className="border border-emerald-400">
                        <tr>
                          <th>NAME</th>
                          <th>PRICE</th>
                          <th>TXNS</th>
                          <th>24H</th>
                        </tr>
                      </thead>
                      <tbody>
                        {latestList.length !== 0
                          ? latestList?.map((coin, index) => (
                            <tr key={coin?.id}>
                              <td className=" text-sm">
                                <button
                                  type="button"
                                  className="w-full h-full text-start hover:text-black"
                                  onClick={() => {
                                    if (handleCopy(coin?.attributes?.address)) {
                                      successToast('Contract Address Copied');
                                    }
                                  }}
                                >
                                  <span className="text-[#808080] mr-1 text-xs">
                                    {`#${
                                      index + 1
                                    }`}
                                  </span>
                                  <span className=" font-semibold">
                                    {coin?.attributes?.name?.split('/')[0]}
                                  </span>
                                  /
                                  <span className=" text-gray-400 hover:text-black">
                                    {coin?.attributes?.name?.split('/')[1]}
                                  </span>
                                </button>
                              </td>
                              <td>
                                {`$${(coin?.attributes?.base_token_price_usd).slice(
                                  0,
                                  5,
                                )}`}
                              </td>
                              <td className="text-sm">
                                <span className="text-[#0ecb81] mr-1">{`Buys: ${coin?.attributes?.transactions?.h24?.buys}`}</span>
                                -
                                <span className="text-[#F56565] ml-1">{`Sells: ${coin?.attributes?.transactions?.h24?.sells}`}</span>
                              </td>
                              <td className="text-sm">
                                <span
                                  className={`
                                    ${
                                      Math.sign(
                                        Number(
                                          coin?.attributes
                                            ?.price_change_percentage?.h24,
                                        ),
                                      ) === 0 && 'text-[#999999]'
                                    }
                                    ${
                                      Math.sign(
                                        Number(
                                          coin?.attributes
                                            ?.price_change_percentage?.h24,
                                        ),
                                      ) === 1 && 'text-[#48BB78]'
                                    }
                                    ${
                                      Math.sign(
                                        Number(
                                          coin?.attributes
                                            ?.price_change_percentage?.h24,
                                        ),
                                      ) === -1 && 'text-[#F56565]'
                                    }
                                    `}
                                >
                                  {`${coin?.attributes?.price_change_percentage?.h24}%`}
                                </span>
                              </td>
                            </tr>
                          ))
                          : tableLoadingSK}
                      </tbody>
                    </>
                  )}
                </table>
              </SkeletonTheme>
            </div>
          </div>

        </div>
      )
        : (
          <div className=" flex flex-col gap-2 bg-subtleBg">
            <div className=" bg-opacity-5 border border-accent-1 shadow-md shadow-accent-1 rounded-lg py-3 md:px-1 lg:px-3 w-full">
              <div className="w-full flex justify-between mb-2 font-semibold text-lg px-1">
                <button
                  type="button"
                  className={`flex items-center gap-1 border-[#0056CC] ${
                    activeList === 'trending' ? 'bg-[#006cff]' : 'bg-[#a9c4eb]'
                  } bg-opacity-40 border px-2 py-1 rounded-lg hover:bg-[#114fa5]`}
                >
                  <div className="red-dot" />
                  Trending Coins
                </button>
                <button
                  type="button"
                  className={`flex items-center gap-1 border-[#0056CC] ${
                    activeList === 'latest' ? 'bg-[#006cff]' : 'bg-[#a9c4eb]'
                  } bg-opacity-40 border px-2 py-1 rounded-lg hover:bg-[#114fa5]`}
                >
                  <div className="green-dot" />
                  Latest Pools
                </button>
              </div>
              <div className="w-full flex justify-between">
                <div className="border-gray-100 border-2 w-[48%] shrink-0 p-3 rounded-lg">
                  <SkeletonTheme
                    baseColor="#5294e0"
                    highlightColor="#96c7ff"
                    borderRadius="0.5rem"
                    duration={4}
                  >
                    <table className="w-full ">
                      <>
                        <thead className="border hover:bg-mainHeading border-emerald-400">
                          <tr>
                            <th>NO.</th>
                            <th>NAME</th>
                            <th>PRICE</th>
                            <th>RANK</th>
                          </tr>
                        </thead>
                        <tbody>
                          {trendingList.length !== 0
                      && tokenPrices?.bitcoin?.usd !== 0
                            ? trendingList?.slice(0, 7)?.map((coin, index) => (
                              <tr key={coin.item?.coin_id}>
                                <td className="flex items-center gap-2 w-full h-full">
                                  {`${index + 1}.`}
                                  <img
                                    src={coin.item?.small}
                                    alt={coin.item?.id}
                                    className="h-[20px] w-[20px] rounded-full"
                                  />
                                </td>
                                <td className="hover:text-black">
                                  <button
                                    type="button"
                                    onClick={() => getTokenData(coin.item?.id)}
                                    className="w-full h-full text-start"
                                  >
                                    {coin.item?.name}
                                    <img
                                      src={clickGIf}
                                      alt="click"
                                      className="h-[18px] w-[18px] inline-flex"
                                    />
                                  </button>
                                </td>
                                <td>
                                  {`$${convertBTCtoUSD(
                                    coin.item?.price_btc,
                                    tokenPrices?.bitcoin?.usd,
                                  ).toFixed(2)}`}
                                </td>
                                <td className="text-[#0ecb81]">{`#${coin.item?.market_cap_rank}`}</td>
                              </tr>
                            ))
                            : tableLoadingSK}
                        </tbody>
                      </>
                    </table>
                  </SkeletonTheme>
                </div>
                <div className="border-gray-100 border-2 w-[48%] shrink-0 p-3 rounded-lg">
                  <SkeletonTheme
                    baseColor="#5294e0"
                    highlightColor="#96c7ff"
                    borderRadius="0.5rem"
                    duration={4}
                  >
                    <table className="w-full">
                      <>
                        <thead className="border border-emerald-400">
                          <tr>
                            <th>NAME</th>
                            <th>PRICE</th>
                            <th>TXNS</th>
                            <th>24H</th>
                          </tr>
                        </thead>
                        <tbody>
                          {latestList.length !== 0
                            ? latestList?.map((coin, index) => (
                              <tr key={coin?.id}>
                                <td className=" text-sm">
                                  <button
                                    type="button"
                                    className="w-full h-full text-start hover:text-black"
                                    onClick={() => {
                                      if (handleCopy(coin?.attributes?.address)) {
                                        successToast('Contract Address Copied');
                                      }
                                    }}
                                  >
                                    <span className="text-[#808080] mr-1 text-xs">
                                      {`#${
                                        index + 1
                                      }`}
                                    </span>
                                    <span className=" font-semibold">
                                      {coin?.attributes?.name?.split('/')[0]}
                                    </span>
                                    /
                                    <span className=" text-gray-400 hover:text-black">
                                      {coin?.attributes?.name?.split('/')[1]}
                                    </span>
                                  </button>
                                </td>
                                <td>
                                  {`$${(coin?.attributes?.base_token_price_usd).slice(
                                    0,
                                    5,
                                  )}`}
                                </td>
                                <td className="text-sm">
                                  <span className="text-[#0ecb81] mr-1">{`Buys: ${coin?.attributes?.transactions?.h24?.buys}`}</span>
                                  -
                                  <span className="text-[#F56565] ml-1">{`Sells: ${coin?.attributes?.transactions?.h24?.sells}`}</span>
                                </td>
                                <td className="text-sm">
                                  <span
                                    className={`
                                    ${
                                      Math.sign(
                                        Number(
                                          coin?.attributes
                                            ?.price_change_percentage?.h24,
                                        ),
                                      ) === 0 && 'text-[#999999]'
                                    }
                                    ${
                                      Math.sign(
                                        Number(
                                          coin?.attributes
                                            ?.price_change_percentage?.h24,
                                        ),
                                      ) === 1 && 'text-[#48BB78]'
                                    }
                                    ${
                                      Math.sign(
                                        Number(
                                          coin?.attributes
                                            ?.price_change_percentage?.h24,
                                        ),
                                      ) === -1 && 'text-[#F56565]'
                                    }
                                    `}
                                  >
                                    {`${coin?.attributes?.price_change_percentage?.h24}%`}
                                  </span>
                                </td>
                              </tr>
                            ))
                            : tableLoadingSK}
                        </tbody>
                      </>
                    </table>
                  </SkeletonTheme>
                </div>
              </div>
            </div>

          </div>
        )
    }

    </>
  );
};
export default ChartTable;
