import React from 'react';
import { PropTypes } from 'prop-types';

const VerifyButton = ({ handleVerify }) => (
  <>
    <span
      className="border border-[#FFA409] bg-[#ffd791] px-2 rounded-xl text-subText text-xs"
    >
      Pending
    </span>
    <button
      type="button"
      className=" px-2 py-1 bg-mainHeading text-mainBg hover:bg-mainHeading/50 font-semibold rounded-lg"
      onClick={() => handleVerify()}
    >
      Verify
    </button>
  </>
);

VerifyButton.propTypes = {
  handleVerify: PropTypes.func.isRequired,
};

export default VerifyButton;
